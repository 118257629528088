import { classnames } from '@/helpers';
import css from './Icon.module.scss';
import { IconProps } from './types';

const Icon = ({ ext, variant, color = 'black-900', className = '', size, noFilter = false, ...props }: IconProps) => {
  const extension = ext ?? (props.style?.height > 24 || props.style?.width > 24) ? 'svg' : 'png';

  return (
    <img
      src={`/images/icons/${variant}.${extension}`}
      {...props}
      className={classnames(noFilter ? '' : `${css.icon} ${css[color]}`, size && `${css.icon} ${css[size]}`, className)}
      alt={props.alt ?? ''}
    />
  );
};

export default Icon;
