import { H3 } from '@/components/elements/content';
import { Button } from '@/components/elements/forms/buttons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SideColumnLayout, { MainColumn, SideColumn } from '@/components/layouts/SideColumnLayout';
import { ShadowModal } from '@/components/modules/modals';
import Header from '@/components/modules/pages/giftCards/Header';
import SEO from '@/components/modules/SEO';
import InfoColumn from '@/components/templates/giftCards/InfoColumn';
import CheckoutColumn from '@/components/templates/valueCards/CheckoutColumn';
import ConfirmValueCard from '@/components/templates/valueCards/ConfirmValueCard';
import { OLD_KLARNA_GIFTCARD_CHECKOUT_FEATURE_FLAG } from '@/constants/experimentConstants';
import { exportIdSlug, isEmpty, trackMpEvent, trackPage, url as urlUtil } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { useValueCardData } from '@/hooks/usePlaceData';
import { __ } from '@/locale';
import { _sTitle } from '@/locale/i18n';
import { NotFound } from '@/pages/_exports';
import { authorizeKlarnaPurchase, getInitializeKlarnaSDKOnce, loadKlarnaWidget } from '@/services/klarnaServices';
import { createValueCardOrder, getValueCardOrderLines, initiateOrUpdateVCPayment } from '@/services/vcPaymentServices';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

const base = 'buyValueCard';

const onPlaceLoad = (place) => {
  if (!place?.employees?.length > 0) return;

  place.redirect && (window.location = `${place.redirect}/valuecards/buy`);
};

const getPath = (path, slugId) => path.replace(':slugId', slugId);

const trackScreenShown = (placeId) => () => {
  trackPage();
  trackMpEvent('screen_shown', {
    screen_name: 'place_value_card_landing_page',
    place_id: placeId,
  });
};

const ValueCardsBuy = ({ ssrPlace = null, is404 = false }) => {
  const { slugId } = useParams();
  const { id, slug } = exportIdSlug(slugId);
  const { path, params } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const flags = useAppSelector((state) => state.flags);

  const { place } = useValueCardData(ssrPlace, parseInt(id), slug);
  const employees = place && !isEmpty(place) && place.employees;
  const description = place && !isEmpty(place) && place.description;
  const placeName = place && !isEmpty(place) && place.name;
  const placeSellsGiftcard = Boolean(place && !isEmpty(place) && place.sellsGiftCard);

  const [valueCard, setValueCard] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const employee = place && !isEmpty(place) && employees?.find((e) => e.id === employeeId);
  const [orderId, setOrderId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [shouldGoToConfirm, setShouldGoToConfirm] = useState(false);

  const [clientToken, setClientToken] = useState(null);
  const [sessionID, setSessionID] = useState(null);
  const [paymentMethodCategories, setPaymentMethodCategories] = useState([]);
  const [orderLines, setOrderLines] = useState([]);
  const initializeKlarnaSDK = getInitializeKlarnaSDKOnce();

  const onSelectValueCard = (valueCard, employeeId) => () => {
    setValueCard(valueCard);
    setEmployeeId(employeeId);
    setShouldGoToConfirm(true);
  };

  const onShowInfoSelectValueCard = (valueCard, employeeId) => () => {
    /**
     * If the old Klarna giftcard checkout feature flag is on, we should use the old checkout flow.
     * for the value card.
     */
    if (flags?.[OLD_KLARNA_GIFTCARD_CHECKOUT_FEATURE_FLAG]?.value === 'on') {
      setShowModal(false);
      onSelectValueCard(valueCard, employeeId)();
      return;
    }
    history.push({
      pathname: `/places/${params?.slugId}/valuecard/checkout`,
      state: { valueCard: valueCard, employeeId },
    });
  };

  useEffect(() => {
    if (!shouldGoToConfirm) return;
    createKlarnaOrder();
    goToConfirm();
  }, [shouldGoToConfirm]);

  const createKlarnaOrder = async () => {
    const updateResponse = await initiateOrUpdateVCPayment(sessionID, {
      placeId: id,
      valueCard,
      slugId,
      employee: employee,
    });

    const token = updateResponse.clientToken ?? clientToken;
    initializeKlarnaSDK(token);
    loadKlarnaWidget('#klarna-payment', token);
    setOrderLines(updateResponse.orderLines);
    if (!sessionID) {
      setPaymentMethodCategories(updateResponse.paymentMethodCategories);
      setSessionID(updateResponse.sessionID);
      setClientToken(updateResponse.clientToken);
    }
  };

  const goToConfirm = () => {
    history.push(`${getPath(path, slugId)}/confirm`);
  };

  const goBack = () => {
    setShouldGoToConfirm(false);
    history.push(getPath(path, slugId));
  };

  const showInfo = (valueCard, employeeId) => () => {
    setValueCard(valueCard);
    setEmployeeId(employeeId);
    setShowModal(true);
  };

  useEffect(() => {
    if (!pathname.endsWith('confirm')) return setShouldGoToConfirm(false);
    if (!valueCard || !employeeId) return goBack();
  }, [pathname]);

  useEffect(trackScreenShown(id), []);

  useEffect(() => onPlaceLoad(place), [place]);

  const getCreateOrder = async (res) => {
    const createRes = await createValueCardOrder(sessionID, res.authorization_token);

    window.location = createRes.redirectURL;
  };

  const authorize = async () => {
    const orderLines = await getValueCardOrderLines(sessionID);
    authorizeKlarnaPurchase(orderLines, 'pay_later', getCreateOrder);
  };

  return is404 ? (
    <NotFound />
  ) : (
    <PageViewLayout
      type="subView"
      title={__(`${base}.title`, { name: placeName || '' })}
      source={base}
      back
      backSrc={`/places/${slugId}`}>
      <div className="mt-10">
        <Switch>
          <Route exact path={path}>
            <div className="container mx-auto">
              <Header
                title={_sTitle(`${base}.checkout.title`, { name: placeName || '' })}
                tag={__(`${base}.checkout.tag`)}
                tagText={__(`${base}.checkout.tagText`)}
              />
            </div>
          </Route>
        </Switch>
        <SideColumnLayout>
          <MainColumn>
            <Switch>
              <Route exact path={path}>
                <CheckoutColumn
                  {...{
                    slugId,
                    employees,
                    placeName,
                    onSelectValueCard,
                    valueCard,
                    showInfo,
                    placeSellsGiftcard,
                  }}
                />
              </Route>
              <Route path={`${path}/confirm`}>
                <ConfirmValueCard
                  {...{
                    goBack,
                    valueCard,
                    employee,
                    goBackPath: getPath(path, slugId),
                    authorize,
                    paymentMethodCategories,
                  }}
                />
              </Route>
            </Switch>
          </MainColumn>
          <SideColumn>
            <div className="sticky top-20">
              <InfoColumn {...{ description, placeName, base: `${base}.info` }} />
            </div>
          </SideColumn>
        </SideColumnLayout>
        <ShadowModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
          <H3 className="mb-6" styleAs="h3-bold">
            {valueCard?.name}
          </H3>
          <p className="my-8">{valueCard?.description}</p>
          <Button variant="primary" block size="lg" onClick={onShowInfoSelectValueCard(valueCard, employeeId)}>
            {__(`${base}.checkout.selectFromModal`)}
          </Button>
          <Button variant="link" block size="lg" onClick={() => setShowModal(false)}>
            {__('abort')}
          </Button>
        </ShadowModal>
      </div>
      <SEO
        title={_sTitle(`seo.${base}Title`, { name: placeName })}
        description={_sTitle(`seo.${base}Description`, { name: placeName })}
        url={`${urlUtil.getBaseUrl()}places/${slugId}/valuecards/buy`}
      />
    </PageViewLayout>
  );
};

const mapStateToProps = (state) => ({ ssrPlace: state.place, is404: state.loading?.is404 ?? false });

export default connect(mapStateToProps)(ValueCardsBuy);
