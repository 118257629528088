import { bookActions } from '@/actions';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { config } from '@/config';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { isAndroidPhone, isIPhone, isSistaminuten, promiseWrapper, url } from '@/helpers';
import { createSession, mapApplePayLineItem } from '@/helpers/applepay';
import {
  getApplePaySummaryServiceItems,
  getCheckoutSummary,
  getGooglePaySummaryServiceItems,
  getPaymentMethodsTracking,
  savePaymentTrackingProps,
} from '@/helpers/checkout';
import { mapConfirmedBookingInfoServices } from '@/helpers/confirmation';
import {
  buildBrowserInfo,
  clientInstance,
  getGoogleIsReadyToPayRequest,
  getGooglePayPaymentDataRequest,
} from '@/helpers/googlepay';
import { useAppSelector } from '@/hooks';
import { useCardsContext } from '@/hooks/adyen/useCards';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import { _s } from '@/locale';
import {
  getValidateRedirectPathname,
  removeCofCheckoutStateFromLocalStorage,
  saveCofCheckoutStateToLocalStorage,
} from '@/pages/validate-cof-payment-redirect/ValidateCofPaymentRedirect.hooks';
import { saveSwishCheckoutStateToLocalStorage } from '@/pages/validate-swish-payment-redirect/ValidateSwishPaymentRedirect.hooks';
import { adyenServices, qliroServices, swishServices } from '@/services';
import { bookServices } from '@/services/bookServicesTs';
import { CofPaymentData } from '@/types/adyen';
import { CheckoutTracking } from '@/types/analytics';
import {
  CreatePaymentAcceptedResponse,
  CreatePaymentActionRequiredResponse,
  createPaymentAcceptedResponseSchema,
  createPaymentActionRequiredResponseSchema,
  createPaymentRefusedResponseSchema,
} from '@/types/api/services/adyen';
import {
  AddBookingGiftcardsResponse,
  ConfirmedBooking,
  InitKlarnaAfterBookingResponse,
  InitQliroResponse,
  PaymentHistoryResponse,
  ValidateGiftCardRequest,
  addBookingGiftcardsResponseSchema,
  initKlarnaAfterBookingResponseSchema,
  initQliroResponseSchema,
  swishPaymentBookingResponseSchema,
} from '@/types/api/services/booking';
import { ErrorResponse, errorResponseSchema } from '@/types/api/services/schema';
import { swishPaymentResponseSchema } from '@/types/api/services/swish';
import {
  BaseCheckoutState,
  CheckoutAPI,
  CheckoutAction,
  CheckoutMissingAction,
  CheckoutSummary,
  SelectedPaymentMethod,
  adyenSelectedPaymentMethodSchema,
  availablePrePaymentMethodSchema,
  selectedPaymentMethodSchema,
  selectedStoredCofPaymentMethodSchema,
} from '@/types/checkout';
import { BookState, Giftcard } from '@/types/state/book';
import * as Sentry from '@sentry/react';
import { Dispatch, createContext, useContext, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { baseTranslationKey } from '../BookingCheckout';
import { SwishSuccessRedirectData } from '../BookingCheckout.hooks';

export const swishStateSchema = z.object({
  payment: swishPaymentBookingResponseSchema.nullable(),
  notificationChannel: z.any().optional(),
  showQrCode: z.boolean().optional(),
});

type SwishState = z.infer<typeof swishStateSchema>;

export type SubmitThreeDSContext = SubmitPrePaymentContext & {
  cofThreeDS: CreatePaymentActionRequiredResponse;
};

type SubmitApplePayBookingContext = SubmitPrePaymentContext & {
  services: ApplePayJS.ApplePayLineItem[];
};

type SubmitGooglePayBookingContext = SubmitPrePaymentContext & {
  services: google.payments.api.DisplayItem[];
};

type SubmitCofBookingContext = SubmitPrePaymentContext & {
  paymentMethod?: typeof CHECKOUT_PAYMENT_METHOD.STORED_COF | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY;
};

type CompleteWalletBookingContext = SubmitPrePaymentContext & {
  type: 'googlepay' | 'applepay';
  payLater: boolean;
  paymentData: CofPaymentData;
};

type UseCheckoutManagerResult = {
  cofThreeDS: CreatePaymentActionRequiredResponse | null;
  swish: SwishState;
  prepayCheckoutAPI: () => CheckoutAPI;
  onChangePaymentMethod: (method: SelectedPaymentMethod) => void;
  onDismissSwishQRCode: () => void;
  onRemoveGiftcard: (code: string) => () => void;
  onApplyGiftcard: (code: string) => (successCallback: () => void) => void;
  onCheckoutMissingAction: (action: CheckoutMissingAction) => void;
} & BaseCheckoutState;

const CheckoutContext = createContext<UseCheckoutManagerResult>({} as UseCheckoutManagerResult);

type PrepayCheckoutSummary = CheckoutSummary;

type PrepayCheckoutAction =
  | CheckoutAction<PrepayCheckoutSummary>
  | {
      type: 'ONHOLD_COF';
      payload: {
        cofThreeDS: CreatePaymentActionRequiredResponse;
      };
    }
  | {
      type: 'ONHOLD_SWISH';
      payload: { swish: SwishState; paymentTrackingProps: CheckoutTracking.PaymentMethods };
    }
  | {
      type: 'ERROR_SWISH';
    }
  | {
      type: 'CLOSE_SWISH_QRCODE_MODAL';
    };

type PrepayCheckoutState = BaseCheckoutState & {
  qliro?: any;
  klarna?: any;
  cofThreeDS?: CreatePaymentActionRequiredResponse | null;
  swish?: SwishState | null;
};

type PayAtPlaceSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE;
  responseData: AddBookingGiftcardsResponse;
};

type KlarnaSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.KLARNA;
  responseData: InitKlarnaAfterBookingResponse;
};

type QliroSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.QLIRO;
  responseData: InitQliroResponse;
};

type CofSuccessRedirectData = {
  paymentMethod:
    | typeof CHECKOUT_PAYMENT_METHOD.STORED_COF
    | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
    | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
  responseData: CreatePaymentAcceptedResponse;
};

export type SuccessRedirectData =
  | PayAtPlaceSuccessRedirectData
  | KlarnaSuccessRedirectData
  | QliroSuccessRedirectData
  | CofSuccessRedirectData
  | SwishSuccessRedirectData;

export type SubmitPrePaymentContext = {
  bookingId: number;
  paymentTrackingProps?: CheckoutTracking.PaymentMethods;
  successCallback: ({ paymentMethod, responseData }: SuccessRedirectData) => void;
  errorCallback: (error: ErrorResponse) => void;
  giftcards?: Giftcard[] | null;
};

function prePayCheckoutReducer(state: PrepayCheckoutState, action: PrepayCheckoutAction) {
  switch (action.type) {
    case 'UPDATE_SUMMARY': {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case 'SET_PAYMENT_METHOD':
      return {
        ...state,
        selectedPaymentMethod: action.payload,
        missingActions: state.missingActions.filter((a) => a !== 'payment-method'),
      };
    case 'SUBMITTING': {
      return { ...state, submitting: true };
    }
    case 'SUCCESS_REDIRECT': {
      removeCofCheckoutStateFromLocalStorage();
      return {
        ...state,
        submitting: false,
      };
    }
    case 'ERROR': {
      return { ...state, submitting: false, error: action.payload, cofThreeDS: null };
    }
    case 'CLOSE_SWISH_QRCODE_MODAL': {
      return {
        ...state,
        submitting: false,
        swish: { ...state.swish, showQrCode: false },
      };
    }
    case 'ONHOLD_COF': {
      const validatedAdyenPaymentMethod = adyenSelectedPaymentMethodSchema.safeParse(state.selectedPaymentMethod);
      const hasSwish = state.summary.availablePaymentMethods.find(
        (method) => method.type === CHECKOUT_PAYMENT_METHOD.SWISH,
      );

      if (validatedAdyenPaymentMethod.success === false) {
        Sentry.captureException(validatedAdyenPaymentMethod.error);
        return {
          ...state,
          submitting: false,
          error: true,
        };
      }

      saveCofCheckoutStateToLocalStorage({
        cofThreeDS: action.payload.cofThreeDS,
        isAddNewCard: false,
        payLater: !state.summary.isOnlinePaymentRequired && !hasSwish,
        selectedPaymentMethod: validatedAdyenPaymentMethod.data,
      });

      return {
        ...state,
        cofThreeDS: action.payload.cofThreeDS,
        submitting: false,
      };
    }
    case 'ONHOLD_SWISH': {
      saveSwishCheckoutStateToLocalStorage({
        payment: action.payload.swish.payment,
        trackingProps: { paymentMethodsProps: action.payload.paymentTrackingProps },
      });
      return {
        ...state,
        submitting: false,
        swish: action.payload.swish,
      };
    }
    case 'ERROR_SWISH': {
      return {
        ...state,
        submitting: false,
        swish: null,
      };
    }
    case 'SET_MISSING_ACTION': {
      const missingActions = Array.from(new Set([...state.missingActions, action.payload]));
      return {
        ...state,
        missingActions,
      };
    }
    default:
      return state;
  }
}

function handleSubmitGooglePay(context: SubmitGooglePayBookingContext, dispatch: Dispatch<PrepayCheckoutAction>) {
  return async (final: number, payLater: boolean) => {
    const { services, bookingId } = context;
    try {
      const googlePaymentsClient = clientInstance('ecom');
      const isReadyToPay = googlePaymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest('ecom'));

      if (!isReadyToPay) throw new Error('Google pay is not ready to pay');

      dispatch({ type: 'SUBMITTING', payload: true });

      const response = await googlePaymentsClient.loadPaymentData(
        getGooglePayPaymentDataRequest(
          {
            totalPrice: payLater ? '0.00' : `${final.toFixed(2)}`,
            totalPriceStatus: payLater ? 'ESTIMATED' : 'FINAL',
            totalPriceLabel: payLater
              ? _s('googlepay.totalPriceLabel.paylater')
              : _s('googlepay.totalPriceLabel.paynow'),
            currencyCode: 'SEK',
            countryCode: 'SE',
            displayItems: [
              ...services,
              ...((): google.payments.api.DisplayItem[] => {
                if (!payLater) return [];
                return [
                  {
                    label: _s('googlepay.totalPriceLabel.paynow'),
                    type: 'SUBTOTAL',
                    price: `${final.toFixed(2)}`,
                    status: 'PENDING',
                  },
                ];
              })(),
            ],
          },
          'ecom',
        ),
      );

      await completeWalletBooking(
        {
          ...context,
          paymentData: {
            paymentMethod: {
              type: 'googlepay',
              googlePayToken: response.paymentMethodData.tokenizationData.token,
              googlePayCardNetwork: response.paymentMethodData.info.cardNetwork,
            },
            browserInfo: buildBrowserInfo(),
            redirectInfo: { returnPath: getValidateRedirectPathname(bookingId, 'booking') },
          },
          payLater,
          type: 'googlepay',
        },
        dispatch,
      );
    } catch (error) {
      // Google pay throws "CANCELED statusCode when user closes the payments sheet"
      if (error.statusCode !== 'CANCELED') {
        context.errorCallback(error);
      }
      dispatch({ type: 'ERROR', payload: true });
    }
  };
}

function handleSubmitApplePay(context: SubmitApplePayBookingContext, dispatch: Dispatch<PrepayCheckoutAction>) {
  return async (final: number, payLater: boolean) => {
    const { services, bookingId } = context;

    const applePaySession = createSession({
      total: mapApplePayLineItem({ amount: `${final}`, label: 'Bokadirekt', payLater }),
      lineItems: [...services],
    });

    applePaySession.begin();

    applePaySession.onpaymentauthorized = async (payment) => {
      try {
        await completeWalletBooking(
          {
            ...context,
            paymentData: {
              paymentMethod: {
                type: 'applepay',
                applePayToken: payment.payment.token.paymentData,
              },
              browserInfo: buildBrowserInfo(),
              redirectInfo: { returnPath: getValidateRedirectPathname(bookingId, 'booking') },
            },
            payLater,
            type: 'applepay',
          },
          dispatch,
          applePaySession,
        );
      } catch (error) {
        context.errorCallback(error);

        applePaySession.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors: [],
        });
        dispatch({ type: 'ERROR', payload: true });
      }
    };
  };
}

async function completeWalletBooking(
  context: CompleteWalletBookingContext,
  dispatch: Dispatch<PrepayCheckoutAction>,
  applePaySession?: ApplePaySession,
) {
  const { type, paymentData, payLater, bookingId, paymentTrackingProps, giftcards } = context;
  const paymentMethod = type === 'googlepay' ? CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY : CHECKOUT_PAYMENT_METHOD.APPLE_PAY;

  let recurringDetailReference: string;

  if (payLater) {
    const saveCardResponse = await adyenServices.saveUserCard({ paymentData });

    if (saveCardResponse.requiresAction) {
      dispatch({ type: 'ONHOLD_COF', payload: { cofThreeDS: saveCardResponse } });
      return;
    }
    recurringDetailReference = saveCardResponse.paymentDetails?.additionalData?.['recurring.recurringDetailReference'];
  }

  const { data, error } = await promiseWrapper(
    adyenServices.createPayment({
      id: bookingId,
      type: 'booking',
      giftcards: giftcards,
      paymentData: {
        ...(() => {
          if (recurringDetailReference) {
            return {
              paymentMethod: {
                type: 'storedCard',
                storedPaymentMethodId: recurringDetailReference,
              },
              browserInfo: paymentData.browserInfo,
              redirectInfo: paymentData.redirectInfo,
            };
          } else {
            return paymentData;
          }
        })(),
      },
      ...(recurringDetailReference ? { paymentMethod } : {}),
    }),
  );

  const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

  if (paymentAccepted.success) {
    applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_SUCCESS });
    savePaymentTrackingProps(paymentTrackingProps);
    context.successCallback({ paymentMethod, responseData: paymentAccepted.data });
    return;
  }

  const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

  if (paymentActionRequired.success) {
    dispatch?.({ type: 'ONHOLD_COF', payload: { cofThreeDS: paymentActionRequired.data } });
    return;
  }

  const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

  applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_FAILURE });
  context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
}

export function handleSubmitThreeDSPayment(context: SubmitThreeDSContext, dispatch?: Dispatch<PrepayCheckoutAction>) {
  return async (_state: any, payLater: boolean, isGooglePay: boolean) => {
    const { adyenPaymentData } = context.cofThreeDS || {};
    const { paymentTrackingProps } = context;
    const paymentMethod = isGooglePay ? CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY : CHECKOUT_PAYMENT_METHOD.STORED_COF;

    dispatch?.({ type: 'SUBMITTING', payload: true });

    if (payLater) {
      const { data, error } = await promiseWrapper(adyenServices.validate3DS({ details: _state.data.details }));

      const recurringDetailReference = data?.additionalData?.['recurring.recurringDetailReference'];

      if (error || !recurringDetailReference) {
        context.errorCallback(error);
        return;
      }
      handleSubmitCofPayment({ paymentMethod, ...context })(recurringDetailReference);
      return;
    }

    // @TODO send in added giftcards
    const { data, error } = await promiseWrapper(
      adyenServices.submitDetails({
        type: 'booking',
        details: _state.data.details,
        paymentData: adyenPaymentData,
        paymentMethod,
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      savePaymentTrackingProps(paymentTrackingProps);
      context.successCallback({ paymentMethod, responseData: paymentAccepted.data });
      return;
    }

    const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

    if (paymentActionRequired.success) {
      dispatch?.({ type: 'ONHOLD_COF', payload: { cofThreeDS: paymentActionRequired.data } });
      return;
    }

    const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

    context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  };
}

function handleSubmitCofPayment(context: SubmitCofBookingContext, dispatch?: Dispatch<PrepayCheckoutAction>) {
  return async (storedCardId: string) => {
    const { bookingId, paymentTrackingProps, paymentMethod = CHECKOUT_PAYMENT_METHOD.STORED_COF, giftcards } = context;

    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(
      adyenServices.createPayment({
        id: context.bookingId,
        type: 'booking',
        giftcards: giftcards,
        paymentData: {
          paymentMethod: {
            type: 'storedCard',
            storedPaymentMethodId: storedCardId,
          },
          browserInfo: buildBrowserInfo(),
          redirectInfo: { returnPath: getValidateRedirectPathname(bookingId, 'booking') },
        },
        paymentMethod,
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      savePaymentTrackingProps(paymentTrackingProps);
      context.successCallback({ paymentMethod, responseData: paymentAccepted.data });
      return;
    }

    const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

    if (paymentActionRequired.success) {
      dispatch?.({ type: 'ONHOLD_COF', payload: { cofThreeDS: paymentActionRequired.data } });
      return;
    }

    const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);
    context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  };
}

async function handleInitQliroPayment(context: SubmitPrePaymentContext, dispatch: Dispatch<PrepayCheckoutAction>) {
  const paymentMethod = CHECKOUT_PAYMENT_METHOD.QLIRO;
  const { errorCallback, successCallback, paymentTrackingProps, giftcards } = context;

  dispatch({ type: 'SUBMITTING', payload: true });

  const { data, error } = await promiseWrapper(
    qliroServices.createPayment({ id: context.bookingId, type: 'booking', giftcards: giftcards }),
  );

  const validateResponse = initQliroResponseSchema.safeParse(data);

  if (validateResponse.success === false || error) {
    errorCallback(error);
    return;
  }

  savePaymentTrackingProps(paymentTrackingProps);
  successCallback({ paymentMethod, responseData: validateResponse.data });
}

async function handleSubmitPayWithGiftcard(context: SubmitPrePaymentContext, dispatch: Dispatch<PrepayCheckoutAction>) {
  const { paymentTrackingProps, giftcards, bookingId, successCallback } = context;
  const paymentMethod = CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE;

  dispatch({ type: 'SUBMITTING', payload: true });

  const { data, error } = await promiseWrapper(bookServices.addBookingGiftcards({ bookingId, giftcards }));

  const validatedResponse = addBookingGiftcardsResponseSchema.safeParse(data);

  if (error || !validatedResponse.success) {
    context.errorCallback(error);
    return;
  }

  savePaymentTrackingProps(paymentTrackingProps);
  successCallback({ paymentMethod, responseData: validatedResponse.data });
}

async function handleInitKlarnaPayment(context: SubmitPrePaymentContext, dispatch: Dispatch<PrepayCheckoutAction>) {
  const paymentMethod = CHECKOUT_PAYMENT_METHOD.KLARNA;
  const { errorCallback, successCallback, paymentTrackingProps, giftcards } = context;

  dispatch({ type: 'SUBMITTING', payload: true });

  const { data, error } = await promiseWrapper(
    bookServices.initKlarnaAfterBooking({ bookingId: context.bookingId, body: { giftcards: giftcards } }),
  );

  const validateResponse = initKlarnaAfterBookingResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    errorCallback(error);
    return;
  }

  savePaymentTrackingProps(paymentTrackingProps);
  successCallback({ paymentMethod, responseData: validateResponse.data });
}

export async function handleSubmitSwishPayment(
  context: SubmitPrePaymentContext,
  dispatch: Dispatch<PrepayCheckoutAction>,
) {
  const { successCallback, errorCallback, paymentTrackingProps, giftcards } = context;
  const paymentMethod = CHECKOUT_PAYMENT_METHOD.SWISH;

  dispatch({ type: 'SUBMITTING', payload: true });

  const { data, error } = await promiseWrapper(
    swishServices.swishPayment({
      type: 'booking',
      id: context.bookingId,
      giftcards: giftcards,
      ...(isIPhone() || isAndroidPhone()
        ? (() => {
            const validateUrl = new URL(
              `${url.getBaseUrl()}validate-swish-payment-redirect/booking/${context.bookingId}'`,
            );
            return {
              callbackUrl: validateUrl.toString(),
            };
          })()
        : {}),
    }),
  );

  const validateResponse = swishPaymentResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    errorCallback(error);
    return;
  }

  const { id, qrCode } = validateResponse.data;
  const domain = isSistaminuten() ? config.lastMinuteBaseUrl : config.baseUrl;
  const successUrl = `${domain}booking/confirmation?bookingId=${id}&type=swishActivatePayment`;

  if (!qrCode && !isIPhone() && !isAndroidPhone()) {
    errorCallback({
      clientError: _s(`${baseTranslationKey}.swishModal.snackbar.activatePaymentMessage`),
      message: 'Qr-code is missing',
      status: 500,
    });
    return;
  }

  const eventSource = swishServices.waitForSwishNotification(validateResponse.data.channel);
  dispatch({
    type: 'ONHOLD_SWISH',
    payload: {
      swish: {
        payment: validateResponse.data,
        notificationChannel: eventSource,
        showQrCode: !isIPhone() && !isAndroidPhone(),
      },
      paymentTrackingProps,
    },
  });

  if (isIPhone() || isAndroidPhone()) {
    window.location.href = validateResponse.data.url;
  }

  if (eventSource) {
    eventSource.onmessage = (e) => {
      const swishNotification = JSON.parse(e.data);

      if (swishNotification.success) {
        savePaymentTrackingProps(paymentTrackingProps);
        successCallback({ paymentMethod, responseData: { ...validateResponse.data, url: successUrl } });
      } else {
        if (swishNotification.keepAlive) {
          return;
        }

        eventSource.close();
        dispatch({ type: 'ERROR_SWISH' });
        toast(
          ({ closeToast }) => (
            <Snackbar
              type="danger"
              label={
                swishNotification.clientError
                  ? swishNotification.clientError
                  : _s(`${baseTranslationKey}.swishModal.snackbar.activatePaymentMessage`)
              }
              action={
                <button
                  onClick={() => {
                    closeToast();
                  }}>
                  {_s(`${baseTranslationKey}.swishModal.snackbar.cta`)}
                </button>
              }
            />
          ),
          {
            autoClose: 3000,
          },
        );
      }
    };

    eventSource.onerror = () => {
      dispatch({ type: 'ERROR_SWISH' });
      errorCallback({ message: 'Could not subscribe to swish notification events', status: 500 });
    };
  }
}

const usePrepayCheckoutManager = (
  booking: ConfirmedBooking,
  paymentHistory: PaymentHistoryResponse,
): UseCheckoutManagerResult => {
  const history = useHistory();
  const location = useLocation();
  const appDispatch = useDispatch();
  const { cards } = useCardsContext();
  // experiment with radio buttons
  const usePromotedPaymentMethods =
    useGetAmplitudeExperimentVariant()('promoted-payment-methods-web')?.value === 'variant-options-visible';
  const bookState = useAppSelector((state) => state.book) as BookState;
  const summary = getCheckoutSummary(
    booking,
    cards,
    bookState.usageReqId?.giftcards,
    usePromotedPaymentMethods ? paymentHistory : undefined,
  );

  const validSelectedPaymentMethod = selectedPaymentMethodSchema.safeParse(location.state?.selectedPaymentMethod);
  const savedSelectedPaymentMethod = validSelectedPaymentMethod.success ? validSelectedPaymentMethod.data : null;
  const hasSwish = summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.SWISH);
  const payLater = !hasSwish && !summary.isOnlinePaymentRequired;
  const addedGiftcardsList = Object.values(bookState.usageReqId?.giftcards || {}).map((giftcard) => giftcard);

  const preSelectedPaymentMethod = ((): CheckoutSummary['preSelectedPaymentMethod'] => {
    const tryGetSavedSelectedPaymentMethod = (() => {
      if (!savedSelectedPaymentMethod) return undefined;

      const validateIsCoF = selectedStoredCofPaymentMethodSchema.safeParse(savedSelectedPaymentMethod);

      if (validateIsCoF.success) {
        const card = cards.find((card) => card.id === validateIsCoF.data.id);
        if (!card) return undefined;

        const { total, totalLabel, final } = validateIsCoF.data;

        return {
          type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
          brand: card.brand,
          id: card.id,
          lastFour: card.lastFour,
          total,
          final,
          totalLabel,
        };
      } else {
        const validateIsAvailablePreSelectedMethod =
          availablePrePaymentMethodSchema.safeParse(savedSelectedPaymentMethod);

        if (validateIsAvailablePreSelectedMethod.success) {
          return validateIsAvailablePreSelectedMethod.data;
        }
      }

      return undefined;
    })();
    if (tryGetSavedSelectedPaymentMethod) return tryGetSavedSelectedPaymentMethod;
    return summary.preSelectedPaymentMethod;
  })();

  const selectedPaymentMethod =
    preSelectedPaymentMethod ||
    summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.NONE);

  const initialState: PrepayCheckoutState = {
    selectedPaymentMethod,
    summary,
    submitting: false,
    error: null,
    missingActions: [],
  };

  const [state, dispatch] = useReducer(prePayCheckoutReducer, initialState);

  useEffect(() => {
    appDispatch(bookActions.addUuid(booking.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * We have to set payment method to pay at place if we can't pay online, for example if we
     * apply a giftcard that covers the whole amount
     */
    if (!summary.canPayOnline)
      dispatch({ type: 'SET_PAYMENT_METHOD', payload: { type: CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE } });
  }, [summary.canPayOnline]);

  useEffect(() => {
    dispatch({ type: 'ERROR_SWISH' });
    if (bookState.successGiftCard === false) return;

    dispatch({
      type: 'UPDATE_SUMMARY',
      payload: getCheckoutSummary(
        booking,
        cards,
        bookState.usageReqId?.giftcards,
        usePromotedPaymentMethods ? paymentHistory : undefined,
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookState.usageReqId?.giftcards]);

  const handleChangePaymentMethod = (method: SelectedPaymentMethod) => {
    dispatch({ type: 'SET_PAYMENT_METHOD', payload: method });
  };

  const handleDismissSwishQRCode = () => {
    state.swish?.notificationChannel?.close?.();
    dispatch({ type: 'CLOSE_SWISH_QRCODE_MODAL' });
  };

  const handleRemoveGiftcard = (code: string) => () => {
    const { usageReqId } = bookState;
    const { summary } = state;
    const giftcardCoveredBookingAmount = !summary.canPayOnline;

    // @ts-ignore
    appDispatch(bookActions.removeGiftCard({ usageRequestId: usageReqId.uuid, code: code }));
    dispatch({
      type: 'UPDATE_SUMMARY',
      payload: getCheckoutSummary(
        booking,
        cards,
        bookState.usageReqId?.giftcards,
        usePromotedPaymentMethods ? paymentHistory : undefined,
      ),
    });

    dispatch({ type: 'ERROR_SWISH' });

    /**
     * if the giftcard covered the total amount of the booking and is removed
     * we unselect the checkout payment method
     */
    if (giftcardCoveredBookingAmount) {
      dispatch({ type: 'SET_PAYMENT_METHOD', payload: { type: CHECKOUT_PAYMENT_METHOD.NONE } });
    }
  };

  const handleApplyGiftcard = (code: string) => (successCallback: () => void) => {
    const { place, services, start } = booking;
    const { usageReqId } = bookState;
    const { finalWithoutGiftcards } = state.summary;
    const employeeId = services[0].employee.id;
    const startAppointmentTime = new Date(start * 1000).toISOString();

    if (!usageReqId) {
      Sentry.captureMessage('usageReqId is missing in prepayCheckout');
      return;
    }

    const giftcardRequestBody: ValidateGiftCardRequest = {
      code,
      place: place.id,
      usageRequestId: usageReqId.uuid,
      personId: employeeId,
      startTime: startAppointmentTime,
      serviceIds: services?.map((service) => service.service.id),
      formId: 0,
      amount: finalWithoutGiftcards * 100,
    };

    dispatch({ type: 'ERROR_SWISH' });
    // @ts-ignore
    appDispatch(bookActions.addGiftCard(giftcardRequestBody, successCallback));
  };

  const handleOnCheckoutMissingAction = (action: CheckoutMissingAction) => {
    dispatch({ type: 'SET_MISSING_ACTION', payload: action });
  };

  const prepayCheckoutAPI = (): CheckoutAPI => {
    const context: SubmitPrePaymentContext = {
      paymentTrackingProps: getPaymentMethodsTracking({
        selectedPaymentMethod: state.selectedPaymentMethod,
        summary,
        giftcards: addedGiftcardsList,
      }),
      bookingId: booking.id,
      giftcards: bookState.usageReqId?.giftcards ? Object.values(bookState.usageReqId?.giftcards) : null,
      errorCallback: (error) => {
        const errorData = errorResponseSchema.safeParse(error);
        dispatch({ type: 'ERROR', payload: true });

        const displayClientError = (errorMessage?: string) => {
          toast(
            ({ closeToast }) => (
              <Snackbar
                label={errorMessage ?? _s('serverError')}
                type="danger"
                onClose={() => {
                  dispatch({ type: 'ERROR', payload: false });
                  closeToast();
                }}
              />
            ),
            {
              autoClose: 5000,
            },
          );
        };

        if (!errorData.success) {
          displayClientError();
          Sentry.captureException(error);
          return;
        }

        const { clientError } = errorData.data;

        displayClientError(clientError);
        Sentry.captureException(error);
      },

      successCallback: ({ paymentMethod, responseData }) => {
        dispatch({ type: 'SUCCESS_REDIRECT', payload: paymentMethod });
        switch (paymentMethod) {
          case CHECKOUT_PAYMENT_METHOD.KLARNA:
            history.push({
              pathname: `/booking/checkout/${booking.id}/klarna`,
              state: { klarnaOrder: responseData },
            });
            break;
          case CHECKOUT_PAYMENT_METHOD.QLIRO:
            history.push({
              pathname: `/booking/checkout/${booking.id}/qliro`,
              state: { qliroOrder: responseData, booking },
            });
            break;
          case CHECKOUT_PAYMENT_METHOD.STORED_COF:
          case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
          case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
            const selectedPaymentMethod =
              state.selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF
                ? {
                    type: state.selectedPaymentMethod.type,
                    id: state.selectedPaymentMethod.id,
                    brand: state.selectedPaymentMethod.brand,
                    lastFour: state.selectedPaymentMethod.lastFour,
                  }
                : state.selectedPaymentMethod;

            history.push({
              pathname: `/booking/confirmation`,
              search: `?bookingId=${booking.id}&type=cofActivatePayment`,
              state: { ...(selectedPaymentMethod && { selectedPaymentMethod }) },
            });
            break;
          case CHECKOUT_PAYMENT_METHOD.SWISH: {
            window.location.href = responseData.url;
            break;
          }
          case CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE: {
            history.push({
              pathname: `/booking/confirmation`,
              search: `?b=${responseData.bookingCode}&type=giftcardActivatePayment`,
            });
            break;
          }
          default:
            break;
        }
      },
    };

    const applePaySummary = {
      services: getApplePaySummaryServiceItems(mapConfirmedBookingInfoServices(booking), payLater),
    };

    const googlePaySummary = {
      services: getGooglePaySummaryServiceItems(mapConfirmedBookingInfoServices(booking), payLater),
    };

    return {
      submitPayAtPlace: () => handleSubmitPayWithGiftcard(context, dispatch),
      initKlarna: () => handleInitKlarnaPayment(context, dispatch),
      initQliro: () => handleInitQliroPayment(context, dispatch),
      submitCoF: handleSubmitCofPayment(context, dispatch),
      submitGooglePay: handleSubmitGooglePay({ ...context, ...googlePaySummary }, dispatch),
      submitApplePay: handleSubmitApplePay({ ...context, ...applePaySummary }, dispatch),
      submitThreeDS: handleSubmitThreeDSPayment({ ...context, cofThreeDS: state.cofThreeDS }, dispatch),
      submitSwish: () => handleSubmitSwishPayment(context, dispatch),
    };
  };

  return {
    summary: state.summary,
    submitting: state.submitting,
    error: state.error,
    selectedPaymentMethod: state.selectedPaymentMethod,
    missingActions: state.missingActions,
    cofThreeDS: state.cofThreeDS,
    swish: state.swish,
    onRemoveGiftcard: handleRemoveGiftcard,
    onDismissSwishQRCode: handleDismissSwishQRCode,
    onApplyGiftcard: handleApplyGiftcard,
    onChangePaymentMethod: handleChangePaymentMethod,
    onCheckoutMissingAction: handleOnCheckoutMissingAction,
    prepayCheckoutAPI,
  };
};

export const usePrepayCheckout = () => {
  const context = useContext(CheckoutContext);

  if (!context) {
    throw new Error('useCheckout must be used within a CheckoutProvider');
  }

  return context;
};

export const PrepayCheckoutProvider = ({
  children,
  booking,
  paymentHistory,
}: {
  children: React.ReactNode;
  booking: ConfirmedBooking;
  paymentHistory: PaymentHistoryResponse;
}) => {
  return (
    <CheckoutContext.Provider value={usePrepayCheckoutManager(booking, paymentHistory)}>
      {children}
    </CheckoutContext.Provider>
  );
};
