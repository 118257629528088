import Header from '@/components/elements/Header/Header';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import BundleListItem from '@/components/elements/lists/BundleCardListItem/BundleCardListItem';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { InitialsAvatar, PersonAvatar } from '@/components/elements/redesign/Avatar';
import Icon from '@/components/icons/Icon';
import { IconVariant } from '@/components/icons/types';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import AppointmentDetailsSummary from '@/components/modules/appointment/AppointmentDetailsSummary';
import AddToCalendarModal, { useAddToCalendarModal } from '@/components/modules/modals/AddToCalendar';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import EmptyState from '@/components/templates/EmptyState';
import { SCREEN_NAME } from '@/constants/analytics';
import {
  ADD_TO_CALENDAR_ACTION_ITEM,
  APPOINTMENT_PAYMENT_METHOD,
  BOOK_AGAIN_ACTION_ITEM,
  CANCEL_BOOKING_ACTION_ITEM,
  CHANGE_BOOKING_TIME_ACTION_ITEM,
  PAY_ONLINE_ACTION_ITEM,
} from '@/constants/appointmentConstants';
import {
  canPayCardOnFileAfterBooking,
  canPayKlarnaAfterBooking,
  canPayQliroAfterBooking,
  isGroupBooking as checkIfGroupBooking,
  classnames,
  getCanCancelAppointmentProps,
  getIsBookable,
  getUserInitials,
  isBookingInThePast,
  isSistaminuten,
  promiseWrapper,
  trackMpEvent,
} from '@/helpers';
import { getBundleListContentProps } from '@/helpers/bundle';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView, { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { bookServices as oldBookServices } from '@/services/bookServices';
import { bookServices } from '@/services/bookServicesTs';
import { handleLoginClick } from '@/services/navigationServices';
import { ScreenViewEvent } from '@/types/analytics';
import { ConfirmedBooking } from '@/types/api/services/booking';
import { bookingSchema } from '@/types/api/services/booking/schema';
import { AppointmentActionIdentifier, AppointmentActionItem } from '@/types/appointment';
import { NavItemIdentifier } from '@/types/navigation';
import { Dispatch, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getVisibleGroupBookingParticipantFields,
  handleBookAgain,
  handleCancelAppointment,
} from './AppointmentDetails.helpers';
import {
  AppointmentDetailsAction,
  AppointmentDetailsState,
  GroupBookingParticipantInfo,
} from './AppointmentDetails.types';

const baseTranslationKey = 'pages.bokning.$id.AppointmentDetails';

const participantInfoMap: {
  [key in keyof GroupBookingParticipantInfo]: {
    icon: IconVariant;
    label: string;
    displayOrder: number;
  };
} = {
  givenName: { displayOrder: 1, icon: 'user', label: _s('participantInfo.givenName') },
  familyName: { displayOrder: 2, icon: 'user', label: _s('participantInfo.familyName') },
  email: { displayOrder: 3, icon: 'email', label: _s('participantInfo.email') },
  mobile: { displayOrder: 4, icon: 'phone', label: _s('participantInfo.mobile') },
  phone: { displayOrder: 5, icon: 'phone', label: _s('participantInfo.phone') },
  postalCode: { displayOrder: 6, icon: 'home', label: _s('participantInfo.postalCode') },
  streetAddress: { displayOrder: 7, icon: 'home', label: _s('participantInfo.streetAddress') },
  locality: { displayOrder: 8, icon: 'home', label: _s('participantInfo.locality') },
  message: { displayOrder: 9, icon: 'sms', label: _s('message') },
  cancellationCode: { displayOrder: 10, icon: 'calendar-cross', label: _s('participantInfo.cancellationCode') },
} as const;

const getScreenViewEvent = (appointment: ConfirmedBooking): ScreenViewEvent => {
  return {
    name: 'screen_view_booking_details',
    properties: { booking_id: appointment.id, company_id: appointment.place.id, customer_id: appointment.client },
  };
};

const AppointmentDetails = ({
  state,
  dispatch,
}: {
  state: AppointmentDetailsState;
  dispatch: Dispatch<AppointmentDetailsAction>;
}) => {
  const location = useLocation();
  const history = useHistory();
  const { appointment, submitting } = state;
  const [participantInfoModal, setParticipantInfoModal] = useState<GroupBookingParticipantInfo | null>(null);
  const { isMobileView } = useMobileView();

  const groupBookingProps = checkIfGroupBooking(appointment, true, true);
  const isGroupBooking = Boolean(groupBookingProps.groupBooking > 0);
  const appliedBundle = appointment.appliedBundle;

  const handleShowParticipantInfoModal = (participant: GroupBookingParticipantInfo) => {
    setParticipantInfoModal(participant);
    trackScreenView({ name: 'screen_view_participant_details' });
  };

  const handleCloseParticipantInfoModal = () => {
    setParticipantInfoModal(null);
    trackScreenView(getScreenViewEvent(appointment));
  };

  const handleBackButtonClick = () => {
    if (isSistaminuten()) {
      history.push('/');
      return;
    }
    history.push(location?.state?.from ?? '/bokningar');
  };

  useTrackScreenView(getScreenViewEvent(appointment));

  useEffect(() => {
    if (location.state?.changedBookingTime) {
      toast(({ closeToast }) => (
        <Snackbar
          onClose={closeToast}
          label={_s(`${baseTranslationKey}.snackbar.changeBookingTimeSuccess`)}
          type="success"
        />
      ));

      history.replace({
        pathname: location.pathname,
        state: { ...(location?.state ?? {}), changedBookingTime: false },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      onBackButtonClick={handleBackButtonClick}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      {submitting && <LoadingPlaceHolder className="fixed h-screen" />}
      <div className="lg:py-xxl lg:container">
        <div className="gap-xxl flex items-start">
          <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
            <CardWrapper className="bg-white">
              <AppointmentDetailsSummary appointment={appointment} />
            </CardWrapper>

            {appliedBundle && (
              <CardWrapper className="bg-white">
                <div className="py-lg pl-lg">
                  <Header label={_s(`${baseTranslationKey}.section.bundle.title`)} size="lg" />
                </div>

                <BundleListItem
                  {...getBundleListContentProps(appliedBundle)}
                  src={`/klippkortdetaljer/${appliedBundle.id}`}
                />
              </CardWrapper>
            )}

            {isGroupBooking && (
              <CardWrapper className="bg-white">
                <div className="py-lg pl-lg">
                  <Header label={_s(`${baseTranslationKey}.section.participants.title`)} size="lg" />
                </div>
                {groupBookingProps.personsInfo.map((participantInfo, key) => {
                  const title = getVisibleGroupBookingParticipantFields(participantInfo);
                  const subTitle = getVisibleGroupBookingParticipantFields(participantInfo, title);
                  const { givenName, familyName } = participantInfo;
                  const userInitials = givenName && familyName && getUserInitials({ about: { givenName, familyName } });

                  return (
                    <ListItem
                      key={key}
                      leftSlot={
                        userInitials ? (
                          <InitialsAvatar initials={userInitials} size="md" variant="default" />
                        ) : (
                          <PersonAvatar alt="" size="md" variant="default" />
                        )
                      }
                      onClick={() => handleShowParticipantInfoModal(participantInfo)}
                      rightSlot={<Icon variant="chevron-s-right" />}
                      underline>
                      <ListItemContent subTitle={subTitle} title={title} />
                    </ListItem>
                  );
                })}
              </CardWrapper>
            )}
            <CardWrapper className="w-full lg:hidden">
              <div className="p-lg empty:hidden">
                <AppointmentActions appointment={appointment} dispatch={dispatch} />
              </div>
            </CardWrapper>
          </div>
          <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
            <CardWrapper className="w-full empty:hidden">
              <AppointmentActions appointment={appointment} dispatch={dispatch} />
            </CardWrapper>
          </div>
        </div>
      </div>
      <Modal isOpen={Boolean(participantInfoModal)}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header
            title={_s(`${baseTranslationKey}.modal.participant.header`)}
            onClose={handleCloseParticipantInfoModal}
          />
          {Object.entries(participantInfoModal || {})
            .map(([key, value]) => {
              const { icon, label, displayOrder } = participantInfoMap[key] || {};
              if (!icon || !label || !value) return null;
              return { icon, label, value, displayOrder };
            })
            .filter(Boolean)
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(({ icon, label, value }, key) => (
              <ListItem key={label} leftSlot={<Icon variant={icon} />} underline>
                <ListItemContent content={value} title={label} />
              </ListItem>
            ))}
        </Modal.Content>
      </Modal>
    </PageViewLayout>
  );
};

/**
 * If the action identifier is not in the map, click event will not be tracked
 */
const actionIdentifierEventNameMap: {
  [key in AppointmentActionIdentifier]?: `${AppointmentActionIdentifier}_click`;
} = {
  [AppointmentActionIdentifier.PAY_ONLINE]: 'pay_online_click',
  [AppointmentActionIdentifier.CHANGE_BOOKING_TIME]: 'change_booking_time_click',
  [AppointmentActionIdentifier.BOOK_AGAIN]: 'book_again_click',
  [AppointmentActionIdentifier.CANCEL_BOOKING]: 'cancel_booking_click',
  [AppointmentActionIdentifier.ADD_TO_CALENDAR]: 'add_to_calendar_click',
};

const AppointmentActions = ({
  appointment,
  dispatch,
}: {
  appointment: ConfirmedBooking;
  dispatch: Dispatch<AppointmentDetailsAction>;
}) => {
  const {
    onOpen: handleOpenCalendarModal,
    onClose: onCloseCalendarModal,
    ...addToCalendarProps
  } = useAddToCalendarModal(appointment);
  const { isMobileView } = useMobileView();
  const appDispatch = useDispatch();
  const [showCancelAppointmentModal, setShowCancelAppointmentModal] = useState(false);
  const { user } = useAppSelector((state) => state.users);
  const isPaidOffline = appointment.extra?.paymentMethod === APPOINTMENT_PAYMENT_METHOD.PAY_AT_PLACE;
  const canPayKlarna = canPayKlarnaAfterBooking(appointment) && !isSistaminuten();
  const canPayQliro = canPayQliroAfterBooking(appointment) && !isSistaminuten();
  const canPayCardOnFile = canPayCardOnFileAfterBooking(appointment) && !isSistaminuten();

  const showActivatePaymentCTA = (canPayKlarna || canPayQliro || canPayCardOnFile) && isPaidOffline;

  const isBookable = getIsBookable(appointment);
  const { canCancel: showCancelAppointmentCTA } = getCanCancelAppointmentProps(appointment);
  const showBookAgainCTA = (isBookable && isBookingInThePast(appointment)) || appointment.status !== 1;
  const showChangeAppointmentTimeCTA = showCancelAppointmentCTA && isBookable;

  const { handleClick: handleBookAgainClick, bookAgainUrl } = handleBookAgain(appointment, appDispatch);

  const handleCloseCalendarModal = () => {
    onCloseCalendarModal();
    trackScreenView(getScreenViewEvent(appointment));
  };

  const handleOpenCancelAppointmentModal = () => {
    setShowCancelAppointmentModal(true);
    trackScreenView({ name: 'screen_view_cancel_booking', properties: { trigger_source: 'booking_details' } });
  };

  const handleCloseCancelAppointmentModal = () => {
    setShowCancelAppointmentModal(false);
    trackScreenView(getScreenViewEvent(appointment));
  };

  const handleConfirmCancelAppointmentClick = () => {
    trackMpEvent(actionIdentifierEventNameMap['cancel_booking'], { trigger_source: SCREEN_NAME.BOOKING_DETAILS });
    handleCloseCancelAppointmentModal();
    handleCancelAppointment(appointment, user, appDispatch, dispatch);
  };

  const handleActionClick = (identifier: AppointmentActionIdentifier) => {
    const event = actionIdentifierEventNameMap?.[identifier];

    if (event) trackMpEvent(event, { trigger_source: SCREEN_NAME.BOOKING_DETAILS });

    switch (identifier) {
      case AppointmentActionIdentifier.PAY_ONLINE:
        return;
      case AppointmentActionIdentifier.CHANGE_BOOKING_TIME:
        handleBookAgainClick(true);
        return;
      case AppointmentActionIdentifier.ADD_TO_CALENDAR:
        handleOpenCalendarModal();
        return;
      case AppointmentActionIdentifier.BOOK_AGAIN:
        handleBookAgainClick();
        return;
      case AppointmentActionIdentifier.CANCEL_BOOKING:
        handleOpenCancelAppointmentModal();
        return null;
    }
  };

  const actions = (() => {
    const list: AppointmentActionItem[] = [
      ...(showActivatePaymentCTA
        ? [{ ...PAY_ONLINE_ACTION_ITEM, to: { pathname: `/booking/checkout/${appointment.id}` } }]
        : []),
      ...(showChangeAppointmentTimeCTA ? [{ ...CHANGE_BOOKING_TIME_ACTION_ITEM, to: { pathname: bookAgainUrl } }] : []),
      ...(showBookAgainCTA ? [{ ...BOOK_AGAIN_ACTION_ITEM, to: { pathname: bookAgainUrl } }] : []),
      ...(isBookable ? [{ ...ADD_TO_CALENDAR_ACTION_ITEM }] : []),
      ...(showCancelAppointmentCTA ? [{ ...CANCEL_BOOKING_ACTION_ITEM }] : []),
    ];

    return list;
  })();

  return (
    actions.length > 0 && (
      <div className="space-y-md flex w-full flex-col">
        {actions.map((action, key) => {
          const Action = action.to ? LinkButton : Button;
          const isFirstItem = key === 0;
          const variant = action.variant === 'link' ? 'link' : isFirstItem ? 'primary' : 'secondary';
          const iconProps = (() => {
            if (!action.icon) return null;
            return { variant: action.icon, color: action.iconColor };
          })();

          return (
            <Action
              key={action.identifier}
              label={action.label}
              variant={variant}
              size="md"
              onClick={() => handleActionClick(action.identifier)}
              {...(action.to && { to: action.to })}
              {...(iconProps && { leftIcon: <Icon {...iconProps} /> })}
            />
          );
        })}
        <AddToCalendarModal onClose={handleCloseCalendarModal} {...addToCalendarProps} />
        <Modal isOpen={showCancelAppointmentModal}>
          <Modal.Content floating={!isMobileView}>
            <Modal.Header
              title={_s(`${baseTranslationKey}.modal.cancel.header`)}
              onClose={handleCloseCancelAppointmentModal}
            />
            <div className={classnames('gap-xl flex flex-col', isMobileView && 'px-lg')}>
              <p>{_s(`${baseTranslationKey}.modal.cancel.body`)}</p>
              <div className="pt-lg flex flex-col">
                <Button onClick={handleCloseCancelAppointmentModal} variant="primary">
                  {_s(`${baseTranslationKey}.modal.cancel.cta.keepBooking`)}
                </Button>
                <Button onClick={handleConfirmCancelAppointmentClick} variant="link" className="text-danger-500">
                  {_s(`${baseTranslationKey}.modal.cancel.cta.cancelBooking`)}
                </Button>
              </div>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    )
  );
};

async function fetchAndSetAppointmentDetails(id: number, dispatch: Dispatch<AppointmentDetailsAction>) {
  const getAppointmentDetails = isSistaminuten() ? oldBookServices.getSistaminutenBooking : bookServices.appointment;
  const { data, error } = await promiseWrapper(getAppointmentDetails(id));

  const validate = bookingSchema.safeParse(data?.booking);

  if (validate.success === false || error) {
    dispatch({ type: 'ERROR' });
    return;
  }

  dispatch({ type: 'SET_APPOINTMENT', payload: validate.data });
}

function appointmentReducer(state: AppointmentDetailsState, action: AppointmentDetailsAction) {
  switch (action.type) {
    case 'SET_APPOINTMENT':
      return {
        appointment: action.payload,
        loading: false,
        error: false,
        submitting: false,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        submitting: false,
      };
    case 'SUBMITTING':
      return {
        ...state,
        submitting: action.payload,
      };
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const routeMatch = useRouteMatch();
  const user = useAppSelector((state) => state.users)?.user;
  const appDispatch = useDispatch();
  const { isMobileView } = useMobileView();
  const isLoggedInUser = Boolean(user && user.token) || isSistaminuten();

  const { id } = routeMatch.params;

  const [state, dispatch] = useReducer(appointmentReducer, {
    appointment: undefined,
    error: false,
    loading: true,
    submitting: false,
  });

  useEffect(() => {
    fetchAndSetAppointmentDetails(id, dispatch);
  }, [id]);

  if (!isLoggedInUser) {
    return (
      <PageViewLayout
        type="subView"
        title={_s(`${baseTranslationKey}.page-title`)}
        back
        backSrc="/bokningar"
        wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
        <EmptyState
          title={_s(`${baseTranslationKey}.emptystate.title`)}
          body={_s(`${baseTranslationKey}.emptystate.body`)}
          icon={<img src="/images/illustrations/bookings2.png" alt="" />}
          cta={
            <Button
              label={_s(`${baseTranslationKey}.emptystate.cta`)}
              size="sm"
              onClick={() => handleLoginClick(appDispatch, NavItemIdentifier.LogIn, SCREEN_NAME.BOOKING_DETAILS)}
            />
          }
        />
      </PageViewLayout>
    );
  }
  if (state.loading) return <LoadingPlaceHolder />;
  if (state.error) return <GoBack />;

  return <AppointmentDetails state={state} dispatch={dispatch} />;
}
