import { showNavigationDrawer } from '@/actions';
import Badge from '@/components/elements/Badge/Badge';
import { Button, Fab } from '@/components/elements/forms/buttons';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Divider from '@/components/elements/lists/Divider/Divider';
import { InitialsAvatar } from '@/components/elements/redesign/Avatar';
import Icon from '@/components/icons/Icon';
import { NAVIGATION_TRIGGER } from '@/constants/navigation';
import { DrawerItem, navigationDrawerItems } from '@/constants/navigationDrawerConstants';
import { Z_INDEX } from '@/constants/styleConstants';
import { classnames, getUserInitials, isSistaminuten } from '@/helpers';
import { themed } from '@/helpers/theme';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { _s, getLang, setLang } from '@/locale';
import { getScreenName, handleNavItemClicked } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ShadowModal } from '../../modals';
import styles from './NavigationDrawer.module.scss';

const LanguageButton = ({ lang, active, onClick }) => (
  <button
    onClick={onClick}
    className={`!my-1 flex w-full items-center justify-between rounded px-3 py-3 ${
      active ? themed('bg-primary-50', 'bg-sm_primary-100') : 'hover:bg-black-100'
    }`}>
    <div className="text-left">
      <div>{_s(`${lang}Short`)}</div>
      <div className="text-black-600 text-sm">{_s(`${lang}Country`)}</div>
    </div>
    {getLanguageIcon(lang)}
  </button>
);

const getLanguageIcon = (lang) => (
  <Icon variant={lang === 'sv' ? 'swedish' : 'english'} noFilter />
);

const NavigationDrawer = ({ source = 'some_menu' }: { source?: string }) => {
  const user = useAppSelector((state) => state.users?.user);
  const { show: open = false } = useAppSelector((state) => state?.navigationDrawer) as { show: boolean };
  const [show, setShow] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const { isMobileView } = useMobileView();
  const dispatch = useDispatch();
  const selectLanguage = (lang) => () => setLang(lang) && setShowLanguageModal(false);
  const currentLang = getLang();

  const handleNavItemClick = (identifier: NavItemIdentifier) => {
    handleNavItemClicked(dispatch, getScreenName(source), NAVIGATION_TRIGGER.NAV_DRAWER)(identifier)();
    dispatch(showNavigationDrawer({ show: false }));
  };
  const sorted = navigationDrawerItems(user).reduce(
    (
      acc: {
        account: DrawerItem[];
        offers: DrawerItem[];
        more: DrawerItem[];
      },
      item,
    ) => {
      switch (item.category) {
        case 'account':
          if (item.identifier === NavItemIdentifier.Bundles && isSistaminuten()) {
            break;
          }
          acc.account.push(item);
          break;
        case 'offer':
          acc.offers.push(item);
          break;
        default:
          acc.more.push(item);
      }

      return acc;
    },
    {
      account: [],
      offers: [],
      more: [],
    },
  );

  useEffect(() => {
    if (open) setShow(true);
    if (!open && show) {
      setTimeout(() => !open && setShow(false), 200);
    }
  }, [open]);

  return (
    <>
      <div
        id="navigation-drawer"
        className={classnames(
          'py-lg no-scrollbar fixed right-0 top-0 h-full w-full overflow-y-auto bg-white shadow transition-transform duration-200 md:w-[390px] md:px-2',
          open ? 'translate-x-0 md:[transform:translateX(100%)_translateX(-390px)]' : 'translate-x-full',
          show ? '' : 'invisible',
        )}
        style={{ zIndex: Z_INDEX.NAVIGATION_DRAWER }}
        aria-hidden={!open}>
        {!isMobileView && (
          <div className="p-lg">
            <Fab
              id="close-navigation-drawer"
              onClick={() => dispatch(showNavigationDrawer({ show: false }))}
              size="sm"
              variant="primary"
              icon={<Icon variant="close" />}
            />
          </div>
        )}
        <nav>
          <ul className="gap-xs flex flex-col">
            {user && (
              <li className="gap-xs flex flex-col items-center">
                <InitialsAvatar initials={getUserInitials(user)} size="lg" variant="default" />
                <span className="text-md font-bold">
                  {user.about?.givenName} {user.about?.familyName}
                </span>
              </li>
            )}
            <Divider label={_s('myAccount')} size="sm" />
            {sorted.account.map((item, key) => {
              const missingActiveCard = item.identifier === NavItemIdentifier.PaymentMethods && !user?.activeCoF;
              return (
                <ListItem
                  key={key}
                  leftSlot={<Icon variant={item.icon} />}
                  rightSlot={<Icon variant={item.externalLink ? 'external-link' : 'chevron-right'} />}
                  targetBlank={item.externalLink}
                  to={item.to}
                  onClick={() => handleNavItemClick(item.identifier)}
                  data-cy={`nav-drawer-${item.identifier}`}>
                  <ListItemContent
                    title={item.label}
                    badge={missingActiveCard ? <Badge label="!" /> : undefined}
                    {...(missingActiveCard
                      ? { subTitle: _s('missingActiveCard'), subTitleClassName: 'text-danger-500' }
                      : {})}
                  />
                </ListItem>
              );
            })}
            {!isSistaminuten() && (
              <>
                <Divider label={_s('offers')} size="sm" />
                {sorted.offers.map((item, key) => {
                  /**
                   * We can remove this when 2023 is over
                   */
                  const isWellnessBuy = item.identifier === NavItemIdentifier.WellnessCardBuy;
                  const daysUntilCampaingEnds = moment('01/01/2024', 'DD/MM/YYYY').diff(moment(), 'days') + 1;
                  const label =
                    isWellnessBuy && daysUntilCampaingEnds > 0 && _s('daysLeft', { count: daysUntilCampaingEnds });

                  return (
                    <ListItem
                      key={key}
                      leftSlot={<Icon variant={item.icon} />}
                      rightSlot={<Icon variant={item.externalLink ? 'external-link' : 'chevron-right'} />}
                      targetBlank={item.externalLink}
                      to={item.to}
                      onClick={() => handleNavItemClick(item.identifier)}>
                      <ListItemContent
                        title={item.label}
                        {...(label && { label: { label, className: 'bg-highlight-500' } })}
                      />
                    </ListItem>
                  );
                })}
              </>
            )}
            <Divider label={_s('More')} size="sm" />
            {sorted.more.map((item, key) => (
              <ListItem
                key={key}
                leftSlot={<Icon variant={item.icon} />}
                rightSlot={<Icon variant={item.externalLink ? 'external-link' : 'chevron-right'} />}
                targetBlank={item.externalLink}
                to={item.to}
                onClick={() => handleNavItemClick(item.identifier)}>
                <ListItemContent title={item.label} />
              </ListItem>
            ))}
            <ListItem
              className="relative"
              leftSlot={<Icon variant={currentLang === 'sv' ? 'swedish' : 'english'} noFilter />}
              rightSlot={<Icon variant="chevron-right" />}
              onClick={() => setShowLanguageModal(true)}>
              <ListItemContent title={_s(currentLang)} />
            </ListItem>
          </ul>
        </nav>
        {isMobileView && <div style={{ height: 80 }}></div>}
      </div>
      {show && (
        <div
          className={classnames(
            'fixed left-0 top-0 h-full w-full cursor-pointer bg-[rgba(0,0,0,0.2)] bg-opacity-20',
            open && styles.show,
            !open && styles.hide,
          )}
          style={{ zIndex: Z_INDEX.NAVIGATION_DRAWER_OVERLAY }}
          onClick={() => dispatch(showNavigationDrawer({ show: false }))}></div>
      )}
      <ShadowModal isOpen={showLanguageModal} onRequestClose={() => setShowLanguageModal(false)} className="relative">
        <button
          className={`absolute right-4 top-4 focus:outline-none focus:ring ${themed(
            'focus:ring-primary-200',
            'focus:ring-sm_primary',
          )} rounded p-1`}
          onClick={() => setShowLanguageModal(false)}>
          <Icon variant="close" className="text-black-900 h-10 w-10" />
        </button>
        <h2 className="py-3 text-3xl">{_s('offCanvasNavigation.selectLanguageModal.title')}</h2>
        <LanguageButton lang="sv" onClick={selectLanguage('sv')} active={currentLang === 'sv'} />
        <LanguageButton lang="en" onClick={selectLanguage('en')} active={currentLang === 'en'} />
        <Button variant="tertiary" block size="sm" className="mt-4" onClick={() => setShowLanguageModal(false)}>
          {_s('close')}
        </Button>
      </ShadowModal>
    </>
  );
};

export default NavigationDrawer;
