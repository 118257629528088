import { DropdownButton } from '@/components/elements/forms/DropdownButton';
import { LangEnFlagIcon, LangSeFlagIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { Dropdown } from '@/components/modules/dropdown';
import { footerTosItems } from '@/constants/footerNavigationConstants';
import { NAVIGATION_TRIGGER } from '@/constants/navigation';
import { isServer, isSistaminuten, trackMpEvent, url } from '@/helpers';
import { _s, getLang, getLanguages, setLang } from '@/locale';
import { getScreenName } from '@/services/navigationServices';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const SocialMediaItems = [
  <a key="instagram" target="_blank" rel="noreferrer" href="https://instagram.com/bokadirekt_se">
    <Icon variant="instagram" className="h-6" alt="instagram" />
  </a>,
  <a key="linkedin" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/bokadirekt-i-stockholm-ab/">
    <Icon variant="linkedin" className="h-6" alt="linkedin" />
  </a>,
  <a key="facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/bokadirekt">
    <Icon variant="facebook" className="h-6" alt="facebook" />
  </a>,
];

export const PaymentLogosIcons = [
  <Icon ext="svg" key="klarna" noFilter variant="payment-method-klarna" className="h-3xl" />,
  <Icon ext="svg" key="qliro" variant="payment-method-qliro" className="h-3xl" noFilter />,
  <Icon ext="svg" key="visa" noFilter variant="payment-method-visa" className="h-3xl" />,
  <Icon ext="svg" key="mastercard" noFilter variant="payment-method-mastercard" className="h-3xl" />,
  <Icon ext="svg" key="apple-pay" noFilter variant="payment-method-apple-pay" className="h-3xl" />,
  <Icon ext="svg" key="google-pay" noFilter variant="payment-method-google-pay" className="h-3xl" />,
];

const getTosItems = (className: string) => {
  return (
    <div className={`flex-wrap gap-4 ${className}`}>
      {footerTosItems.map((link, key) => {
        if (link.link) {
          return (
            <Link key={key} className="text-black-900 hover:text-black-900 text-m" to={link.url}>
              {link.title}
            </Link>
          );
        } else {
          return (
            <a key={key} href={link.url} className="text-black-900 hover:text-black-900 text-m">
              {link.title}
            </a>
          );
        }
      })}
    </div>
  );
};

const LanguageItem = ({ isListPage, className }: { isListPage: boolean; className: string }) => {
  const [showLang, setShowLang] = useState(false);

  const changeLanguage = (lang: string) => () => {
    trackMpEvent('language_changed', {
      screen_name: url.getScreenNameFromUrl(window.location.href),
      trigger_source: NAVIGATION_TRIGGER.FOOTER,
      language: lang,
    });
    setLang(lang);
  };

  const languageItems = (
    <ul className="navbar-nav">
      {getLanguages().map((lang, index) => {
        const isActive = lang === getLang();

        return (
          <li
            key={index}
            className={`flex cursor-pointer items-center px-2 py-3 ${isActive ? 'bg-black-100' : ''}`}
            onClick={changeLanguage(lang)}>
            {lang === 'sv' ? <LangSeFlagIcon className="mr-2 h-5 w-5" /> : <LangEnFlagIcon className="mr-2 h-5 w-5" />}
            {<span>{_s(lang)}</span>}
          </li>
        );
      })}
    </ul>
  );

  const toggleShowLang = () => {
    const screenName = isListPage ? 'search_results' : getScreenName(url.getScreenNameFromUrl(window.location.href));
    trackMpEvent('change_language_clicked', {
      screen_name: screenName,
      trigger_source: NAVIGATION_TRIGGER.FOOTER,
    });
    setShowLang((prev) => !prev);
  };

  return (
    <div key="language" className={`relative ${className}`}>
      <DropdownButton
        icon={<Icon variant="globe" />}
        close={() => setShowLang(false)}
        isActive={false}
        toggleOpen={toggleShowLang}
        className="!bg-brown-50">
        {_s(getLang() + 'Short')}
      </DropdownButton>
      {showLang && (
        <Dropdown
          allowClick={showLang}
          className="bottom-[32px] left-auto right-0 top-auto w-[200px] overflow-hidden"
          onClickOutside={() => setShowLang(false)}
          children={languageItems}
        />
      )}
    </div>
  );
};

export const FooterBottom = ({ isListPage }: { isListPage: boolean }) => {
  return !isSistaminuten() ? (
    <>
      {getTosItems('flex md:hidden ')}
      <div className="flex items-center justify-center gap-3 md:hidden">{SocialMediaItems.map((item) => item)}</div>
      <div className="hidden flex-wrap items-center justify-between md:flex">
        {SocialMediaItems.map((item) => item)}
        {PaymentLogosIcons.map((item) => item)}
        {!isServer && <LanguageItem isListPage={isListPage} className="hidden md:block" />}
      </div>
      <div className="flex flex-1 items-center justify-between gap-2">
        <div className="text-m flex-shrink-0">&copy; Bokadirekt {new Date().getFullYear()}</div>
        {!isServer && <LanguageItem isListPage={isListPage} className="block md:hidden" />}
        {getTosItems('hidden md:flex')}
      </div>
    </>
  ) : (
    <>
      <div className="flex flex-1 items-center justify-between">
        <div className="text-m">&copy; Bokadirekt {new Date().getFullYear()}</div>
        {!isServer && <LanguageItem isListPage={isListPage} className="" />}
      </div>
    </>
  );
};
