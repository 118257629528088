import { CHECKOUT_PAYMENT_METHOD, COF_PAYMENT_REDIRECT_LOCALSTORAGE_KEY } from '@/constants/checkout';
import { promiseWrapper } from '@/helpers';
import { getThreeDSecureRedirectedIfAny } from '@/helpers/adyen';
import { newTrackPlaceGiftCardPurchase, trackGiftcardPurchase } from '@/helpers/giftcards';
import { GiftcardFormData } from '@/hooks/useGiftcardCheckoutFormData';
import { adyenServices } from '@/services';
import { createPaymentActionRequiredResponseSchema } from '@/types/api/services/adyen';
import { SelectedPaymentMethod, SelectedStoredCofPaymentMethod, selectedPaymentMethodSchema } from '@/types/checkout';
import * as Sentry from '@sentry/react';
import { Buffer } from 'buffer';
import { Dispatch, useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { z } from 'zod';
import {
  SubmitThreeDSContext as SubmitPrePayThreeDSContext,
  handleSubmitThreeDSPayment as handleSubmitPrePayThreeDSPayment,
} from '../booking/checkout/$bookingId/PrepayCheckout.hooks';
import {
  SubmitThreeDSContext as SubmitBundleThreeDSContext,
  handleSubmitThreeDSPayment as handleSubmitBundleThreeDSPayment,
} from '../bundles/checkout/BundleCheckout.hooks';
import { handleSubmitThreeDSPayment as handleSubmitGiftcardThreeDSPayment } from '../presentkort/GiftcardCheckout.hooks';

const savedCofCheckoutStateSchema = z.object({
  cofThreeDS: createPaymentActionRequiredResponseSchema.nullable().optional(),
  selectedPaymentMethod: selectedPaymentMethodSchema,
  isAddNewCard: z.boolean(),
  payLater: z.boolean(),
});

export type SavedCofCheckoutState = z.infer<typeof savedCofCheckoutStateSchema>;

type PrePayCheckoutSuccessRedirectPath = '/booking/confirmation';
type PrePayCheckoutErrorRedirectPath = `/booking/checkout/${string}`;

type BundleCheckoutSuccessRedirectPath = '/bundles/confirmation';
type BundleCheckoutErrorRedirectPath = `/bundles/checkout/${string}/${string}`;

type GiftcardCheckoutSuccessRedirectPath = `/giftcard/confirmation/${string}`;
type GiftcardCheckoutErrorRedirectPath = `/${'friskvardskort' | 'presentkort'}`;

type PlaceGiftcardCheckoutSuccessRedirectPath = `/places/${string}/placecard/confirmation/${string}`;
type PlaceGiftcardCheckoutErrorRedirectPath = `/places/${string}/giftcard/checkout`;

type ValuecardCheckoutSuccessRedirectPath = `/places/${string}/valuecard/confirmation/${string}`;
type ValuecardCheckoutErrorRedirectPath = `/places/${string}/valuecard/checkout`;

type ThreeDSecureSuccessRedirect = {
  pathname:
    | PrePayCheckoutSuccessRedirectPath
    | BundleCheckoutSuccessRedirectPath
    | GiftcardCheckoutSuccessRedirectPath
    | PlaceGiftcardCheckoutSuccessRedirectPath
    | ValuecardCheckoutSuccessRedirectPath;
  search?: string;
  state: { selectedPaymentMethod?: SelectedPaymentMethod };
};

type ThreeDSecureErrorRedirect = {
  pathname:
    | PrePayCheckoutErrorRedirectPath
    | BundleCheckoutErrorRedirectPath
    | GiftcardCheckoutErrorRedirectPath
    | PlaceGiftcardCheckoutErrorRedirectPath
    | ValuecardCheckoutErrorRedirectPath;
  search?: string;
  state?: { selectedPaymentMethod?: SelectedPaymentMethod; savedCheckoutState?: any };
};

export const getValidateRedirectPathname = (id: number, type: 'booking' | 'bundle', placeId?: number) =>
  `/validate-cof-payment-redirect/${type}/${id}${placeId ? `/${placeId}` : ''}`;

export function saveCofCheckoutStateToLocalStorage(state: SavedCofCheckoutState) {
  localStorage.setItem(COF_PAYMENT_REDIRECT_LOCALSTORAGE_KEY, JSON.stringify(state));
}

export function removeCofCheckoutStateFromLocalStorage() {
  localStorage.removeItem(COF_PAYMENT_REDIRECT_LOCALSTORAGE_KEY);
}

function getSavedCheckoutStateFromLocalStorage(): SavedCofCheckoutState | null {
  const savedCheckoutState = JSON.parse(localStorage.getItem(COF_PAYMENT_REDIRECT_LOCALSTORAGE_KEY));
  savedCheckoutState && localStorage.removeItem(COF_PAYMENT_REDIRECT_LOCALSTORAGE_KEY);

  const validatedPrePayCheckoutState = savedCofCheckoutStateSchema.safeParse(savedCheckoutState);

  if (validatedPrePayCheckoutState.success === false) {
    Sentry.captureException(validatedPrePayCheckoutState.error);
    return null;
  }

  return validatedPrePayCheckoutState.data;
}

async function handleSubmitPrePayRedirectThreeDS({
  context,
  redirectResult,
  checkoutState,
}: {
  context: SubmitPrePayThreeDSContext;
  redirectResult: string;
  checkoutState: SavedCofCheckoutState;
}) {
  const { selectedPaymentMethod, payLater, cofThreeDS } = checkoutState;
  const { adyenPaymentData } = cofThreeDS;

  switch (selectedPaymentMethod.type) {
    case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.STORED_COF: {
      handleSubmitPrePayThreeDSPayment({ ...context })(
        {
          data: { details: { redirectResult, paymentData: adyenPaymentData } },
        },
        payLater,
        selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY,
      );
    }
  }
}

async function handleSubmitBundlePaymentRedirectThreeDS({
  context,
  redirectResult,
  checkoutState,
}: {
  context: SubmitBundleThreeDSContext;
  redirectResult: string;
  checkoutState: SavedCofCheckoutState;
}) {
  const { selectedPaymentMethod, cofThreeDS } = checkoutState;
  const { adyenPaymentData } = cofThreeDS;

  switch (selectedPaymentMethod.type) {
    case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.STORED_COF: {
      handleSubmitBundleThreeDSPayment({ ...context })({
        data: { details: { redirectResult, paymentData: adyenPaymentData } },
      });
    }
  }
}

async function handleAddNewCardRedirectThreeDS(
  redirectResult: string,
  selectedPaymentMethod: SelectedPaymentMethod,
  pathnames: ReturnType<typeof getRedirectPathname>,
  setRedirect: Dispatch<ThreeDSecureSuccessRedirect | ThreeDSecureErrorRedirect | false>,
) {
  const { data, error } = await promiseWrapper(adyenServices.validate3DS({ details: { redirectResult } }));
  const recurringDetailReference = data?.additionalData?.['recurring.recurringDetailReference'];

  if (error || !recurringDetailReference) {
    setRedirect({ pathname: pathnames.failurePathname, search: '?threeDSValidateSuccess=false' });
    return;
  }

  // build the selected payment method with the new stored card to be set as preselected payment method on redirect
  const newSelectedPaymentMethod: SelectedStoredCofPaymentMethod = {
    ...selectedPaymentMethod,
    type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
    id: recurringDetailReference,
    brand: 'unknown',
    lastFour: 'unknown',
  };

  setRedirect({
    pathname: pathnames.failurePathname, // <- this is actually not failing, but user is just storing a new card and then redirecting back to checkout
    search: '?threeDSValidateSuccess=true',
    state: { selectedPaymentMethod: newSelectedPaymentMethod },
  });
}

const getRedirectPathname = (
  type: 'booking' | 'bundle' | 'giftcard' | 'wellnesscard' | 'placecard' | 'valuecard',
  id: number,
  placeId?: number,
): {
  successPathname: ThreeDSecureSuccessRedirect['pathname'];
  failurePathname: ThreeDSecureErrorRedirect['pathname'];
} => {
  switch (type) {
    case 'booking':
      return {
        successPathname: '/booking/confirmation',
        failurePathname: `/booking/checkout/${id}`,
      };
    case 'bundle':
      return {
        successPathname: '/bundles/confirmation',
        failurePathname: `/bundles/checkout/${placeId}/${id}`,
      };
    case 'giftcard':
      return {
        successPathname: `/giftcard/confirmation/${id}`,
        failurePathname: '/presentkort',
      };
    case 'wellnesscard':
      return {
        successPathname: `/giftcard/confirmation/${id}`,
        failurePathname: '/friskvardskort',
      };
    case 'placecard':
      return {
        successPathname: `/places/${placeId}/placecard/confirmation/${id}`,
        failurePathname: `/places/${placeId}/giftcard/checkout`,
      };
    case 'valuecard':
      return {
        successPathname: `/places/${placeId}/valuecard/confirmation/${id}`,
        failurePathname: `/places/${placeId}/valuecard/checkout`,
      };
  }
};

const useValidateCofPaymentRedirect = () => {
  const location = useLocation();
  const [redirectResult] = useState<string | false>(getThreeDSecureRedirectedIfAny(location));
  const [checkoutState] = useState<SavedCofCheckoutState | null>(getSavedCheckoutStateFromLocalStorage());
  const [redirect, setRedirect] = useState<ThreeDSecureSuccessRedirect | ThreeDSecureErrorRedirect | false>(false);
  const routeMatch = useRouteMatch();

  const { id, type, placeId } = routeMatch.params;
  const pathnames = getRedirectPathname(type, id, placeId);

  useEffect(() => {
    const { selectedPaymentMethod: savedCheckoutPaymentMethod, cofThreeDS, isAddNewCard } = checkoutState || {};

    if (!redirectResult || !checkoutState) {
      setRedirect({ pathname: pathnames.failurePathname, search: '?threeDSValidateSuccess=false' });
      return;
    }

    if (isAddNewCard) {
      handleAddNewCardRedirectThreeDS(redirectResult, savedCheckoutPaymentMethod, pathnames, setRedirect);
      return;
    }

    const successCallback = ({ responseData }) => {
      const checkoutStateQuery = new URLSearchParams(location.search).get('data');
      const checkoutState: GiftcardFormData = checkoutStateQuery
        ? JSON.parse(Buffer.from(decodeURIComponent(checkoutStateQuery), 'base64').toString())
        : null;
      const searchParams =
        type === 'booking' ? `?bookingId=${id}&type=cofActivatePayment` : `?bundleId=${responseData?.id ?? id}`;
      const selectedPaymentMethod =
        savedCheckoutPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF
          ? {
              type: savedCheckoutPaymentMethod.type,
              id: savedCheckoutPaymentMethod.id,
              brand: savedCheckoutPaymentMethod.brand,
              lastFour: savedCheckoutPaymentMethod.lastFour,
            }
          : savedCheckoutPaymentMethod;

      if (type === 'wellnesscard' || type === 'giftcard') {
        trackGiftcardPurchase({
          giftcardAmount: checkoutState.amount,
          quantity: checkoutState.quantity,
          isDigital: checkoutState.type === 'digital',
          isWellness: type === 'wellnesscard',
          orderId: responseData.id,
          paymentsId: responseData.pspRef,
        });
      }

      if (type === 'placecard' || type === 'valuecard') {
        newTrackPlaceGiftCardPurchase({
          giftcardAmount: checkoutState.amount,
          quantity: checkoutState.quantity,
          paymentsId: responseData.pspRef,
          placeId: placeId,
          isValueCard: type === 'valuecard',
        });
      }

      setRedirect({
        pathname: pathnames.successPathname,
        search: searchParams,
        state: { ...(selectedPaymentMethod && { selectedPaymentMethod }) },
      });
    };
    const errorCallback = () => {
      const checkoutStateQuery = new URLSearchParams(location.search).get('data');
      const checkoutState = checkoutStateQuery
        ? JSON.parse(Buffer.from(decodeURIComponent(checkoutStateQuery), 'base64').toString())
        : null;
      setRedirect({
        pathname: pathnames.failurePathname,
        search: '?threeDSValidateSuccess=false',
        state: checkoutState ? { savedCheckoutState: checkoutState } : {},
      });
    };

    if (type === 'booking') {
      const context: SubmitPrePayThreeDSContext = {
        bookingId: id,
        cofThreeDS,
        errorCallback,
        successCallback,
      };
      handleSubmitPrePayRedirectThreeDS({ context, redirectResult, checkoutState });
    } else if (type === 'bundle') {
      const context: SubmitBundleThreeDSContext = {
        bundleId: id,
        placeId,
        cofThreeDS,
        errorCallback,
        successCallback,
      };
      handleSubmitBundlePaymentRedirectThreeDS({ context, redirectResult, checkoutState });
    } else if (type === 'wellnesscard' || type === 'giftcard' || type === 'placecard' || type === 'valuecard') {
      handleSubmitGiftcardThreeDSPayment({
        cofThreeDS,
        errorCallback,
        successCallback,
      })({
        data: { details: { redirectResult, paymentData: checkoutState.cofThreeDS.adyenPaymentData } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { redirect };
};

export default useValidateCofPaymentRedirect;
