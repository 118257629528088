import { bookActions } from '@/actions';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { config } from '@/config';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { filterEmptyValues, isAndroidPhone, isIPhone, isSistaminuten, promiseWrapper, url } from '@/helpers';
import { createSession, mapApplePayLineItem } from '@/helpers/applepay';
import {
  buildBookingTrackingProps,
  getApplePaySummaryGiftcardItems,
  getApplePaySummaryServiceItems,
  getBookingCheckoutEmployee,
  getBookingCheckoutFormFields,
  getBookingCheckoutSummary,
  getBookingRequestBody,
  getBookingRetryPathname,
  getBookingStartTime,
  getCheckoutID,
  getGooglePaySummaryGiftcardItems,
  getGooglePaySummaryServiceItems,
  getGroupBookingParticipantSession,
  getUserProfileInfoFields,
  guestCheckoutManager,
  mapBookStateToCheckoutDetailsServices,
  saveBookingTrackingProps,
} from '@/helpers/checkout';
import {
  buildBrowserInfo,
  clientInstance,
  getGoogleIsReadyToPayRequest,
  getGooglePayPaymentDataRequest,
} from '@/helpers/googlepay';
import { useAppSelector } from '@/hooks';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import { CheckoutFormData, bookingCheckoutFormDataSchema, useCheckoutFormData } from '@/hooks/useCheckoutFormData';
import { _s } from '@/locale';
import { adyenServices, swishServices, userService } from '@/services';
import { bookServices } from '@/services/bookServicesTs';
import { CofPaymentData } from '@/types/adyen';
import { CheckoutTracking } from '@/types/analytics';
import { submitAdditionalDetailsResponseSchema } from '@/types/api/services/adyen';
import {
  CofPaymentRequest,
  CofPaymentResponseSuccess,
  CofPaymentResponseThreeDS,
  InitKlarnaResponse,
  InitQliroResponse,
  PaymentHistoryResponse,
  SaveBookingRequest,
  SaveBookingResponse,
  SwishPaymentBookingResponse,
  ValidateGiftCardRequest,
  cofPaymentResponseSuccessSchema,
  cofPaymentResponseThreeDSSchema,
  initKlarnaResponseSchema,
  initQliroResponseSchema,
  saveBookingResponseSchema,
  swishPaymentBookingResponseSchema,
} from '@/types/api/services/booking';
import { ErrorResponse, errorResponseSchema } from '@/types/api/services/schema';
import {
  BookingCheckoutSummary,
  CheckoutAPI,
  CheckoutAction,
  CheckoutEmployee,
  CheckoutMissingAction,
  CheckoutSummary,
  SelectedPaymentMethod,
  availablePrePaymentMethodSchema,
  baseCheckoutStateSchema,
  bookingCheckoutSummarySchema,
  checkoutMissingActionSchema,
  selectedStoredCofPaymentMethodSchema,
} from '@/types/checkout';
import { PaymentCard } from '@/types/paymentcards';
import { BookState } from '@/types/state/book';
import { FormField, formFieldSchema } from '@/types/state/shared';
import * as Sentry from '@sentry/react';
import { Buffer } from 'buffer';
import { Dispatch, ReactNode, createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { baseTranslationKey } from './BookingCheckout';

const swishStateSchema = z.object({
  payment: swishPaymentBookingResponseSchema.nullable(),
  notificationChannel: z.any().optional(),
  showQrCode: z.boolean().optional(),
});

type SwishState = z.infer<typeof swishStateSchema>;

type CofPaymentSuccessRedirectData = {
  paymentMethod:
    | typeof CHECKOUT_PAYMENT_METHOD.STORED_COF
    | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
    | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
  responseData: CofPaymentResponseSuccess;
};

type PayAtPlaceSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE;
  responseData: SaveBookingResponse;
};

export type SwishSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.SWISH;
  responseData: SwishPaymentBookingResponse;
};

type KlarnaSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.KLARNA;
  responseData: InitKlarnaResponse;
};

type QliroSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.QLIRO;
  responseData: InitQliroResponse;
};

export type SuccessRedirectData =
  | PayAtPlaceSuccessRedirectData
  | SwishSuccessRedirectData
  | KlarnaSuccessRedirectData
  | QliroSuccessRedirectData
  | CofPaymentSuccessRedirectData;

export const bookingCheckoutStateSchema = baseCheckoutStateSchema.merge(
  z.object({
    summary: bookingCheckoutSummarySchema,
    formFields: z.array(formFieldSchema),
    formData: bookingCheckoutFormDataSchema,
    swish: swishStateSchema.nullable().optional(),
    cofThreeDS: cofPaymentResponseThreeDSSchema.nullable().optional(),
    termsAccepted: z.boolean(),
    missingActions: z.array(checkoutMissingActionSchema),
    guestId: z.string().optional(),
    protectedEmailError: z.boolean(),
    groupBooking: z.object({
      showModal: z.boolean(),
      participantId: z.string(),
      isUpdate: z.boolean(),
      hasAddedParticipant: z.boolean(),
    }),
  }),
);

export type BookingCheckoutState = z.infer<typeof bookingCheckoutStateSchema>;

type UseBookingCheckoutManagerResult = {
  employee: CheckoutEmployee;
  cofThreeDS?: CofPaymentResponseThreeDS | null;
  bookingCheckoutAPI: () => CheckoutAPI;
  onChangePaymentMethod: (method: SelectedPaymentMethod) => void;
  onApplyGiftcard: (code: string) => (successCallback: () => void) => void;
  onRemoveGiftcard: (code: string) => () => void;
  onToggleTerms: () => void;
  onUseGuestCheckout: () => void;
  onDismissSwishQRCode: () => void;
  onUpdateGroupBooking: ({
    isUpdate,
    showModal,
    participantId,
    hasAddedParticipant,
  }: {
    isUpdate?: boolean;
    showModal?: boolean;
    participantId?: string;
    hasAddedParticipant?: boolean;
  }) => void;
  onCheckoutMissingAction: (action: CheckoutMissingAction) => void;
  onValidateEmailProtected: (email: string, onErrorCallback: () => void) => void;
  onDismissEmailProtectedError: () => void;
} & BookingCheckoutState;

export type SubmitBookingContext = {
  bookingRequestBody: SaveBookingRequest;
  bookingTrackingProps: CheckoutTracking.Booking;
  successCallback: ({ paymentMethod, responseData }: SuccessRedirectData) => void;
  errorCallback: (error: ErrorResponse) => void;
  bookState?: BookState;
};

type SubmitPayAtPlaceBookingContext = SubmitBookingContext & { fullyPaid?: boolean };
type InitQliroBookingContext = SubmitBookingContext;
type InitKlarnaBookingContext = SubmitBookingContext;
type SubmitCofBookingContext = SubmitBookingContext;
type SubmitGooglePayBookingContext = SubmitBookingContext & {
  services: google.payments.api.DisplayItem[];
  giftcards: google.payments.api.DisplayItem[];
};
type SubmitApplePayBookingContext = SubmitBookingContext & {
  services: ApplePayJS.ApplePayLineItem[];
  giftcards: ApplePayJS.ApplePayLineItem[];
};

type SubmitThreeDSContext = SubmitBookingContext & {
  cofThreeDS: CofPaymentResponseThreeDS;
  paymentMethod?:
    | typeof CHECKOUT_PAYMENT_METHOD.STORED_COF
    | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
    | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
};

type CompleteWalletBookingContext = SubmitBookingContext & {
  type: 'googlepay' | 'applepay';
  payLater: boolean;
  paymentData: CofPaymentData;
};

type SubmitSwishBookingContext = SubmitBookingContext & {
  swish?: SwishState;
};

type BookingCheckoutAction =
  | CheckoutAction<BookingCheckoutSummary>
  | {
      type: 'UPDATE_FORM_FIELDS';
      payload: FormField[];
    }
  | {
      type: 'SHOW_ADD_PARTICIPANT_MODAL';
      payload: boolean;
    }
  | {
      type: 'UPDATE_GROUP_BOOKING';
      payload: { showModal?: boolean; isUpdate?: boolean; participantId?: string; hasAddedParticipant?: boolean };
    }
  | {
      type: 'ONHOLD_COF';
      payload: {
        cofThreeDS: CofPaymentResponseThreeDS;
        bookingRequestBody: SaveBookingRequest;
        bookingTrackingProps: CheckoutTracking.Booking;
      };
    }
  | {
      type: 'ONHOLD_SWISH';
      payload:
        | (SwishState & {
            bookingRequestBody: SaveBookingRequest;
            bookingTrackingProps: CheckoutTracking.Booking;
          })
        | null;
    }
  | {
      type: 'ERROR_SWISH';
    }
  | {
      type: 'SET_PROTECTED_EMAIL_ERROR';
      payload: boolean;
    }
  | {
      type: 'CLOSE_SWISH_QRCODE_MODAL';
    }
  | {
      type: 'UPDATE_TERMS_ACCEPTED';
      payload: boolean;
    }
  | {
      type: 'SET_GUEST_ID';
    };

export const CHECKOUT_STATE_LOCALSTORAGE_KEY = 'checkout-state-v2';

export function saveBookingCheckoutStateToLocalStorage(state: BookingCheckoutState) {
  localStorage.setItem(CHECKOUT_STATE_LOCALSTORAGE_KEY, JSON.stringify({ savedCheckoutState: state }));
}

export function removeBookingCheckoutStateFromLocalStorage() {
  localStorage.removeItem(CHECKOUT_STATE_LOCALSTORAGE_KEY);
}

function bookingCheckoutReducer(state: BookingCheckoutState, action: BookingCheckoutAction) {
  switch (action.type) {
    case 'UPDATE_SUMMARY': {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case 'SET_PAYMENT_METHOD':
      return {
        ...state,
        selectedPaymentMethod: action.payload,
        missingActions: state.missingActions.filter((a) => a !== 'payment-method'),
      };
    case 'UPDATE_FORM_FIELDS': {
      return {
        ...state,
        formFields: action.payload,
      };
    }
    case 'SHOW_ADD_PARTICIPANT_MODAL': {
      return {
        ...state,
        submitting: false,
        groupBooking: {
          ...state.groupBooking,
          showModal: action.payload,
        },
      };
    }
    case 'UPDATE_GROUP_BOOKING': {
      const updatedFields = filterEmptyValues(action.payload);

      return {
        ...state,
        groupBooking: {
          ...state.groupBooking,
          ...updatedFields,
        },
        ...(action.payload.hasAddedParticipant
          ? { missingActions: state.missingActions.filter((action) => action !== 'participants') }
          : {}),
      };
    }
    case 'SUCCESS_REDIRECT': {
      if (action.payload !== CHECKOUT_PAYMENT_METHOD.SWISH || !(isIPhone() || isAndroidPhone())) {
        removeBookingCheckoutStateFromLocalStorage();
      }

      return {
        ...state,
        submitting: false,
      };
    }
    case 'SUBMITTING': {
      return {
        ...state,
        submitting: true,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        submitting: false,
        error: action.payload,
        cofThreeDS: null,
      };
    }
    case 'ONHOLD_COF': {
      const updatedState = {
        ...state,
        cofThreeDS: action.payload.cofThreeDS,
      };
      localStorage.setItem(
        CHECKOUT_STATE_LOCALSTORAGE_KEY,
        JSON.stringify({ savedCheckoutState: updatedState, ...action.payload }),
      );
      return {
        ...updatedState,
        submitting: false,
      };
    }
    case 'ONHOLD_SWISH': {
      const { bookingRequestBody, bookingTrackingProps, ...swishState } = action.payload;

      localStorage.setItem(
        CHECKOUT_STATE_LOCALSTORAGE_KEY,
        JSON.stringify({
          savedCheckoutState: state,
          bookingRequestBody,
          bookingTrackingProps,
          payment: swishState.payment,
        }),
      );

      return {
        ...state,
        submitting: false,
        swish: swishState,
      };
    }
    case 'ERROR_SWISH': {
      return {
        ...state,
        submitting: false,
        swish: null,
      };
    }
    case 'CLOSE_SWISH_QRCODE_MODAL': {
      return {
        ...state,
        submitting: false,
        swish: { ...state.swish, showQrCode: false },
      };
    }
    case 'SET_PROTECTED_EMAIL_ERROR': {
      return {
        ...state,
        error: false,
        submitting: false,
        protectedEmailError: action.payload,
      };
    }
    case 'UPDATE_TERMS_ACCEPTED': {
      return {
        ...state,
        termsAccepted: action.payload,
        missingActions: state.missingActions.filter((a) => a !== 'practitioner-terms'),
      };
    }
    case 'SET_MISSING_ACTION': {
      const missingActions = Array.from(new Set([...state.missingActions, action.payload]));
      return {
        ...state,
        missingActions,
      };
    }
    case 'SET_GUEST_ID': {
      return {
        ...state,
        guestId: uuidv4(),
      };
    }

    default:
      const never: never = action;
      throw new Error(`Unhandled action type: ${never}`);
  }
}

async function handleSubmitPayAtPlace(
  context: SubmitPayAtPlaceBookingContext,
  dispatch: Dispatch<BookingCheckoutAction>,
) {
  const { bookingRequestBody, bookingTrackingProps, fullyPaid } = context;
  const paymentMethod = CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE;
  const request = { ...bookingRequestBody, paymentMethod, fullyPaid };

  dispatch({ type: 'SUBMITTING', payload: true });

  const { data, error } = await promiseWrapper(bookServices.saveBooking(request));

  const validateResponse = saveBookingResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  saveBookingTrackingProps(bookingTrackingProps);
  context.successCallback({ paymentMethod, responseData: validateResponse.data });
}

export async function handleSubmitSwish(context: SubmitSwishBookingContext, dispatch: Dispatch<BookingCheckoutAction>) {
  const { bookingRequestBody, bookingTrackingProps, bookState, swish } = context;
  const paymentMethod = CHECKOUT_PAYMENT_METHOD.SWISH;
  const { services, time, employee, campaigns, place } = bookState;

  // Only store service and employee ids to save space in base64 string.
  const serviceIds = services.map((service) => service.id);
  const timeEmployeeIds = time.employees.map((employee) => employee.id);
  const timeWithEmployeeIds = { ...time, employees: timeEmployeeIds };

  const bookStateJsonString = JSON.stringify({
    employee: employee,
    services: serviceIds,
    time: timeWithEmployeeIds,
    campaigns,
    place: {
      id: place.id,
      slug: place.about.slug,
    },
  });
  const bookStateBase64String = Buffer.from(bookStateJsonString).toString('base64');

  dispatch({ type: 'SUBMITTING', payload: true });

  const bookingRequestData = {
    ...bookingRequestBody,
    paymentMethod,
    ...(isIPhone() || isAndroidPhone()
      ? (() => {
          const search = new URLSearchParams({
            data: bookStateBase64String,
          });
          const validateUrl = new URL(`${url.getBaseUrl()}booking/checkout/validate-swish-redirect`);
          search.forEach((value, key) => {
            validateUrl.searchParams.append(key, value);
          });
          return {
            callbackUrl: validateUrl.toString(),
          };
        })()
      : {}),
  };

  const shouldUpdateExistingBooking = Boolean(swish?.payment?.bookingId);

  let createOrderResponse = null;
  let createOrUpdateOrderError = null;

  if (shouldUpdateExistingBooking) {
    const { error: updateError } = await promiseWrapper(
      bookServices.updateSwishOrder({ ...bookingRequestData, bookingId: swish.payment.bookingId }),
    );
    createOrUpdateOrderError = updateError;
  } else {
    const { data, error: createOrderError } = await promiseWrapper(bookServices.createSwishOrder(bookingRequestData));
    createOrUpdateOrderError = createOrderError;
    createOrderResponse = data;
  }

  const validateResponse = swishPaymentBookingResponseSchema.safeParse(
    shouldUpdateExistingBooking ? swish.payment : createOrderResponse,
  );

  if (!validateResponse.success || createOrUpdateOrderError) {
    context.errorCallback(createOrUpdateOrderError);
    return;
  }

  const { bookingId, qrCode } = validateResponse.data;
  const domain = isSistaminuten() ? config.lastMinuteBaseUrl : config.baseUrl;
  const successUrl = `${domain}booking/confirmation?bookingId=${bookingId}&type=swish`;

  if (!qrCode && !isIPhone() && !isAndroidPhone()) {
    context.errorCallback({
      clientError: _s(`${baseTranslationKey}.swishModal.snackbar.bookingMessage`),
      message: 'Qr-code is missing',
      status: 500,
    });
    return;
  }

  const alreadyListeningOnSwishPayment = Boolean(swish?.notificationChannel);
  const eventSource =
    swish?.notificationChannel ?? swishServices.waitForSwishNotification(validateResponse.data.channel);

  dispatch({
    type: 'ONHOLD_SWISH',
    payload: {
      payment: validateResponse.data,
      notificationChannel: eventSource,
      showQrCode: !isIPhone() && !isAndroidPhone(),
      bookingRequestBody: context.bookingRequestBody,
      bookingTrackingProps: context.bookingTrackingProps,
    },
  });

  if (isIPhone() || isAndroidPhone()) {
    window.location.href = validateResponse.data.url;
  }

  if (eventSource && !alreadyListeningOnSwishPayment) {
    eventSource.onmessage = (e) => {
      const swishNotification = JSON.parse(e.data);

      if (swishNotification.success) {
        saveBookingTrackingProps(bookingTrackingProps);
        context.successCallback({ paymentMethod, responseData: { ...validateResponse.data, url: successUrl } });
        eventSource.close();
      } else {
        if (swishNotification.keepAlive) {
          return;
        }

        eventSource.close();
        dispatch({ type: 'ERROR_SWISH' });

        // This means that the swish payment timed out, user most likely
        // switched payment method or have not scanned qrcode
        if (swishNotification.errorReason === 'TM01') {
          return;
        }
        toast(
          ({ closeToast }) => (
            <Snackbar
              type="danger"
              label={
                swishNotification.clientError
                  ? swishNotification.clientError
                  : _s(`${baseTranslationKey}.swishModal.snackbar.bookingMessage`)
              }
              action={
                <button
                  onClick={() => {
                    closeToast();
                  }}>
                  {_s(`${baseTranslationKey}.swishModal.snackbar.cta`)}
                </button>
              }
            />
          ),
          {
            autoClose: 3000,
          },
        );
      }
    };

    eventSource.onerror = () => {
      dispatch({ type: 'ERROR_SWISH' });
      context.errorCallback({ message: 'Could not subscribe to swish notification events', status: 500 });
    };
  }
}

function handleSubmitGuestCoF(context: SubmitCofBookingContext, dispatch: Dispatch<BookingCheckoutAction>) {
  return async (paymentData: CofPaymentData) => {
    const { bookingRequestBody, bookingTrackingProps } = context;
    const paymentMethod = (() => {
      if (paymentData?.paymentMethod?.type === 'googlepay') return CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY;
      if (paymentData?.paymentMethod?.type === 'applepay') return CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
      return CHECKOUT_PAYMENT_METHOD.STORED_COF;
    })();
    const request = { ...bookingRequestBody, paymentMethod, paymentData };

    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(bookServices.cofPayment(request));

    const successData = cofPaymentResponseSuccessSchema.safeParse(data);

    if (successData.success) {
      saveBookingTrackingProps(bookingTrackingProps);
      context.successCallback({ paymentMethod, responseData: successData.data });
      return;
    }
    const threeDSData = cofPaymentResponseThreeDSSchema.safeParse(data);
    if (threeDSData.success) {
      dispatch?.({
        type: 'ONHOLD_COF',
        payload: {
          cofThreeDS: threeDSData.data,
          bookingRequestBody: context.bookingRequestBody,
          bookingTrackingProps: context.bookingTrackingProps,
        },
      });
      return;
    }

    context.errorCallback(error);
  };
}

export function handleSubmitCoF(context: SubmitCofBookingContext, dispatch?: Dispatch<BookingCheckoutAction>) {
  return async (storedPaymentMethodId: string) => {
    const { bookingRequestBody, bookingTrackingProps } = context;
    const paymentMethod = CHECKOUT_PAYMENT_METHOD.STORED_COF;
    const request: CofPaymentRequest = {
      ...bookingRequestBody,
      paymentMethod,
      paymentData: {
        paymentMethod: {
          type: 'storedCard',
          storedPaymentMethodId,
        },
      },
    };

    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(bookServices.cofPayment(request));

    const successData = cofPaymentResponseSuccessSchema.safeParse(data);

    if (successData.success) {
      saveBookingTrackingProps(bookingTrackingProps);
      context.successCallback({ paymentMethod, responseData: successData.data });
      return;
    }
    const threeDSData = cofPaymentResponseThreeDSSchema.safeParse(data);
    if (threeDSData.success) {
      dispatch?.({
        type: 'ONHOLD_COF',
        payload: {
          cofThreeDS: threeDSData.data,
          bookingRequestBody: context.bookingRequestBody,
          bookingTrackingProps: context.bookingTrackingProps,
        },
      });
      return;
    }

    context.errorCallback(error);
  };
}

function handleSubmitGooglePay(context: SubmitGooglePayBookingContext, dispatch: Dispatch<BookingCheckoutAction>) {
  return async (final: number, payLater: boolean) => {
    try {
      const googlePaymentsClient = clientInstance('ecom');
      const isReadyToPay = googlePaymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest('ecom'));

      if (!isReadyToPay) throw new Error('Google pay is not ready to pay');

      const { services, giftcards } = context;

      dispatch({ type: 'SUBMITTING', payload: true });

      const response = await googlePaymentsClient.loadPaymentData(
        getGooglePayPaymentDataRequest(
          {
            totalPrice: payLater ? '0.00' : `${final.toFixed(2)}`,
            totalPriceStatus: payLater ? 'ESTIMATED' : 'FINAL',
            totalPriceLabel: payLater
              ? _s('googlepay.totalPriceLabel.paylater')
              : _s('googlepay.totalPriceLabel.paynow'),
            currencyCode: 'SEK',
            countryCode: 'SE',
            displayItems: [
              ...services,
              ...giftcards,
              ...((): google.payments.api.DisplayItem[] => {
                if (!payLater) return [];
                return [
                  {
                    label: _s('googlepay.totalPriceLabel.paynow'),
                    type: 'SUBTOTAL',
                    price: `${final.toFixed(2)}`,
                    status: 'PENDING',
                  },
                ];
              })(),
            ],
          },
          'ecom',
        ),
      );

      await completeWalletBooking(
        {
          ...context,
          paymentData: {
            paymentMethod: {
              type: 'googlepay',
              googlePayToken: response.paymentMethodData.tokenizationData.token,
              googlePayCardNetwork: response.paymentMethodData.info.cardNetwork,
            },
            browserInfo: buildBrowserInfo(),
            redirectInfo: {
              returnPath: '/booking/checkout/validate-3ds-redirect',
            },
          },
          payLater,
          type: 'googlepay',
        },
        dispatch,
      );
    } catch (error) {
      // Google pay throws "CANCELED statusCode when user closes the payments sheet"
      if (error.statusCode !== 'CANCELED') {
        context.errorCallback(error);
      }
      dispatch({ type: 'ERROR', payload: true });
    }
  };
}

function handleSubmitApplePay(context: SubmitApplePayBookingContext, dispatch: Dispatch<BookingCheckoutAction>) {
  return async (final: number, payLater: boolean) => {
    const { services, giftcards } = context;

    const applePaySession = createSession({
      total: mapApplePayLineItem({ amount: `${final}`, label: 'Bokadirekt', payLater }),
      lineItems: [...services, ...giftcards],
    });

    applePaySession.begin();

    applePaySession.onpaymentauthorized = async (payment) => {
      try {
        await completeWalletBooking(
          {
            ...context,
            paymentData: {
              paymentMethod: {
                type: 'applepay',
                applePayToken: payment.payment.token.paymentData,
              },
              redirectInfo: {
                returnPath: '/booking/checkout/validate-3ds-redirect',
              },
            },
            payLater,
            type: 'applepay',
          },
          dispatch,
          applePaySession,
        );
      } catch (error) {
        context.errorCallback(error);

        applePaySession.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors: [],
        });
        dispatch({ type: 'ERROR', payload: true });
      }
    };
  };
}

async function completeWalletBooking(
  context: CompleteWalletBookingContext,
  dispatch: Dispatch<BookingCheckoutAction>,
  applePaySession?: ApplePaySession,
) {
  const { bookingRequestBody, type, payLater, paymentData, bookingTrackingProps } = context;
  const paymentMethod = type === 'googlepay' ? CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY : CHECKOUT_PAYMENT_METHOD.APPLE_PAY;

  let recurringDetailReference: string;

  if (payLater) {
    const saveCardResponse = await adyenServices.saveUserCard({ paymentData });

    if (saveCardResponse.requiresAction) {
      dispatch({
        type: 'ONHOLD_COF',
        payload: {
          cofThreeDS: saveCardResponse,
          bookingRequestBody: context.bookingRequestBody,
          bookingTrackingProps: context.bookingTrackingProps,
        },
      });
      return;
    }
    recurringDetailReference = saveCardResponse.paymentDetails?.additionalData?.['recurring.recurringDetailReference'];
  }

  const request: CofPaymentRequest = {
    ...bookingRequestBody,
    paymentMethod,
    ...(() => {
      if (recurringDetailReference) {
        return {
          paymentData: {
            paymentMethod: {
              type: 'storedCard',
              storedPaymentMethodId: recurringDetailReference,
            },
          },
        };
      } else {
        return { paymentData };
      }
    })(),
  };

  const { data, error } = await promiseWrapper(bookServices.cofPayment(request));

  const successData = cofPaymentResponseSuccessSchema.safeParse(data);

  if (successData.success) {
    applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_SUCCESS });
    saveBookingTrackingProps(bookingTrackingProps);
    context.successCallback({ paymentMethod, responseData: successData.data });
    return;
  }

  const threeDSData = cofPaymentResponseThreeDSSchema.safeParse(data);

  if (threeDSData.success) {
    dispatch({
      type: 'ONHOLD_COF',
      payload: {
        cofThreeDS: threeDSData.data,
        bookingRequestBody: context.bookingRequestBody,
        bookingTrackingProps: context.bookingTrackingProps,
      },
    });
    return;
  }

  applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_FAILURE });
  context.errorCallback(error);
}

export function handleSubmitThreeDSPayment(context: SubmitThreeDSContext, dispatch?: Dispatch<BookingCheckoutAction>) {
  return async (_state: any, payLater: boolean) => {
    const { bookingId, reference } = context.cofThreeDS || {};

    dispatch?.({ type: 'SUBMITTING', payload: true });

    if (payLater) {
      const { data, error } = await promiseWrapper(adyenServices.validate3DS({ details: _state.data.details }));

      const recurringDetailReference = data?.additionalData?.['recurring.recurringDetailReference'];

      if (error || !recurringDetailReference) {
        context.errorCallback(error);
        return;
      }
      handleSubmitCoF(context)(recurringDetailReference);
      return;
    }

    const { data, error } = await promiseWrapper(
      adyenServices.submitAdditionalDetails({
        bookingId,
        details: _state.data.details,
        reference,
      }),
    );

    const validateResponse = submitAdditionalDetailsResponseSchema.safeParse(data);

    if (!validateResponse.success || error) {
      context.errorCallback(error);
      return;
    }

    saveBookingTrackingProps(context.bookingTrackingProps);

    context.successCallback({
      paymentMethod: CHECKOUT_PAYMENT_METHOD.STORED_COF,
      responseData: validateResponse.data,
    });
  };
}

async function handleInitKlarnaPayment(context: InitKlarnaBookingContext, dispatch: Dispatch<BookingCheckoutAction>) {
  const { bookingRequestBody, bookingTrackingProps } = context;
  const paymentMethod = CHECKOUT_PAYMENT_METHOD.KLARNA;

  dispatch({ type: 'SUBMITTING', payload: true });
  const { data, error } = await promiseWrapper(bookServices.initKlarna({ ...bookingRequestBody, paymentMethod }));

  const validateResponse = initKlarnaResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  saveBookingTrackingProps(bookingTrackingProps);

  context.successCallback({ paymentMethod, responseData: validateResponse.data });
}

async function handleInitQliroPayment(context: InitQliroBookingContext, dispatch: Dispatch<BookingCheckoutAction>) {
  const { bookingRequestBody, bookingTrackingProps } = context;
  const paymentMethod = CHECKOUT_PAYMENT_METHOD.QLIRO;

  dispatch({ type: 'SUBMITTING', payload: true });

  const { data, error } = await promiseWrapper(bookServices.initQliro({ ...bookingRequestBody, paymentMethod }));

  const validateResponse = initQliroResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  saveBookingTrackingProps(bookingTrackingProps);

  context.successCallback({ paymentMethod, responseData: validateResponse.data });
}

/**
 * Currently if user has some fields prefilled in his profile we remove them from form fields
 * since we instead want to show them in EditUserInfo modal component.
 * (this logic is handled by giving "state.formData" to the "UpdateUserInfo" Component)
 *
 * If user does not have these fields prefilled they will instead not be filtered from form fields
 * and be shown in the form.
 */
function handleRemovePrefilledUserProfileFields(
  userProfileInfoFields: { id: FormField['id']; value: string }[],
  formFields: FormField[],
  dispatch: Dispatch<BookingCheckoutAction>,
) {
  const filterExistingProfileFields = formFields.filter(
    (field) => !userProfileInfoFields.find((v) => v.id === field.id),
  );

  dispatch({ type: 'UPDATE_FORM_FIELDS', payload: filterExistingProfileFields });
}

const useBookingCheckoutManager = (
  booking: BookState,
  cards: PaymentCard[],
  formData: CheckoutFormData,
  paymentHistory: PaymentHistoryResponse,
): UseBookingCheckoutManagerResult => {
  const user = useAppSelector((state) => state.users.user);
  const appDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const validSavedCheckoutState = bookingCheckoutStateSchema.safeParse(location.state?.savedCheckoutState);
  const selectedCapacity = booking.time?.selectedCapacity;

  const getExperimentVariant = useGetAmplitudeExperimentVariant();

  // experiment with radio buttons
  const usePromotedPaymentMethods = (() => {
    if (isSistaminuten()) return false;

    return getExperimentVariant('promoted-payment-methods-web')?.value === 'variant-options-visible';
  })();

  const employee = getBookingCheckoutEmployee(location.state, booking);
  const formFields = useMemo(
    () =>
      getBookingCheckoutFormFields({
        employee: employee.actual,
        place: booking.place,
        services: booking.services,
        /**
         * We force require email if we are on sistaminuten since online payment is required and we need to
         * make sure we have an email to send reciept
         */
        forceRequireEmail: isSistaminuten(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const checkoutId = getCheckoutID(booking, employee.actual);

  const emailRequired = (() => {
    if (Boolean(user?.contact?.email)) return true;

    return formFields.some((field) => {
      if (field.id !== 'email') return false;
      return field.required;
    });
  })();

  const guestId = (() => {
    if (!user && (isSistaminuten() || guestCheckoutManager().getOptions()?.useGuestCheckout)) {
      return uuidv4();
    }
    return undefined;
  })();

  const isGuestCheckout = Boolean(guestId);

  const summary = getBookingCheckoutSummary({
    booking,
    employee,
    cards,
    emailRequired,
    isGuestCheckout,
    paymentHistory: usePromotedPaymentMethods ? paymentHistory : undefined,
    forceRequireOnlinePayment: isSistaminuten(),
  });
  const { savedCheckoutState, savedSelectedPaymentMethod } = (() => {
    if (!validSavedCheckoutState.success) {
      return { savedCheckoutState: null, savedSelectedPaymentMethod: null };
    }

    const { selectedPaymentMethod, ...savedCheckoutState } = validSavedCheckoutState.data;

    return {
      savedCheckoutState,
      savedSelectedPaymentMethod: selectedPaymentMethod,
    };
  })();

  const preSelectedPaymentMethod = ((): CheckoutSummary['preSelectedPaymentMethod'] => {
    const tryGetSavedSelectedPaymentMethod = (() => {
      if (!savedSelectedPaymentMethod) return undefined;

      const validateIsCoF = selectedStoredCofPaymentMethodSchema.safeParse(savedSelectedPaymentMethod);

      if (validateIsCoF.success) {
        const card = cards.find((card) => card.id === validateIsCoF.data.id);
        if (!card) return undefined;

        const { total, totalLabel, final } = validateIsCoF.data;

        return {
          type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
          brand: card.brand,
          id: card.id,
          lastFour: card.lastFour,
          total,
          final,
          totalLabel,
        };
      } else {
        const validateIsAvailablePreSelectedMethod =
          availablePrePaymentMethodSchema.safeParse(savedSelectedPaymentMethod);

        if (validateIsAvailablePreSelectedMethod.success) {
          return validateIsAvailablePreSelectedMethod.data;
        }
      }

      return undefined;
    })();

    if (tryGetSavedSelectedPaymentMethod) return tryGetSavedSelectedPaymentMethod;

    return summary.preSelectedPaymentMethod;
  })();

  const selectedPaymentMethod =
    preSelectedPaymentMethod ||
    summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.NONE);

  const [state, dispatch] = useReducer(
    bookingCheckoutReducer,
    savedCheckoutState
      ? { ...savedCheckoutState, selectedPaymentMethod, submitting: false, cofThreeDS: null, error: false }
      : {
          selectedPaymentMethod,
          formData,
          summary,
          formFields,
          submitting: false,
          error: false,
          protectedEmailError: false,
          cofThreeDS: null,
          termsAccepted: !Boolean(employee.terms),
          missingActions: [],
          swish: null,
          guestId,
          groupBooking: {
            showModal: false,
            isUpdate: false,
            participantId: '1',
            hasAddedParticipant: false,
          },
        },
  );

  /**
   * close connection to swish notification channel on unmount
   */
  useEffect(() => {
    return () => state.swish?.notificationChannel?.close?.();
  }, [state.swish?.notificationChannel]);

  useEffect(() => {
    appDispatch(bookActions.addUuid(checkoutId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutId]);

  useEffect(() => {
    if (!user) return;
    const savedParticipants = getGroupBookingParticipantSession(booking, String(employee.actual.id));
    const userProfileInfoFields = getUserProfileInfoFields(user);

    if (savedParticipants) {
      handleUpdateGroupBooking({ hasAddedParticipant: true });
    }

    handleRemovePrefilledUserProfileFields(userProfileInfoFields, formFields, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    dispatch({ type: 'ERROR_SWISH' });
    if (typeof booking.successGiftCard === 'boolean' && !booking.successGiftCard) return;
    dispatch({
      type: 'UPDATE_SUMMARY',
      payload: getBookingCheckoutSummary({
        booking,
        employee,
        cards,
        selectedCapacity,
        emailRequired,
        isGuestCheckout,
        paymentHistory: usePromotedPaymentMethods ? paymentHistory : undefined,
        forceRequireOnlinePayment: isSistaminuten(),
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking?.usageReqId?.giftcards, selectedCapacity]);

  useEffect(() => {
    /**
     * We have to set payment method to pay at place if we can't pay online, for example if we
     * apply a giftcard that covers the whole amount
     */
    if (!summary.canPayOnline)
      dispatch({ type: 'SET_PAYMENT_METHOD', payload: { type: CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE } });
  }, [summary.canPayOnline]);

  const handleUpdateGroupBooking = ({
    showModal,
    isUpdate,
    participantId,
    hasAddedParticipant,
  }: {
    showModal?: boolean;
    isUpdate?: boolean;
    participantId?: string;
    hasAddedParticipant?: boolean;
  }) => {
    dispatch({ type: 'UPDATE_GROUP_BOOKING', payload: { isUpdate, participantId, showModal, hasAddedParticipant } });
  };

  const handleChangePaymentMethod = (method: SelectedPaymentMethod) => {
    dispatch({
      type: 'SET_PAYMENT_METHOD',
      payload:
        method.type === CHECKOUT_PAYMENT_METHOD.NONE
          ? summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.NONE)
          : method,
    });
  };

  const handleApplyGiftcard = (code: string) => (successCallback: () => void) => {
    const { place, usageReqId, services, time } = booking;
    const { finalWithoutGiftcards } = state.summary;

    const giftcardRequestBody: ValidateGiftCardRequest = {
      code,
      place: place.id,
      usageRequestId: usageReqId.uuid,
      personId: employee.selectedEmployeeId,
      startTime: getBookingStartTime(time),
      serviceIds: services?.map((service) => service.id),
      formId: 0,
      amount: finalWithoutGiftcards * 100,
    };

    dispatch({ type: 'ERROR_SWISH' });
    // @ts-ignore
    appDispatch(bookActions.addGiftCard(giftcardRequestBody, successCallback));
  };

  const handleRemoveGiftcard = (code: string) => () => {
    const { usageReqId } = booking;
    const { summary } = state;

    const giftcardCoveredBookingAmount = !summary.canPayOnline;

    // @ts-ignore
    appDispatch(bookActions.removeGiftCard({ usageRequestId: usageReqId.uuid, code: code }));
    dispatch({
      type: 'UPDATE_SUMMARY',
      payload: getBookingCheckoutSummary({
        booking,
        employee,
        cards,
        selectedCapacity,
        emailRequired,
        isGuestCheckout,
        paymentHistory: usePromotedPaymentMethods ? paymentHistory : undefined,
        forceRequireOnlinePayment: isSistaminuten(),
      }),
    });

    dispatch({ type: 'ERROR_SWISH' });

    /**
     * if the giftcard covered the total amount of the booking and is removed
     * we unselect the checkout payment method
     */
    if (giftcardCoveredBookingAmount) {
      dispatch({ type: 'SET_PAYMENT_METHOD', payload: { type: CHECKOUT_PAYMENT_METHOD.NONE } });
    }
  };

  const handleDismissSwishQRCode = () => {
    dispatch({ type: 'CLOSE_SWISH_QRCODE_MODAL' });
  };

  const handleToggleTerms = () => {
    dispatch({ type: 'UPDATE_TERMS_ACCEPTED', payload: !state.termsAccepted });
  };

  const handleOnCheckoutMissingAction = (action: CheckoutMissingAction) => {
    dispatch({ type: 'SET_MISSING_ACTION', payload: action });
  };

  const handleUseGuestCheckout = () => {
    guestCheckoutManager().setOptions({ useGuestCheckout: true });

    dispatch({
      type: 'UPDATE_SUMMARY',
      payload: getBookingCheckoutSummary({
        booking,
        employee,
        cards,
        selectedCapacity,
        emailRequired,
        isGuestCheckout: true,
        paymentHistory: usePromotedPaymentMethods ? paymentHistory : undefined,
        forceRequireOnlinePayment: isSistaminuten(),
      }),
    });
    dispatch({ type: 'SET_GUEST_ID' });
  };

  const handleDismissEmailProtectedError = () => {
    dispatch({ type: 'SET_PROTECTED_EMAIL_ERROR', payload: false });
  };

  const handleOnValidateEmailProtected = async (email: string, onErrorCallback: () => void) => {
    const response = await promiseWrapper(userService.isEmailProtected(email));

    if (response?.error?.status === 401) {
      dispatch({ type: 'SET_PROTECTED_EMAIL_ERROR', payload: true });
      onErrorCallback();
      return;
    }
  };

  const handleSuccessRedirect = ({ paymentMethod, responseData }: SuccessRedirectData) => {
    dispatch({ type: 'SUCCESS_REDIRECT', payload: paymentMethod });
    switch (paymentMethod) {
      case CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE: {
        history.push({ pathname: `/booking/confirmation`, search: `?b=${responseData.bookingCode}`, state: {} });
        break;
      }
      case CHECKOUT_PAYMENT_METHOD.SWISH: {
        window.location.href = responseData.url;
        break;
      }
      case CHECKOUT_PAYMENT_METHOD.KLARNA: {
        history.push({
          pathname: '/booking/checkout/klarna',
          state: { klarnaOrder: responseData, savedCheckoutState: { ...state, formData }, employee: employee.actual },
        });
        break;
      }
      case CHECKOUT_PAYMENT_METHOD.QLIRO: {
        history.push({
          pathname: '/booking/checkout/qliro',
          state: { qliroOrder: responseData, savedCheckoutState: { ...state, formData }, employee: employee.actual },
        });
        break;
      }
      case CHECKOUT_PAYMENT_METHOD.STORED_COF:
      case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
      case CHECKOUT_PAYMENT_METHOD.APPLE_PAY: {
        const selectedPaymentMethod = (() => {
          if (state.selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
            return {
              type: state.selectedPaymentMethod.type,
              id: state.selectedPaymentMethod.id,
              brand: state.selectedPaymentMethod.brand,
              lastFour: state.selectedPaymentMethod.lastFour,
            };
          }

          if (state.selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY) {
            return { type: CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY };
          }

          if (state.selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.APPLE_PAY) {
            return { type: CHECKOUT_PAYMENT_METHOD.APPLE_PAY };
          }

          return undefined;
        })();

        history.push({
          pathname: `/booking/confirmation`,
          search: `?bookingId=${responseData.id}&type=cof`,
          state: { ...(selectedPaymentMethod && { selectedPaymentMethod }) },
        });
        break;
      }
      default:
        Sentry.captureMessage('User did not get redirected correctly');
        break;
    }
  };

  const handleError = (error: unknown) => {
    const errorData = errorResponseSchema.safeParse(error);
    dispatch({ type: 'ERROR', payload: true });

    const displayClientError = (errorMessage?: string) => {
      toast(
        ({ closeToast }) => (
          <Snackbar
            label={errorMessage ?? _s('serverError')}
            type="danger"
            onClose={() => {
              dispatch({ type: 'ERROR', payload: false });
              closeToast();
            }}
          />
        ),
        {
          autoClose: 5000,
        },
      );
    };

    if (!errorData.success) {
      displayClientError();
      Sentry.captureException(error);
      return;
    }

    const { status, clientError } = errorData.data;
    /**
     * if we get 409 conflict error the booking time is no longer available and we need to redirect the user to
     * calendar page where the location state of retry is set to true to show a Alert message saying the time is taken
     */
    if (status === 409) {
      history.push({ pathname: getBookingRetryPathname(booking), state: { retry: true } });
      return;
    }

    if (status === 401 && !isSistaminuten()) {
      /**
       * if we get 401 unauthorized error the user has tried to book with a protected email
       * save checkout state if user proceeds to login to save the current state and complete the booking
       * since user login otherwise will loose the current state
       */
      history.replace('/booking/checkout', { ...location?.state, savedCheckoutState: state });
      dispatch({ type: 'SET_PROTECTED_EMAIL_ERROR', payload: true });
      return;
    }

    displayClientError(clientError);
    Sentry.captureException(error);
  };

  const bookingCheckoutAPI = () => {
    const bookingRequestBody = getBookingRequestBody({
      booking,
      employee,
      formfields: formData,
      guestId: state.guestId,
      summary,
    });
    const successCallback = handleSuccessRedirect;
    const errorCallback = handleError;
    const bookingTrackingProps = buildBookingTrackingProps({
      booking,
      employee,
      summary,
      selectedPaymentMethod: state.selectedPaymentMethod,
      formFields,
      selectedCapacity,
    });

    const applePaySummary = {
      services: getApplePaySummaryServiceItems(
        mapBookStateToCheckoutDetailsServices(booking, employee.shown),
        summary.payLater,
      ),
      giftcards: getApplePaySummaryGiftcardItems(booking, summary.payLater),
    };

    const googlePaySummary = {
      services: getGooglePaySummaryServiceItems(
        mapBookStateToCheckoutDetailsServices(booking, employee.shown),
        summary.payLater,
      ),
      giftcards: getGooglePaySummaryGiftcardItems(booking, summary.payLater),
    };

    const context = { bookingRequestBody, bookingTrackingProps, successCallback, errorCallback };

    return {
      submitPayAtPlace: () =>
        handleSubmitPayAtPlace(
          {
            ...context,
            fullyPaid: summary.finalPrice === 0,
          },
          dispatch,
        ),
      submitSwish: (bookState: BookState) => handleSubmitSwish({ ...context, bookState, swish: state.swish }, dispatch),
      submitCoF: handleSubmitCoF(context, dispatch),
      submitGuestCoF: handleSubmitGuestCoF(context, dispatch),
      submitGooglePay: handleSubmitGooglePay({ ...context, ...googlePaySummary }, dispatch),
      submitApplePay: handleSubmitApplePay({ ...context, ...applePaySummary }, dispatch),
      submitThreeDS: handleSubmitThreeDSPayment({ ...context, cofThreeDS: state.cofThreeDS }, dispatch),
      initKlarna: () => handleInitKlarnaPayment(context, dispatch),
      initQliro: () => handleInitQliroPayment(context, dispatch),
    };
  };

  return {
    summary: state.summary,
    selectedPaymentMethod: state.selectedPaymentMethod,
    employee: employee,
    formFields: state.formFields,
    termsAccepted: state.termsAccepted,
    submitting: state.submitting,
    error: state.error,
    protectedEmailError: state.protectedEmailError,
    swish: state.swish,
    cofThreeDS: state.cofThreeDS,
    missingActions: state.missingActions,
    groupBooking: state.groupBooking,
    guestId: state.guestId,
    onUpdateGroupBooking: handleUpdateGroupBooking,
    onDismissSwishQRCode: handleDismissSwishQRCode,
    onChangePaymentMethod: handleChangePaymentMethod,
    onApplyGiftcard: handleApplyGiftcard,
    onRemoveGiftcard: handleRemoveGiftcard,
    onToggleTerms: handleToggleTerms,
    onCheckoutMissingAction: handleOnCheckoutMissingAction,
    onUseGuestCheckout: handleUseGuestCheckout,
    onValidateEmailProtected: handleOnValidateEmailProtected,
    onDismissEmailProtectedError: handleDismissEmailProtectedError,
    bookingCheckoutAPI,
  };
};

const CheckoutContext = createContext<UseBookingCheckoutManagerResult>({
  employee: null,
  formFields: [],
  selectedPaymentMethod: { type: 0 },
  summary: null,
  submitting: false,
  error: undefined,
  protectedEmailError: false,
  termsAccepted: false,
  missingActions: [],
  groupBooking: null,
  bookingCheckoutAPI: () => ({
    submitPayAtPlace: async () => {},
    submitSwish: async () => {},
    submitCoF: async () => {},
    submitGuestCoF: async () => {},
    submitGooglePay: async () => {},
    submitApplePay: async () => {},
    submitThreeDS: async () => {},
    initKlarna: async () => {},
    initQliro: async () => {},
  }),
  onApplyGiftcard: () => () => {},
  onRemoveGiftcard: () => () => {},
  onChangePaymentMethod: () => {},
  onToggleTerms: () => {},
  onDismissSwishQRCode: () => {},
  onCheckoutMissingAction: () => {},
  onUpdateGroupBooking: () => {},
  onUseGuestCheckout: () => {},
  onValidateEmailProtected: () => {},
  onDismissEmailProtectedError: () => {},
});

export const useBookingCheckout = () => {
  const context = useContext(CheckoutContext);

  if (!context) {
    throw new Error('useCheckout must be used within a CheckoutProvider');
  }

  return context;
};

export const BookingCheckoutProvider = ({
  children,
  cards = [],
  paymentHistory,
}: {
  children: ReactNode;
  cards?: PaymentCard[];
  paymentHistory: PaymentHistoryResponse;
}) => {
  const booking = useAppSelector((state) => state.book);
  const { formData } = useCheckoutFormData();
  return (
    <CheckoutContext.Provider value={useBookingCheckoutManager(booking, cards, formData, paymentHistory)}>
      {children}
    </CheckoutContext.Provider>
  );
};
