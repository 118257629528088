import { LoginSuccess } from '@/components/animations';
import { LinkButton } from '@/components/elements/forms/buttons';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import EmptyState from '@/components/templates/EmptyState';
import CheckoutDetails from '@/components/templates/giftCards/CheckoutDetails/CheckoutDetails';
import { trackMpEvent, trackPage } from '@/helpers';
import { mapKlarnaOrderToGiftcardOrder, trackKlarnaGiftcardPurchase } from '@/helpers/giftcards';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { ProductType } from '@/pages/presentkort/GiftcardCheckout.types';
import { giftCardServices } from '@/services';
import { GiftcardService } from '@/services/giftcardServicesNew';
import { Order } from '@/types/api/services/giftcard';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const baseTranslationsKey = 'pages.giftcard.confirmation';

const getScreenName = (productType: Order['productType']) => {
  switch (productType) {
    case 1:
      return 'gift_card_confirmation';
    case 2:
      return 'wellness_card_confirmation';
    case 3:
      return 'place_gift_card_confirmation';
    case 4:
      return 'value_card_confirmation';
    default:
      const _exhaustiveCheck: never = productType;
      throw new Error(`Unhandled product type: ${_exhaustiveCheck}`);
  }
};

const getProductType = (productType: Order['productType']): ProductType => {
  switch (productType) {
    case 1:
      return 'giftcard';
    case 2:
      return 'wellnesscard';
    case 3:
      return 'placecard';
    case 4:
      return 'valuecard';
    default:
      const _exhaustiveCheck: never = productType;
      throw new Error(`Unhandled product type: ${_exhaustiveCheck}`);
  }
};

const GiftcardConfirmation = () => {
  const { isMobileView } = useMobileView();
  const location = useLocation();
  const [order, setOrder] = useState<Order>(null);
  const selectedPaymentMethod = location.state?.selectedPaymentMethod;
  const guestEmail = location.state?.guestEmail;
  const isGuest = Boolean(guestEmail);
  const queryParams = new URLSearchParams(location.search);
  const klarnaID = queryParams.get('klarna_order_id');
  const sessionID = queryParams.get('session_id');
  const isKlarna = Boolean(klarnaID || sessionID);
  const [response, loading, error] = isKlarna
    ? giftCardServices.useConfirmGiftCardOrder(klarnaID, sessionID)
    : [null, false, null];
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isKlarna) return;
    GiftcardService.getGiftcardOrder(id)
      .then((response) => {
        setOrder(response);
        setIsLoading(false);
        trackPage();
        trackMpEvent('screen_shown', {
          screen_name: getScreenName(response.productType),
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        toast(({ closeToast }) => <Snackbar label={_s('serverError')} type="danger" onClose={closeToast} />, {
          autoClose: 5000,
        });
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (!response || response.error || !isKlarna) return;
    setOrder(mapKlarnaOrderToGiftcardOrder(response));

    if (!response.alreadyCreated) {
      trackKlarnaGiftcardPurchase(response, klarnaID, sessionID);
    }
  }, [response, klarnaID]);

  if ((isLoading && !isKlarna) || (loading && isKlarna))
    return <LoadingPlaceHolder text={_s(`${baseTranslationsKey}.loading`)} />;

  if (error && isKlarna) {
    return <Redirect to="/" />;
  }

  return (
    <PageViewLayout
      type="subView"
      wrapperClass={`min-h-screen ${isMobileView ? 'bg-brown-50' : 'bg-gradient'}`}
      rightSlot={
        <Link to={'/'}>
          <Icon variant="close-alt" alt={_s('close')} size="sm" />
        </Link>
      }>
      {order && (
        <div className="bg-brown-50 flex justify-center lg:bg-transparent">
          <div className="lg:py-xxl w-full lg:container">
            <div className="gap-xxl flex justify-center">
              <div className={`gap-lg flex grow-[9999] flex-col lg:max-w-[600px]`}>
                <CardWrapper className="w-full">
                  <EmptyState
                    icon={<LoginSuccess style={{ width: 196, height: 154 }} />}
                    title={_s(`${baseTranslationsKey}.title`)}
                    {...(order.email && {
                      body: _s(`${baseTranslationsKey}.body`, { email: isGuest ? guestEmail : order.email }),
                    })}
                  />
                </CardWrapper>
                <div className="lg:hidden">
                  <CardWrapper className="w-full bg-white">
                    <CheckoutDetails
                      discount={order.discount}
                      deliveryType={order.deliveryType}
                      productType={getProductType(order.productType)}
                      selectedAmount={parseFloat(order.price)}
                      selectedQuantity={order.quantity}
                      paymentMethod={selectedPaymentMethod ?? { type: order.paymentMethod }}
                    />
                  </CardWrapper>
                </div>
                <CardWrapper className="w-full bg-white">
                  <div className="p-lg gap-lg flex flex-col">
                    <LinkButton to={'/'} block variant="primary" label={_s(`done`)} size="md" />
                  </div>
                </CardWrapper>
              </div>
              <div className="sticky hidden w-full lg:flex lg:max-w-[390px] lg:grow-[100] lg:flex-col">
                <CardWrapper className="w-full bg-white">
                  <CheckoutDetails
                    discount={order.discount}
                    deliveryType={order.deliveryType}
                    productType={getProductType(order.productType)}
                    selectedAmount={parseFloat(order.price)}
                    selectedQuantity={order.quantity}
                    paymentMethod={selectedPaymentMethod ?? { type: order.paymentMethod }}
                  />
                </CardWrapper>
              </div>
            </div>
          </div>
        </div>
      )}
      <Helmet>
        <title>{`Bekräftelse av ${order?.productType === 1 ? 'presentkorts' : 'friskvårdskorts'}köp`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
    </PageViewLayout>
  );
};

export default GiftcardConfirmation;
