import { config } from '@/config';
import { isServer, isSistaminuten } from '@/helpers';
import { _s } from '@/locale';
import { DEALS_FEATURE_FLAG } from '@/pages/deals/Deals';
import { NavItem, NavItemIdentifier } from '@/types/navigation';
import { APP_ROUTES } from './pages';

const tBase = (key: string) => _s(`headerNavigation.${key}`);

const navSearchItem: NavItem = {
  id: 'nav-item-search',
  icon: 'search',
  label: tBase('search'),
  to: '/search',
  identifier: NavItemIdentifier.Search,
};

const navBookinsItem: NavItem = {
  id: 'nav-item-bookings',
  icon: 'calendar-empty',
  label: tBase('bookings'),
  to: '/bokningar',
  identifier: NavItemIdentifier.Bookings,
};

const navDealsItem: NavItem = {
  id: 'nav-item-deals',
  icon: 'deal',
  label: 'Deals',
  to: '/deals',
  identifier: NavItemIdentifier.Deals,
};

const navFavoritesItem: NavItem = {
  id: 'nav-item-favorites',
  icon: 'heart',
  label: tBase('favorites'),
  to: '/favoriter',
  identifier: NavItemIdentifier.Favorites,
};

const navCancelBookingItem: NavItem = {
  icon: 'calendar-cross',
  label: tBase('cancel'),
  to: '/cancel',
  identifier: NavItemIdentifier.Cancel,
};

const navConnectCompanyItem: NavItem = {
  icon: 'company',
  label: tBase('connectCompany'),
  to: 'https://business.bokadirekt.se/',
  identifier: NavItemIdentifier.ConnectCompany,
  breakpoint: 'xl:inline-flex',
};

const navManageCompanyItem: NavItem = {
  icon: 'company',
  label: tBase('manageCompany'),
  to: config.merchantUrl,
  identifier: NavItemIdentifier.CompanyLogin,
  breakpoint: 'xl:inline-flex',
};

const navLoginItem: NavItem = {
  icon: 'account',
  label: tBase('login'),
  identifier: NavItemIdentifier.LogIn,
};

const navHamburgerItem: NavItem = {
  id: 'nav-item-menu',
  icon: 'menu',
  identifier: NavItemIdentifier.Hamburger,
};

const navBarMainNavigationBokadirektItems = (
  isMerchant: boolean,
  whitelabel: boolean,
  dealsFeature: boolean,
): NavItem[] => {
  const navWellnessBuyItem: NavItem = {
    icon: 'wellness-card',
    label: tBase('buyWellnessCard'),
    to: APP_ROUTES.WELLNESSCARD_CHECKOUT,
    identifier: NavItemIdentifier.WellnessCardBuy,
    breakpoint: 'xl:inline-flex',
  };

  const navGiftCardBuyItem: NavItem = {
    icon: 'gift-card',
    label: tBase('buyGiftCard'),
    to: APP_ROUTES.GIFTCARD_CHECKOUT,
    identifier: NavItemIdentifier.GiftCardBuy,
  };

  return [
    navSearchItem,
    navBookinsItem,
    ...(dealsFeature ? [navDealsItem] : []),
    navFavoritesItem,
    ...(!whitelabel ? ([navWellnessBuyItem, navGiftCardBuyItem] as NavItem[]) : []),
    ...(!whitelabel ? ([...(isMerchant ? [navManageCompanyItem] : [navConnectCompanyItem])] as NavItem[]) : []),
  ].filter(Boolean);
};

const navBarMainNavigationSistaminutenItems: NavItem[] = [
  navSearchItem,
  navCancelBookingItem,
  navConnectCompanyItem,
  navHamburgerItem,
];

export const navBarMainNavigationItems = (user, flags): NavItem[] => {
  const whitelabel = !isServer ? sessionStorage.getItem('whitelabel') === 'true' : false;
  const isMerchant = user?.about?.isMerchant;
  const dealsFeature = flags[DEALS_FEATURE_FLAG]?.value === 'on';

  let items: NavItem[] = isSistaminuten()
    ? [...navBarMainNavigationSistaminutenItems]
    : [
        ...navBarMainNavigationBokadirektItems(isMerchant, whitelabel, dealsFeature),
        ...(user ? [navHamburgerItem] : [navLoginItem, navHamburgerItem]),
      ];

  return items.filter(Boolean);
};
