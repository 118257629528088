import { APP_ROUTES } from '@/constants/pages';
import { ProductType } from './GiftcardCheckout.types';

export function getGiftcardCheckoutProductType(
  params: { slugId: string; type: string },
  pathname: string,
): ProductType | undefined {
  if (!params.slugId && !params.type) {
    if (pathname.startsWith(APP_ROUTES.WELLNESSCARD_CHECKOUT)) {
      return 'wellnesscard';
    }

    return 'giftcard';
  }

  switch (params.type) {
    case 'valuecard':
      return 'valuecard';
    case 'giftcard':
      return 'placecard';
  }
}
