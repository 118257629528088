import { deployActions, loadingActions } from '@/actions';
import Card from '@/components/elements/Card/Card';
import PlaceItemCard from '@/components/elements/Card/PlaceItemCard/PlaceItemCard';
import { Fab } from '@/components/elements/forms/buttons';
import { LinkButton } from '@/components/elements/forms/buttons/Button/Button';
import { ArrowIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { NewCustomerDiscountBanner } from '@/components/modules/banner';
import BannerSlot from '@/components/modules/banner/BannerSlot';
import Breadcrumbs from '@/components/modules/breadcrumbs/Breadcrumbs';
import TopTabBar from '@/components/modules/navigation/TopTabBar/TopTabBar';
import {
  About,
  AppBanner,
  BookAppointment,
  FavoritesButton,
  GiftCards,
  MatchedServices,
  Photos,
  Reviews,
  SalonInfo,
  Sidebar,
  Staff,
  WelcomeText,
  InstagramFeed,
} from '@/components/modules/salon';
import Certificates from '@/components/modules/salon/Certificates';
import { ReviewsHeader } from '@/components/modules/salon/Reviews';
import { config } from '@/config';
import { BANNER_SLOT_LOCATION } from '@/constants/banner';
import { SMART_LINKS } from '@/constants/smartLinkConstants';
import {
  classnames,
  exportIdSlug,
  getBookingStartingPoint,
  getCompanyType,
  getDetailsBreadcrumbs,
  getPlaceImage,
  hasFeatureSetting,
  isAndroidPhone,
  isIPhone,
  isMobile,
  isServer,
  isSistaminuten,
  limitString,
  salonTracking,
  server,
  setBookingStartingPoint,
  shouldDisplayReviews,
  trackMpEvent,
  trackMpMetaEvent,
  trackPage,
  trackPageCio,
  updateDeployTimestamp,
  url,
} from '@/helpers';
import { getBreadcrumbsSchema, getLocalBusinessSchema } from '@/helpers/jsonld';
import { isPlaceOpenToday } from '@/helpers/placeHelper';
import withMobileView from '@/hoc/withMobileView';
import { __, getLang, setLang } from '@/locale';
import { NotFound } from '@/pages/_exports';
import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const OTHER_LOCATIONS_COUNT = 3;

class SalonLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.height = 0;
    this.renderMobile = this.renderMobile.bind(this);
    this.queryParams = new URLSearchParams(this.props.location.search);
    this.whitelabelQuery = this.queryParams.get('whitelabel') === 'true';
    this.whitelabel = this.whitelabelQuery || (!isServer ? sessionStorage.getItem('whitelabel') === 'true' : false);
    if (!isServer && this.whitelabelQuery) {
      sessionStorage.setItem('whitelabel', this.whitelabelQuery);
    }
    this.isMpReviews = !['1', 'true'].includes(this.queryParams.get('service-reviews'));

    this.topTabBarRef = React.createRef();

    this.state = {
      place: props.place || {},
      searchedPlaces: [],
      loading: false,
      is404: Boolean(this.props?.is404),
      groupName: '',
      expanded: false,
      truncated: false,
      closedBanner: false,
      // searchKeyword: null,
      matchedServices: [],
      searchParams: {},
      navigationSubMenuTab: 0,
    };
  }

  seo() {
    const { place } = this.state;
    if (!place) return null;
    const serviceNames =
      place.services && place.services.length
        ? [].concat.apply(
            [],
            place.services.map((category) => (category.services ? category.services.map((s) => s.name) : [])),
          )
        : [];
    const uniqueServiceNames = serviceNames.filter((item, pos) => item !== false && serviceNames.indexOf(item) === pos);
    const title = limitString(
      place.about.name +
        (place.contact?.address?.city ? ', ' + place.contact.address.city : '') +
        (place.contact?.address?.district ? ', ' + place.contact.address.district : '') +
        ' - Bokadirekt',
      60,
    );
    const description = limitString(place.about.description, 155);
    const keywords = place.about.name + ', ' + place.contact.address.city + ', ' + uniqueServiceNames.join(', ');
    const placeUrl = url.getBaseUrl() + 'places/' + place.about.slug + '-' + place.id;
    const image =
      place.about && place.about.profileImage && place.about.profileImage ? (
        <meta property="og:image" content={place.about.profileImage} />
      ) : null;
    const images =
      place.about && place.about.images && place.about.images.length
        ? place.about.images.map((imageUrl, i) => <meta key={i} property="og:image" content={imageUrl} />)
        : null;
    const streetAddress = (place.contact.address.street || '') + ', ' + (place.contact.address.city || '');
    const isNoIndex =
      hasFeatureSetting(place, 'seo_noindex') || hasFeatureSetting(place, 'seo_noindex_portal') || isSistaminuten();
    const klarnaMerchantClientId = this.getMerchantKlarnaClientId();

    const breadcrumbs = getDetailsBreadcrumbs(place).map((item) => ({
      ...item,
      url: `${url.getBaseUrl()}${(item.url ?? '').slice(1)}`,
    }));

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={placeUrl} />
        <meta property="og:url" content={placeUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Bokadirekt" />
        <meta property="og:locale" content="sv_SE" />
        {image}
        {images}
        <meta name="og:type" content="business.business" />
        <meta name="business:contact_data:street_address" content={streetAddress} />
        <meta name="business:contact_data:postal_code" content={place.contact.address.zipcode || ''} />
        <meta name="business:contact_data:locality" content={place.contact.address.city || ''} />
        <meta name="business:contact_data:country_name" content="Sverige" />
        <meta name="place:location:latitude" content={place.contact.position.lat || ''} />
        <meta name="place:location:longitude" content={place.contact.position.lon || ''} />
        {place.contact.phone && <meta name="business:contact_data:phone_number" content={place.contact.phone || ''} />}
        {!isSistaminuten() && <meta name="twitter:card" content="summary_large_image" />}
        {!isSistaminuten() && <meta name="twitter:site" content="@bokadirekt" />}
        <meta name="robots" content={isNoIndex ? 'noindex, follow' : 'index, follow'} />
        {klarnaMerchantClientId && (
          <script
            async
            data-environment={config.env === 'production' ? 'production' : 'playground'}
            src="https://js.klarna.com/web-sdk/v1/klarna.js"
            data-client-id={klarnaMerchantClientId}></script>
        )}
        <script type="application/ld+json">{getLocalBusinessSchema(place)}</script>
        <script type="application/ld+json">{getBreadcrumbsSchema(breadcrumbs)}</script>
      </Helmet>
    );
  }

  componentDidMount() {
    const { id, slug } = exportIdSlug(this.props.match.params.slugId);

    const searchParams = new URLSearchParams(this.props.location?.search);

    if (searchParams.has('lang')) {
      const lang = searchParams.get('lang');
      setLang(lang);
    }

    sessionStorage.setItem('bookSPurl', `/places/${slug}-${id}`);
    sessionStorage.setItem('paymentSPurl', `/places/${slug}-${id}`);

    if (!this.props.place || parseInt(this.props.place.id) !== parseInt(id)) {
      this.getPlace(id, slug);
    } else {
      if (!this.whitelabel) {
        this.trackPage(this.props.place);
      }
      this.getFirstAvailableTimeServices();
      if (!isServer) {
        this.setPlaceVisit(this.props.place);
      }
    }
    if (!isServer) {
      window.scrollTo(0, 0);
      document.querySelector('html').setAttribute('class', 'scroll-smooth');
    }

    if (this.props.deploy) {
      updateDeployTimestamp(this.props.deploy);
      this.props.dispatch(deployActions.clearDeployTimestamp());
    }

    if (!isServer) {
      // keep track of scroll position
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const { showSubHeader } = this.state;
    const { height } = this.topTabBarRef?.current?.getBoundingClientRect() || {};
    const scrollY = window.scrollY;
    if (scrollY > 160 + height && !showSubHeader) {
      this.setState({ showSubHeader: true });
    }
    if ((scrollY < 160 + height) & showSubHeader) {
      this.setState({ showSubHeader: false });
    }
  };

  componentWillUnmount() {
    if (!isServer) {
      document.querySelector('html').removeAttribute('class', 'scroll-smooth');
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  trackPage(place) {
    trackPage();

    trackMpEvent(
      'screen_shown',
      {
        screen_name: 'company_details',
        company_id: place.id,
        company_type: getCompanyType(place),
      },
      false,
    );

    trackPageCio('company_details', {
      merchant_id: place.id,
      merchant_name: place.about.name,
    });

    trackMpMetaEvent('ViewContent', {
      content_ids: [place.id],
      content_name: place.about.name,
      contents: [{ id: place.id, content_name: place.about.name }],
    });

    salonTracking(place);

    if (!getBookingStartingPoint()) {
      setBookingStartingPoint('company_details');
    }
  }

  getPlace(id, slug = '') {
    const { dispatch } = this.props;

    const isMpReviews = this.isMpReviews;
    dispatch(loadingActions.show());
    server.request
      .get('/findPlace/' + id + '/' + encodeURIComponent(slug), {
        params: { slug: encodeURIComponent(slug), 'mp-reviews': isMpReviews },
      })
      .then((response) => {
        const { place, redirect, locations, selectedGroup } = response.data;

        if (redirect && !isServer) {
          return (window.location = redirect);
        }

        if (!isServer) {
          // Don't have reviews - fetch again to check if was miss in cache
          if (place.reviews === null) {
            server.request
              .get('/places/getPlaceReviews/' + place.id, {
                params: { 'mp-reviews': isMpReviews },
              })
              .then(({ data }) => {
                if (data.reviews) {
                  place.reviews = data.reviews;
                }

                this.setState({ place });
              })
              .catch(() => {});
          }

          this.setPlaceVisit(place);
        }

        // add locations and group name to place
        place.locations = locations;
        place.groupName = selectedGroup;

        this.setState(
          {
            is404: false,
            place: place,
            loading: false,
          },
          () => {
            this.getFirstAvailableTimeServices();
          },
        );

        if (!this.whitelabel) {
          this.trackPage(place);
        }

        dispatch(loadingActions.hide());
        if (response.data.redirect) {
          this.props.history.replace(response.data.redirect);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          this.setState({ is404: true });
        }

        this.setState({ loading: false });
        dispatch(loadingActions.hide());
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match &&
      prevProps.match.params &&
      prevProps.match.params.slugId &&
      prevProps.match.params.slugId !== this.props.match.params.slugId
    ) {
      const { id, slug } = exportIdSlug(this.props.match.params.slugId);
      this.getPlace(id, slug);
      if (!isServer) {
        window.scrollTo(0, 0);
      }
    }

    if (this.props.deploy) {
      updateDeployTimestamp(this.props.deploy);
      this.props.dispatch(deployActions.clearDeployTimestamp());
    }
  }

  getNavigationSubMenu() {
    const { place } = this.state;
    const hideStaff = place.schedule || (place.about.settings && place.about.settings.hideEmployees);
    return [
      {
        label: __('services'),
        onClick: this.handleClick('book-salon-button-clicked', 'book'),
        to: '#book',
      },
      !hideStaff && {
        label: __('staff'),
        onClick: this.handleClick('staff-salon-button-clicked', 'staff'),
        to: '#staff',
      },
      shouldDisplayReviews(place) && {
        label: __('Ratings'),
        onClick: this.handleClick('reviews-salon-button-clicked', 'reviews'),
        to: '#reviews',
      },
      {
        label: __('about'),
        onClick: this.handleClick('about-salon-button-clicked', 'about'),
        to: '#about',
      },
    ].filter((a) => a);
  }

  handleClick = (name, id) => (e) => {
    e.preventDefault();

    const target = document ? document.getElementById(id) : false;
    if (target) {
      target.scrollIntoView(true);
      // now account for fixed header
      const scrolledY = window.scrollY;
      if (scrolledY) {
        if (isMobile()) {
          window.scroll(0, scrolledY - 120);
        } else {
          if (id !== 'photos') {
            window.scroll(0, scrolledY - 118);
          } else {
            window.scroll(0, scrolledY);
          }
        }
      }
    }
    return false;
  };

  showFavoriteModal = (value, callback) => {
    this.setState({ showFavoriteModal: value || false }, () => {
      if (callback) {
        callback();
      }
    });
  };

  getImages(place) {
    const { about = {} } = place;
    const { profileImage, images = [] } = about;

    const galleryImages = [...images];
    const shouldAddProfileImage = isMobile() && profileImage && profileImage !== images?.[0];
    if (shouldAddProfileImage) galleryImages.unshift(profileImage);

    if (!galleryImages.length) return null;

    const showGallery = !!this.props?.location?.state?.showGallery;
    const photos =
      about.settings?.subscriptionType !== 'essential'
        ? galleryImages
        : galleryImages.slice(0, shouldAddProfileImage ? 4 : 3);
    return <Photos photos={photos} place={place} showGallery={showGallery} />;
  }

  goBack = (e) => {
    e.preventDefault();
    if (this.props.location.state?.fromList) {
      this.props.history.goBack();
    } else {
      this.props.history.replace('/vad/var');
    }
  };

  handleBannerClick = () => {
    const { place } = this.state;

    if (!this.whitelabel) {
      trackMpEvent('go_to_appstore_clicked', {
        screen_name: 'company_details',
        company_id: place.id,
        company_type: getCompanyType(place),
      });
    }
  };

  setPlaceVisit(place) {
    try {
      const mpPlaceVisits = JSON.parse(localStorage.getItem('mpPlaceVisits')) || {};
      const placeVisits = mpPlaceVisits[place.id] || [];
      const totalVisits = placeVisits.length;
      const unix = moment().unix();

      if (totalVisits === 5) {
        placeVisits.shift();
      }

      placeVisits.push(unix);

      mpPlaceVisits[place.id] = placeVisits;
      localStorage.setItem('mpPlaceVisits', JSON.stringify(mpPlaceVisits));

      localStorage.setItem(
        'mpLastVisited',
        JSON.stringify({
          id: place.id,
          about: place.about,
          contact: place.contact,
          rating: (place.reviews && place.reviews.stats) || null,
        }),
      );
    } catch (e) {}
  }

  hideBanner = () => {
    this.setState({ closedBanner: true });
  };

  getAppBanner() {
    try {
      const { place, closedBanner } = this.state;
      if (!isServer && (isIPhone() || isAndroidPhone()) && !closedBanner && place && place.id) {
        const mpPlaceVisits = JSON.parse(localStorage.getItem('mpPlaceVisits')) || {};
        const placeVisits = mpPlaceVisits[place.id] || [];

        if (placeVisits.length < 3) {
          const placeUrl = encodeURIComponent(`https://www.bokadirekt.se/places/${place.id}#add-to-favorites`);
          const url = SMART_LINKS.getPlaceFavouritesSmartLink(placeUrl);

          return (
            <AppBanner
              url={url}
              salonName={place.about.name}
              handleBannerClick={this.handleBannerClick}
              hideBanner={this.hideBanner}
            />
          );
        }
      }
    } catch (e) {}
  }

  shouldHaveFirstAvailableTimes = () =>
    this.props.location.state &&
    this.props.location.state.fromList &&
    this.props.location.state?.searchParams?.q &&
    !(this.state.place && this.state.place.schedule);

  async getFirstAvailableTimeServices() {
    if (this.shouldHaveFirstAvailableTimes()) {
      const searchParams = Object.fromEntries(
        Object.entries(this.props.location.state.searchParams).filter((param) => param),
      );
      const placeId = this.state.place.id;
      this.setState({ searchParams });
      const response = await server.request
        .get('/search', { params: { ...searchParams, placeId: placeId } })
        .then(server.handleSuccess)
        .catch(server.handleError);
      const matchedServices = response && response.places && response.places[0] && response.places[0].matchedServices;
      this.setState({ matchedServices });
    } else {
      return null;
    }
  }

  getDateTimeLabel = () => {
    const { q, startDate, endDate, timeOfDay, prefs } = this.props.location.state?.searchParams;

    // let dateLabel = __('anyDate');
    let label = q;

    if (prefs && prefs.indexOf('9') !== -1) {
      label += ' - friskvårdskort';
    }
    if (startDate && endDate) {
      const startDateObject = moment.utc(startDate);
      const endDateObject = moment.utc(endDate);
      const startDateMonthLabel = __(startDateObject.format('MMM'));
      const endDateMonthLabel = __(endDateObject.format('MMM'));
      const isSameMonth = startDateMonthLabel === endDateMonthLabel;
      const startDateLabel = `${startDateObject.format('D')}${isSameMonth ? '' : ` ${startDateMonthLabel}`}`;
      const endDateLabel = `${endDateObject.format('D')} ${endDateMonthLabel}, ${endDateObject.format('YYYY')}`;
      // dateLabel = `${startDateLabel} - ${endDateLabel}`;

      label += `, ${startDateLabel} - ${endDateLabel}`;

      if (timeOfDay && timeOfDay !== 'any') {
        const timeOfDayLabel = __(`timeOfDay_${timeOfDay}`);
        label += `, ${timeOfDayLabel}`;
      }
    }

    return label;
  };

  getMerchantKlarnaClientId() {
    const { place } = this.state;
    const { employees } = place;
    return employees?.find((employee) => !!employee.about.settings?.klarnaClientId)?.about?.settings?.klarnaClientId;
  }

  placeData() {
    const { place } = this.state;
    const images = this.getImages(place);
    const breadcrumbs = getDetailsBreadcrumbs(place);
    const hideStaff = place.schedule || (place.about.settings && place.about.settings.hideEmployees);

    const klarnaMerchantClientId = this.getMerchantKlarnaClientId();

    if (!(place && place.id)) {
      return (
        <div className="container relative py-6">
          <span className="font-semibold">{__('noPlaceFound')}</span>
        </div>
      );
    }

    const showGoBack =
      !(this.props.location.state && this.props.location.state.fromList) && place.source === 2 ? false : true;
    const haveReviews = Boolean(
      place.reviews && place.reviews.stats && place.reviews.stats.count && place.reviews.stats.count > 4,
    );

    if (isMobile()) {
      return (
        <div className="place-data">
          <div className="relative">
            <div>
              {false && showGoBack && (
                <div className="absolute left-4 top-6 z-10">
                  <Fab
                    icon={<ArrowIcon className="h-4 w-4 rotate-180 text-white" />}
                    onClick={this.goBack}
                    size="sm"
                    variant="secondary"
                    className="!hover:bg-[rgba(0,0,0,0.5)] border-none !bg-[rgba(0,0,0,0.5)] !ring-0"
                  />
                </div>
              )}
              {!isSistaminuten() && (
                <div className="absolute right-4 top-6 z-10">
                  <FavoritesButton
                    overlay
                    showModal={this.state.showFavoriteModal}
                    showFavoriteModal={this.showFavoriteModal}
                    place={place}
                  />
                </div>
              )}
            </div>
            {images}
            <div className="shadow-xs sm:hidden" ref={this.topTabBarRef}>
              <TopTabBar items={this.getNavigationSubMenu()} defaultSelected={this.state.navigationSubMenuTab} />
            </div>
            <div className="px-3">
              <SalonInfo place={place} noGiftcard={isSistaminuten()} />
              {klarnaMerchantClientId && (
                <klarna-placement
                  id="klarna-placement"
                  data-key="top-strip-promotion-auto-size"
                  data-locale={getLang() === 'sv' ? 'sv-SE' : 'en-SE'}></klarna-placement>
              )}
            </div>
            {/* {!isSistaminuten() && this.getAppBanner()} */}
            {this.hasDiscount()}
            {this.shouldHaveFirstAvailableTimes() && (
              <div className="px-3 pt-6">
                <MatchedServices
                  place={place}
                  matchedServices={this.state.matchedServices}
                  searchKeyword={this.getDateTimeLabel()}
                />
              </div>
            )}
            <section className="relative pt-6">
              <span className="absolute right-4 top-8 text-white">{place?.about?.salonId}</span>
              <BookAppointment place={place} />
            </section>
            {!hideStaff && (
              <section className="px-3 pt-6">
                <Staff
                  employees={place.employees}
                  place={place}
                  dispatch={this.props.dispatch}
                  isMpReviews={this.isMpReviews}
                />
              </section>
            )}
            {shouldDisplayReviews(place) && haveReviews && (
              <section className="px-3 pt-6">
                <Reviews place={place} hideStaff={hideStaff} isMpReviews={this.isMpReviews} />
              </section>
            )}
            {shouldDisplayReviews(place) && !haveReviews && (
              <section id="reviews" className="relative px-3 pt-6">
                <ReviewsHeader place={place} />
                <div className="flex items-center">
                  <Icon variant="star-filled" color="warning-500" style={{ width: 72, height: 72 }} />
                  <div className="pl-2">
                    <span className="text-black-600 block">{__('noReviews')}</span>
                  </div>
                </div>
              </section>
            )}
            <section className="px-3 pt-6">
              <About description={place.about.description} />
            </section>
            {place?.about?.settings?.subscriptionType === 'growth' && place?.contact?.instagram && place?.about?.instagramFeed && (
              <div className="py-6">
                <InstagramFeed profile={place.contact.instagram} media={place.about.instagramFeed} />
              </div>
            )}
            {place.about.settings.displayCertificates && (
              <section className="px-3 pt-6">
                <Certificates employees={place.employees} />
              </section>
            )}
            <div className="px-3 pt-6">
              <Sidebar place={place} />
            </div>
          </div>
        </div>
      );
    } else {
      const locations = this.getLocations();
      return (
        <div className="container mx-auto py-6">
          <Breadcrumbs items={breadcrumbs} />
          <div className="flex flex-col gap-16 md:flex-row">
            <div className="w-full md:w-7/12">
              <div className="mb-6">
                <SalonInfo place={place} />

                {this.hasDiscount()}
                {place.about && place.about.welcomeText && (
                  <div className="border-black-100 hidden border-y-[1px] py-4 md:block">
                    <WelcomeText text={place.about.welcomeText} />
                  </div>
                )}
                {klarnaMerchantClientId && (
                  <klarna-placement
                    id="klarna-placement"
                    data-key="top-strip-promotion-auto-size"
                    data-locale={getLang() === 'sv' ? 'sv-SE' : 'en-SE'}></klarna-placement>
                )}
              </div>
              {!isSistaminuten() && <GiftCards place={place} />}
              {!isSistaminuten() && this.shouldHaveFirstAvailableTimes() && (
                <div className="py-6">
                  <MatchedServices
                    place={place}
                    matchedServices={this.state.matchedServices}
                    searchKeyword={this.getDateTimeLabel()}
                  />
                </div>
              )}
              <section className="relative py-6">
                <span className="absolute right-4 top-8 text-white">{place?.about?.salonId}</span>
                <BookAppointment place={place} />
              </section>
              {!hideStaff && (
                <section className="py-6">
                  <Staff
                    employees={place.employees}
                    place={place}
                    dispatch={this.props.dispatch}
                    isMpReviews={this.isMpReviews}
                  />
                </section>
              )}
              {shouldDisplayReviews(place) && haveReviews && (
                <section className="py-6">
                  <Reviews place={place} hideStaff={hideStaff} isMpReviews={this.isMpReviews} />
                </section>
              )}
              {shouldDisplayReviews(place) && !haveReviews && (
                <section id="reviews" className="relative py-6">
                  <ReviewsHeader place={place} />
                  <div className="flex items-center">
                    <Icon variant="star-filled" color="warning-500" style={{ width: 72, height: 72 }} />
                    <div className="pl-2">
                      <span className="text-black-600 block">{__('noReviews')}</span>
                    </div>
                  </div>
                </section>
              )}
              <section className="py-6">
                <About description={place.about.description} />
              </section>
              {place.about.settings.displayCertificates && (
                <section className="py-6">
                  <Certificates employees={place.employees} />
                </section>
              )}
              <div className="py-6">{images}</div>
              {place?.about?.settings?.subscriptionType === 'growth' && place?.contact?.instagram && place?.about?.instagramFeed && (
                <div className="py-6">
                  <InstagramFeed profile={place.contact.instagram} media={place.about.instagramFeed} />
                </div>
              )}
              {locations}
            </div>
            <div className="relative hidden md:block md:w-5/12 md:max-w-[275px]">
              <div className="md:sticky md:top-32">
                <Sidebar place={place} showPlaceLogo />
              </div>
            </div>
            <div className="relative md:hidden">
              <Sidebar place={place} />
            </div>
          </div>
        </div>
      );
    }
  }

  getLocations() {
    const { place } = this.state;
    const { locations, about } = place;
    let locationCount = 0;
    if (locations && !isSistaminuten() && about?.salonId) {
      const titleClass = `${isMobile() ? 'px-6 ' : ''} font-semibold mb-lg text-2xl`;
      const containerClass = isMobile() ? 'list-container' : '';
      return (
        <div>
          <h2 className={titleClass}> {__('otherLocations')}</h2>
          <div className={`${containerClass} m-0 flex flex-col gap-6`}>
            {locations.map((location) => {
              if (location.id !== place.id && OTHER_LOCATIONS_COUNT > locationCount) {
                locationCount++;
                const imagePreview = getPlaceImage(location);
                return (
                  <div key={location.id}>
                    <PlaceItemCard
                      src={`${location?.about?.slug}-${location?.id}`}
                      businessHours={isPlaceOpenToday(location)}
                      address={{
                        city: location.contact?.address?.city,
                        postal: location.contact?.address?.zipcode,
                        street: location.contact?.address?.street,
                      }}
                      rating={location?.rating?.score}
                      ratingCount={location?.rating?.count}
                      title={location.about?.name}
                      image={{
                        src: imagePreview,
                      }}
                    />
                  </div>
                );
              }
              return null;
            })}
            <div className="flex justify-start">
              <LinkButton
                variant="link"
                label={__('placePage.showAllAddresses')}
                size="lg"
                to={`/goto/${about.salonId}`}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  hasDiscount() {
    const { place } = this.state;
    const offer = !isServer && sessionStorage.getItem('ClickedOffer');

    if (offer && place && parseInt(place.id) === parseInt(offer)) {
      return (
        <div className="my-4">
          <NewCustomerDiscountBanner onRemove={this.removeDiscount} variant="extended" />
        </div>
      );
    }
    return null;
  }

  removeDiscount = (e) => {
    if (!isServer) {
      sessionStorage.removeItem('ClickedOffer');
    }
    this.setState({ render: !this.state.render });
  };

  getParams() {
    const placeContainer = this.placeData();
    return { placeContainer };
  }

  renderMobile(params) {
    const { placeContainer } = params;

    const locations = this.getLocations();
    return (
      <>
        <div className="details">
          {placeContainer}
          <div className="py-6">{locations}</div>
        </div>
        {this.seo()}
      </>
    );
  }

  toggleBreadCrumbs = () => {
    this.setState({ showBreadCrumbs: !this.state.showBreadCrumbs });
  };

  handleSubNavigationTabClick = (activeTab) => {
    this.setState({ navigationSubMenuTab: activeTab });
  };

  render() {
    if (this.state.is404) return <NotFound staticContext={this.props.staticContext} />;
    const { id } = exportIdSlug(this.props.match.params.slugId);
    if (parseInt(this.state.place.id) !== parseInt(id)) {
      return null;
    }
    const { place, loading, showBreadCrumbs } = this.state;
    if (loading || !place || !place.id) {
      return null;
    }
    const breadcrumbs = getDetailsBreadcrumbs(place);
    const params = this.getParams();

    const navType = this.props.isMobileView ? (this.state.showSubHeader ? 'subView' : 'mainView') : 'mainView';

    const { placeContainer } = params;
    if (!isServer) {
      document.body.classList.add('landing');
    }

    const desktopContent = (
      <>
        {placeContainer}
        {this.seo()}
      </>
    );

    return (
      <PageViewLayout
        back
        hideBottomNavBar
        title={place.about.name}
        {...(navType === 'subView' && {
          title: place.about.name,
          titleAction: this.toggleBreadCrumbs,
          floatingSlot: (
            <div className={classnames('mt-md container', showBreadCrumbs ? 'block' : 'hidden')}>
              <Card>
                <Breadcrumbs items={breadcrumbs} className="w-full text-sm" />
              </Card>
            </div>
          ),
          ...(this.state.showSubHeader && {
            headerSlot: (
              <div className="border-black-100 border-t">
                <div className="mx-auto max-w-screen-sm">
                  <TopTabBar
                    onTabClick={this.handleSubNavigationTabClick}
                    items={this.getNavigationSubMenu()}
                    defaultSelected={this.state.navigationSubMenuTab}
                  />
                </div>
              </div>
            ),
          }),
        })}
        {...(!this.props.isMobileView && {
          headerSlot: (
            <div className="border-black-100 border-t">
              <div className="mx-auto max-w-screen-sm">
                <TopTabBar
                  onTabClick={this.handleSubNavigationTabClick}
                  items={this.getNavigationSubMenu()}
                  defaultSelected={this.state.navigationSubMenuTab}
                />
              </div>
            </div>
          ),
        })}
        type={navType}
        {...(!isSistaminuten() &&
          !this.whitelabel && {
            prepend: (
              <BannerSlot
                config={this.props.flags?.[BANNER_SLOT_LOCATION.ABOVE_ALL_BANNER_PLACE_SCREEN]?.payload}
                locationId={BANNER_SLOT_LOCATION.ABOVE_ALL_BANNER_PLACE_SCREEN}
              />
            ),
          })}
        onBackButtonClick={this.goBack}>
        {isMobile() ? this.renderMobile(params) : desktopContent}
      </PageViewLayout>
    );
  }
}

function mapStateToProps(state) {
  const { place, deploy, flags, loading } = state;
  const { is404 } = loading;

  return { place, deploy, flags, is404 };
}

const SalonLanding = withRouter(withMobileView(connect(mapStateToProps)(SalonLandingPage)));
export default SalonLanding;
