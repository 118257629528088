import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { CHECKOUT_PAYMENT_METHOD, COF_PAYMENT_REDIRECT_LOCALSTORAGE_KEY } from '@/constants/checkout';
import { exportIdSlug, isEmpty, promiseWrapper } from '@/helpers';
import { createSession, mapApplePayLineItem } from '@/helpers/applepay';
import {
  getGiftcardCheckoutSummary,
  getGiftcardRequestData,
  getRedirectPreselectedPaymentMethod,
  guestCheckoutManager,
} from '@/helpers/checkout';
import { newTrackPlaceGiftCardPurchase, trackGiftcardPurchase } from '@/helpers/giftcards';
import {
  buildBrowserInfo,
  clientInstance,
  getGoogleIsReadyToPayRequest,
  getGooglePayPaymentDataRequest,
} from '@/helpers/googlepay';
import { useAppSelector } from '@/hooks';
import { useCardsContext } from '@/hooks/adyen/useCards';
import { GiftcardFormData, useGiftcardCheckoutFormData } from '@/hooks/useGiftcardCheckoutFormData';
import { usePlaceGiftCardData, useValueCardData } from '@/hooks/usePlaceData';
import useWalletPayment from '@/hooks/useWalletPayment';
import { _s } from '@/locale';
import { giftCardServices } from '@/services';
import { GiftcardService } from '@/services/giftcardServicesNew';
import { initiateOrUpdatePGCPayment } from '@/services/pgcPaymentServices';
import { CofPaymentData } from '@/types/adyen';
import {
  CreatePaymentAcceptedResponse as AdyenCreatePaymentAccepted,
  CreatePaymentActionRequiredResponse,
  createPaymentAcceptedResponseSchema,
  createPaymentActionRequiredResponseSchema,
  createPaymentRefusedResponseSchema,
} from '@/types/api/services/adyen';
import { cofPaymentResponseThreeDSSchema } from '@/types/api/services/booking';
import { Employee, KlarnaResponse, klarnaResponseSchema } from '@/types/api/services/giftcard';
import { ErrorResponse, errorResponseSchema } from '@/types/api/services/schema';
import {
  CheckoutAPI,
  CheckoutAction,
  CheckoutMissingAction,
  CheckoutSummary,
  SavedCofPaymentData,
  SelectedPaymentMethod,
  baseCheckoutStateSchema,
  giftcardCheckoutSummarySchema,
  selectedPaymentMethodSchema,
} from '@/types/checkout';
import * as Sentry from '@sentry/react';
import { Buffer } from 'buffer';
import { Dispatch, Reducer, createContext, useContext, useEffect, useReducer } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { getGiftcardCheckoutProductType } from './GiftcardCheckout.helpers';
import { ProductType } from './GiftcardCheckout.types';

export function saveCofCheckoutState(state: SavedCofPaymentData) {
  localStorage.setItem(COF_PAYMENT_REDIRECT_LOCALSTORAGE_KEY, JSON.stringify(state));
}

const getRedirectUrl = (productType: ProductType, placeId: string) => {
  switch (productType) {
    case 'giftcard':
      return '/validate-cof-payment-redirect/giftcard/<placeholder>';
    case 'wellnesscard':
      return '/validate-cof-payment-redirect/wellnesscard/<placeholder>';
    case 'placecard':
      return `/validate-cof-payment-redirect/placecard/<placeholder>/${placeId}`;
    case 'valuecard':
      return `/validate-cof-payment-redirect/valuecard/<placeholder>/${placeId}`;
  }
};

export const PHYSICAL_FEE = 45;

export const giftcardCheckoutStateSchema = baseCheckoutStateSchema.merge(
  z.object({
    summary: giftcardCheckoutSummarySchema,
    cofThreeDS: cofPaymentResponseThreeDSSchema.nullable().optional(),
    loading: z.boolean().optional(),
    employee: z.string().optional(),
    guestId: z.string().optional(),
    giftcardOrderId: z.number().optional(),
    klarna: klarnaResponseSchema.optional(),
  }),
);

type SubmitKlarnaGiftcardPaymentContext = {
  errorCallback: (error: ErrorResponse) => void;
  formData: GiftcardFormData;
  productType: ProductType;
  user: any;
  klarna: KlarnaResponse;
};

type SubmitKlarnaPlacePaymentContext = {
  errorCallback: (error: ErrorResponse) => void;
  formData: GiftcardFormData;
  user: any;
  klarna: KlarnaResponse;
  placeId: number;
  slugId: string;
  slug: string;
  employees: any;
};

type SubmitGiftcardPaymentContext = {
  successCallback: ({ paymentMethod, responseData }: SuccessRedirectData) => void;
  errorCallback: (error: ErrorResponse) => void;
  formData: GiftcardFormData;
  productType: ProductType;
  employee?: Employee;
  slugId?: string;
};

type SubmitCofGiftcardPaymentContext = SubmitGiftcardPaymentContext & {
  selectedPaymentMethod: SelectedPaymentMethod;
  guestId?: string;
};

export type SubmitThreeDSContext = Omit<
  SubmitCofGiftcardPaymentContext,
  'formData' | 'productType' | 'selectedPaymentMethod' | 'employees'
> &
  Partial<Pick<SubmitCofGiftcardPaymentContext, 'selectedPaymentMethod'>> & {
    cofThreeDS: CreatePaymentActionRequiredResponse;
    paymentMethod?:
      | typeof CHECKOUT_PAYMENT_METHOD.STORED_COF
      | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
      | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
    giftcardOrderId?: number;
  };

type CompleteWalletPaymentContext = SubmitCofGiftcardPaymentContext & {
  type: 'googlepay' | 'applepay';
  paymentData: CofPaymentData;
};

type SubmitSwishGiftcardPaymentContext = SubmitGiftcardPaymentContext & { loggedIn: boolean };

type SuccessRedirectData = CofPaymentSuccessRedirectData;

export type CofPaymentSuccessRedirectData = {
  paymentMethod:
    | typeof CHECKOUT_PAYMENT_METHOD.STORED_COF
    | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
    | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
  responseData: AdyenCreatePaymentAccepted;
};

export type GiftcardCheckoutState = z.infer<typeof giftcardCheckoutStateSchema>;

const initialState: GiftcardCheckoutState = {
  summary: {
    acceptsGiftcard: false,
    acceptsValuecard: false,
    acceptsWellnesscard: false,
    availablePaymentMethods: [],
    isOnlinePaymentRequired: true,
    preSelectedPaymentMethod: {},
    canPayOnline: true,
  },
  selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.NONE },
  missingActions: [],
  submitting: false,
  loading: true,
};

type GiftcardCheckoutAction =
  | CheckoutAction<CheckoutSummary>
  | { type: 'INITIALIZE_KLARNA'; payload: KlarnaResponse }
  | { type: 'LOADING'; payload: boolean }
  | {
      type: 'SET_GUEST_ID';
    }
  | {
      type: 'ONHOLD_COF';
      payload: {
        cofThreeDS: CreatePaymentActionRequiredResponse;
        giftcardOrderId: number;
      };
    };

const giftcardCheckoutReducer: Reducer<GiftcardCheckoutState, GiftcardCheckoutAction> = (state, action) => {
  switch (action.type) {
    case 'SUBMITTING': {
      return { ...state, submitting: action.payload };
    }
    case 'INITIALIZE_KLARNA': {
      return {
        ...state,
        klarna: action.payload,
      };
    }
    case 'SET_PAYMENT_METHOD':
      const hasNotSelectedPaymentMethod = action.payload.type === CHECKOUT_PAYMENT_METHOD.NONE;
      return {
        ...state,
        selectedPaymentMethod: action.payload,
        missingActions: state.missingActions.filter((a) => hasNotSelectedPaymentMethod || a !== 'payment-method'),
      };
    case 'SET_MISSING_ACTION': {
      const missingActions = Array.from(new Set([...state.missingActions, action.payload]));
      return {
        ...state,
        missingActions,
      };
    }
    case 'ONHOLD_COF': {
      return {
        ...state,
        cofThreeDS: action.payload.cofThreeDS,
        giftcardOrderId: action.payload.giftcardOrderId,
        submitting: false,
      };
    }
    case 'SET_GUEST_ID': {
      return {
        ...state,
        guestId: uuidv4(),
      };
    }
    case 'ERROR': {
      return {
        ...state,
        submitting: false,
        cofThreeDS: null,
        error: action.payload,
      };
    }
    case 'UPDATE_SUMMARY':
      return {
        ...state,
        summary: action.payload,
      };
    case 'LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};

export type UseGiftcardCheckoutManagerResult = ReturnType<typeof useGiftcardCheckoutManager>;

async function handleKlarnaPayment(
  context: SubmitKlarnaGiftcardPaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
) {
  const { formData, productType, user, klarna } = context;

  const address = {
    address: formData.address ? formData.address : user?.contact?.streetAddress,
    city: formData.city ? formData.city : user?.contact?.locality,
    name: formData.name ? formData.name : user?.about?.name,
    zipcode: formData.postalCode ? formData.postalCode : user?.contact?.postalCode,
  };

  const email = formData.email ? formData.email : user?.contact?.email;

  if (klarna) {
    dispatch({ type: 'SUBMITTING', payload: true });
  }

  const { data, error } = await promiseWrapper(
    giftCardServices.createOrUpdateOrder({
      code: formData.discountCode,
      discount: 0,
      isWellness: productType === 'wellnesscard',
      quantity: formData.quantity,
      ...(formData.serviceType
        ? { serviceType: { name: formData.serviceType, taxRate: formData.serviceType === 'massage' ? 2500 : 600 } }
        : {}),
      ...(formData.type === 'physical' ? { shipping: address } : { shipping: null }),
      shippingMethod: formData.type === 'digital' ? 'digital' : 'shipping',
      greeting:
        formData.giverName || formData.message || formData.recipientName
          ? { title: formData.recipientName, message: formData.message, giver: formData.giverName }
          : null,
      sessionID: klarna?.sessionID ?? null,
      ssn: formData.ssn,
      value: formData.customAmount || formData.amount,
      email,
      newConfirmationPage: true,
    }),
  );

  const validateResponse = klarnaResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  if (!klarna) {
    dispatch({
      type: 'INITIALIZE_KLARNA',
      payload: validateResponse.data,
    });
  }

  dispatch({ type: 'SUBMITTING', payload: false });

  return validateResponse.data;
}

async function handleInitQliroPayment(
  context: SubmitGiftcardPaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
) {}

function handleSubmitGooglePay(context: SubmitCofGiftcardPaymentContext, dispatch: Dispatch<GiftcardCheckoutAction>) {
  return async (final: number) => {
    try {
      const redirectUrl = getRedirectUrl(context.productType, context.slugId);
      const googlePaymentsClient = clientInstance('gc');
      const isReadyToPay = googlePaymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest('gc'));

      if (!isReadyToPay) throw new Error('Google pay is not ready to pay');

      dispatch({ type: 'SUBMITTING', payload: true });

      const checkoutState: GiftcardFormData = {
        serviceType: context.formData.serviceType,
        type: context.formData.type,
        customAmount: context.formData.customAmount,
        amount: context.formData.amount,
        quantity: context.formData.quantity,
      };

      const encodedBase64CheckoutState = encodeURIComponent(
        Buffer.from(JSON.stringify(checkoutState)).toString('base64'),
      );

      const response = await googlePaymentsClient.loadPaymentData(
        getGooglePayPaymentDataRequest(
          {
            totalPrice: `${final.toFixed(2)}`,
            totalPriceStatus: 'FINAL',
            totalPriceLabel: _s('googlepay.totalPriceLabel.paynow'),
            currencyCode: 'SEK',
            countryCode: 'SE',
            displayItems: [
              ...((): google.payments.api.DisplayItem[] => {
                return [
                  {
                    label: _s('googlepay.totalPriceLabel.paynow'),
                    type: 'SUBTOTAL',
                    price: `${final.toFixed(2)}`,
                    status: 'PENDING',
                  },
                ];
              })(),
            ],
          },
          'gc',
        ),
      );

      await completeWalletBooking(
        {
          ...context,
          paymentData: {
            paymentMethod: {
              type: 'googlepay',
              googlePayToken: response.paymentMethodData.tokenizationData.token,
              googlePayCardNetwork: response.paymentMethodData.info.cardNetwork,
            },
            browserInfo: buildBrowserInfo(),
            redirectInfo: { returnPath: `${redirectUrl}?data=${encodedBase64CheckoutState}` },
          },
          type: 'googlepay',
        },
        dispatch,
      );
    } catch (error) {
      // Google pay throws "CANCELED statusCode when user closes the payments sheet"
      if (error.statusCode !== 'CANCELED') {
        context.errorCallback(error);
      }
      dispatch({ type: 'ERROR', payload: true });
    }
  };
}

function handleSubmitApplePay(context: SubmitCofGiftcardPaymentContext, dispatch: Dispatch<GiftcardCheckoutAction>) {
  return async (final: number) => {
    const redirectUrl = getRedirectUrl(context.productType, context.slugId);
    const checkoutState: GiftcardFormData = {
      serviceType: context.formData.serviceType,
      type: context.formData.type,
      customAmount: context.formData.customAmount,
      amount: context.formData.amount,
      quantity: context.formData.quantity,
    };

    const encodedBase64CheckoutState = encodeURIComponent(
      Buffer.from(JSON.stringify(checkoutState)).toString('base64'),
    );

    const applePaySession = createSession({
      total: mapApplePayLineItem({ amount: `${final}`, label: 'Bokadirekt', payLater: false }),
    });

    applePaySession.begin();

    applePaySession.onpaymentauthorized = async (payment) => {
      try {
        await completeWalletBooking(
          {
            ...context,
            paymentData: {
              paymentMethod: {
                type: 'applepay',
                applePayToken: payment.payment.token.paymentData,
              },
              redirectInfo: { returnPath: `${redirectUrl}?data=${encodedBase64CheckoutState}` },
            },
            type: 'applepay',
          },
          dispatch,
          applePaySession,
        );
      } catch (error) {
        context.errorCallback(error);

        applePaySession.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors: [],
        });
        dispatch({ type: 'ERROR', payload: true });
      }
    };
  };
}

async function completeWalletBooking(
  context: CompleteWalletPaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
  applePaySession?: ApplePaySession,
) {
  const { type, paymentData, formData, guestId, productType, selectedPaymentMethod, employee } = context;
  const paymentMethod = type === 'googlepay' ? CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY : CHECKOUT_PAYMENT_METHOD.APPLE_PAY;

  const { data, error } = await promiseWrapper(
    GiftcardService.createGiftcardAdyenOrder({
      ...getGiftcardRequestData({ formData, productType, employee }),
      guestId,
      paymentMethod,
      paymentData: {
        ...(() => {
          if (paymentData.paymentMethod.type === 'googlepay') {
            return {
              paymentMethod: {
                type: 'googlepay',
                googlePayToken: paymentData.paymentMethod.googlePayToken,
                googlePayCardNetwork: paymentData.paymentMethod.googlePayCardNetwork,
              },
              browserInfo: paymentData.browserInfo,
              redirectInfo: paymentData.redirectInfo,
            };
          }

          if (paymentData.paymentMethod.type === 'applepay') {
            return {
              paymentMethod: { type: 'applepay', applePayToken: paymentData.paymentMethod.applePayToken },
              browserInfo: paymentData.browserInfo,
              redirectInfo: paymentData.redirectInfo,
            };
          }
        })(),
      },
    }),
  );

  const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

  if (paymentAccepted.success) {
    applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_SUCCESS });
    context.successCallback({ paymentMethod, responseData: paymentAccepted.data });
    return;
  }

  const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

  if (paymentActionRequired.success) {
    saveCofCheckoutState({
      cofThreeDS: paymentActionRequired.data,
      isAddNewCard: false,
      payLater: false,
      selectedPaymentMethod,
    });

    dispatch?.({
      type: 'ONHOLD_COF',
      payload: { cofThreeDS: paymentActionRequired.data, giftcardOrderId: paymentActionRequired.data.id },
    });

    return;
  }

  const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

  context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_FAILURE });
}

function handleSubmitGuestCoF(context: SubmitCofGiftcardPaymentContext, dispatch: Dispatch<GiftcardCheckoutAction>) {
  return async (paymentData: CofPaymentData) => {
    const { formData, guestId, productType, selectedPaymentMethod, employee, slugId } = context;
    const redirectUrl = getRedirectUrl(productType, slugId);

    const checkoutState: GiftcardFormData = {
      serviceType: formData.serviceType,
      type: formData.type,
      customAmount: formData.customAmount,
      amount: formData.amount,
      quantity: formData.quantity,
    };

    const encodedBase64CheckoutState = encodeURIComponent(
      Buffer.from(JSON.stringify(checkoutState)).toString('base64'),
    );

    const paymentMethod = (() => {
      if (paymentData?.paymentMethod?.type === 'googlepay') return CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY;
      if (paymentData?.paymentMethod?.type === 'applepay') return CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
      return CHECKOUT_PAYMENT_METHOD.STORED_COF;
    })();

    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(
      GiftcardService.createGiftcardAdyenOrder({
        ...getGiftcardRequestData({ formData, productType, employee }),
        paymentData: {
          ...paymentData,
          redirectInfo: { returnPath: `${redirectUrl}?data=${encodedBase64CheckoutState}` },
        },
        guestId,
        paymentMethod,
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      context.successCallback({ paymentMethod, responseData: paymentAccepted.data });
      return;
    }
    const threeDSData = createPaymentActionRequiredResponseSchema.safeParse(data);
    if (threeDSData.success) {
      saveCofCheckoutState({
        cofThreeDS: threeDSData.data,
        isAddNewCard: false,
        payLater: false,
        selectedPaymentMethod,
      });

      dispatch?.({
        type: 'ONHOLD_COF',
        payload: {
          cofThreeDS: threeDSData.data,
          giftcardOrderId: threeDSData.data.id,
        },
      });
      return;
    }

    context.errorCallback(error);
  };
}

function handleSubmitCoF(context: SubmitCofGiftcardPaymentContext, dispatch: Dispatch<GiftcardCheckoutAction>) {
  return async (storedPaymentMethodId: string) => {
    const { formData, guestId, productType, selectedPaymentMethod, employee, slugId } = context;
    const redirectUrl = getRedirectUrl(productType, slugId);

    const checkoutState: GiftcardFormData = {
      serviceType: formData.serviceType,
      type: formData.type,
      customAmount: formData.customAmount,
      amount: formData.amount,
      quantity: formData.quantity,
    };

    const encodedBase64CheckoutState = encodeURIComponent(
      Buffer.from(JSON.stringify(checkoutState)).toString('base64'),
    );

    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(
      GiftcardService.createGiftcardAdyenOrder({
        ...getGiftcardRequestData({ formData, productType, employee }),
        paymentData: {
          paymentMethod: {
            type: 'storedCard',
            storedPaymentMethodId,
          },
          browserInfo: buildBrowserInfo(),
          redirectInfo: { returnPath: `${redirectUrl}?data=${encodedBase64CheckoutState}` },
        },
        guestId,
        paymentMethod: CHECKOUT_PAYMENT_METHOD.STORED_COF,
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      context.successCallback({
        paymentMethod: CHECKOUT_PAYMENT_METHOD.STORED_COF,
        responseData: paymentAccepted.data,
      });
      return;
    }

    const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

    if (paymentActionRequired.success) {
      saveCofCheckoutState({
        cofThreeDS: paymentActionRequired.data,
        isAddNewCard: false,
        payLater: false,
        selectedPaymentMethod,
      });

      dispatch?.({
        type: 'ONHOLD_COF',
        payload: { cofThreeDS: paymentActionRequired.data, giftcardOrderId: paymentActionRequired.data.id },
      });

      return;
    }

    const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

    context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  };
}

export function handleSubmitThreeDSPayment(context: SubmitThreeDSContext, dispatch?: Dispatch<GiftcardCheckoutAction>) {
  return async (_state: any) => {
    const { paymentMethod, giftcardOrderId, selectedPaymentMethod, cofThreeDS, errorCallback, successCallback } =
      context;
    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(
      GiftcardService.submitGiftcardAdyenPaymentDetails({
        details: _state.data.details,
        paymentData: cofThreeDS.adyenPaymentData,
        paymentMethod,
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      successCallback({ paymentMethod: CHECKOUT_PAYMENT_METHOD.STORED_COF, responseData: paymentAccepted.data });
      return;
    }

    const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

    if (paymentActionRequired.success) {
      if (selectedPaymentMethod) {
        saveCofCheckoutState({
          cofThreeDS: paymentActionRequired.data,
          isAddNewCard: false,
          payLater: false,
          selectedPaymentMethod,
        });
      }

      dispatch?.({
        type: 'ONHOLD_COF',
        payload: { cofThreeDS: paymentActionRequired.data, giftcardOrderId },
      });
      return;
    }

    const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

    errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  };
}

export async function handleSubmitSwishPayment(
  context: SubmitSwishGiftcardPaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
) {}

async function handlePlaceKlarnaPayment(
  context: SubmitKlarnaPlacePaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
) {
  const { formData, user, klarna, placeId, slug, slugId, employees } = context;

  const employee = employees && formData.employee ? employees.find((e) => e.id === formData.employee) : null;

  const email = formData.email ? formData.email : user?.contact?.email;

  if (klarna) {
    dispatch({ type: 'SUBMITTING', payload: true });
  }

  const { data, error } = await promiseWrapper(
    initiateOrUpdatePGCPayment(klarna?.sessionID ?? null, {
      quantity: formData.quantity,
      value: formData.customAmount || formData.amount,
      placeId,
      employee,
      slug,
      slugId,
      email,
      newConfirmationPage: true,
    }),
  );

  const validateResponse = klarnaResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  if (!klarna) {
    dispatch({
      type: 'INITIALIZE_KLARNA',
      payload: validateResponse.data,
    });
  }

  dispatch({ type: 'SUBMITTING', payload: false });

  return validateResponse.data;
}

const useGiftcardCheckoutManager = (formData: GiftcardFormData) => {
  const { setValue, getValues } = useGiftcardCheckoutFormData();
  const location = useLocation();
  const history = useHistory();
  const user = useAppSelector((state) => state.users.user);
  const match = useRouteMatch();
  const { id, slug } = exportIdSlug(match?.params?.slugId);
  const slugId = match?.params?.slugId;
  const { cards } = useCardsContext();
  const { hasActiveGooglePayPaymentMethod } = useWalletPayment();

  const productType = getGiftcardCheckoutProductType(match.params, location.pathname);
  const { loading: loadingPlace, place } = (() => {
    switch (productType) {
      case 'placecard': {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return usePlaceGiftCardData(undefined, parseInt(id), slug);
      }
      case 'valuecard': {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useValueCardData(undefined, parseInt(id), slug);
      }
      default:
        return { loading: false, place: null };
    }
  })();

  const employees = place && !isEmpty(place) && place.employees;
  const placeName = (place && !isEmpty(place) && place.name) || '...';
  const placeSellsGiftcard = Boolean(place && !isEmpty(place) && place.sellsGiftCard);
  const giftcardValue = formData.customAmount || formData.amount;
  const validSelectedPaymentMethod = selectedPaymentMethodSchema.safeParse(location.state?.selectedPaymentMethod);
  const savedSelectedPaymentMethod = validSelectedPaymentMethod.success ? validSelectedPaymentMethod.data : null;

  const [state, dispatch] = useReducer(giftcardCheckoutReducer, initialState);

  useEffect(() => {
    if (!place?.employees?.length) return;
    place.redirect && (window.location.href = `${place.redirect}/giftcard/checkout`);

    if (productType === 'placecard') {
      setValue('employee', place.employees[0].id);
    }

    if (productType === 'valuecard') {
      const pickedEmployee = place.employees.find((e) => e.id === location.state.employeeId);
      const pickedValueCard = pickedEmployee?.valueCards.find((vc) => vc.id === location.state.valueCard.id);
      setValue('employee', pickedEmployee.id);
      setValue('amount', pickedValueCard.price / 100);
    }

    // setValue('amount', )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  useEffect(() => {
    let loading = true;

    if (productType === 'placecard') {
      loading = loadingPlace || place?.dataType !== 'giftCard';
    }

    if (productType === 'valuecard') {
      loading = loadingPlace || place?.dataType !== 'valueCard';
    }

    dispatch({ type: 'LOADING', payload: loading });
  }, [loadingPlace, place?.dataType]);

  useEffect(() => {
    if (!user) {
      guestCheckoutManager().setOptions({ useGuestCheckout: true });
      dispatch({ type: 'SET_GUEST_ID' });
    }
  }, []);

  useEffect(() => {
    const updateSummary = async () => {
      const orderTotal = giftcardValue * formData.quantity + (formData.type === 'physical' ? PHYSICAL_FEE : 0);
      const { data } = formData.discountCode
        ? await promiseWrapper(giftCardServices.checkDiscountCode(formData.discountCode, orderTotal))
        : { data: null };

      const summary = getGiftcardCheckoutSummary({
        cards,
        orderTotal,
        canPayWithGooglePay: hasActiveGooglePayPaymentMethod,
        discountAmount: data?.discount,
      });

      const selectedPaymentMethod =
        state.selectedPaymentMethod.type !== -1
          ? state.selectedPaymentMethod
          : getRedirectPreselectedPaymentMethod({
              cards,
              summary,
              selectedPaymentMethod: savedSelectedPaymentMethod,
            }) ||
            summary.preSelectedPaymentMethod ||
            summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.NONE);

      dispatch({ type: 'UPDATE_SUMMARY', payload: summary });
      dispatch({ type: 'SET_PAYMENT_METHOD', payload: selectedPaymentMethod });
    };
    updateSummary();
  }, [
    cards,
    formData.type,
    formData.amount,
    formData.discountCode,
    formData.customAmount,
    formData.quantity,
    location.state?.selectedPaymentMethod,
  ]);

  /**
   * Currently we want google pay to be the default payment method if it's available
   * since it might not be loaded initially we want to update the summary and
   * set the payment method as selected when it's ready
   */
  useEffect(() => {
    const hasGooglePayInSummary = state.summary.availablePaymentMethods.some(
      (method) => method.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY,
    );

    if (hasGooglePayInSummary || !hasActiveGooglePayPaymentMethod) return;

    (async () => {
      const orderTotal = giftcardValue * formData.quantity + (formData.type === 'physical' ? PHYSICAL_FEE : 0);
      const { data } = formData.discountCode
        ? await promiseWrapper(giftCardServices.checkDiscountCode(formData.discountCode, orderTotal))
        : { data: null };

      const summary = getGiftcardCheckoutSummary({
        cards,
        orderTotal,
        canPayWithGooglePay: hasActiveGooglePayPaymentMethod,
        discountAmount: data?.discount,
      });

      dispatch({ type: 'UPDATE_SUMMARY', payload: summary });
      dispatch({
        type: 'SET_PAYMENT_METHOD',
        payload:
          summary.preSelectedPaymentMethod ||
          summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.NONE),
      });
    })();
  }, [hasActiveGooglePayPaymentMethod]);

  const handleChangePaymentMethod = (method: SelectedPaymentMethod) => {
    dispatch({ type: 'SET_PAYMENT_METHOD', payload: method });
  };

  const handleUseGuestCheckout = () => {
    guestCheckoutManager().setOptions({ useGuestCheckout: true });
    dispatch({ type: 'SET_GUEST_ID' });
  };

  const handleOnCheckoutMissingAction = (action: CheckoutMissingAction) => {
    dispatch({ type: 'SET_MISSING_ACTION', payload: action });
  };

  const handleError = (error: unknown) => {
    const errorData = errorResponseSchema.safeParse(error);
    dispatch({ type: 'ERROR', payload: true });

    const displayClientError = (errorMessage?: string) => {
      toast(
        ({ closeToast }) => (
          <Snackbar
            label={errorMessage ?? _s('serverError')}
            type="danger"
            onClose={() => {
              dispatch({ type: 'ERROR', payload: false });
              closeToast();
            }}
          />
        ),
        {
          autoClose: 5000,
        },
      );
    };

    if (!errorData.success) {
      displayClientError();
      Sentry.captureException(error);
      return;
    }

    const { clientError } = errorData.data;

    displayClientError(clientError);
    Sentry.captureException(error);
  };

  const giftcardCheckoutAPI = (): CheckoutAPI => {
    const employeeId = productType === 'valuecard' ? location.state.employeeId : formData.employee;
    const employee = employees && formData.employee ? employees.find((e) => e.id === employeeId) : undefined;
    // filter out all value cards that are not the selected
    if (productType === 'valuecard') {
      employee['valueCards'] = employee?.valueCards?.filter((vc) => vc.id === location.state.valueCard.id);
    }

    const context: SubmitGiftcardPaymentContext = {
      errorCallback: handleError,
      successCallback: ({ paymentMethod, responseData }) => {
        switch (paymentMethod) {
          case CHECKOUT_PAYMENT_METHOD.STORED_COF:
          case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
          case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
            const selectedPaymentMethod =
              state.selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF
                ? {
                    type: state.selectedPaymentMethod.type,
                    id: state.selectedPaymentMethod.id,
                    brand: state.selectedPaymentMethod.brand,
                    lastFour: state.selectedPaymentMethod.lastFour,
                  }
                : state.selectedPaymentMethod;

            const { amount, quantity, type } = getValues();

            switch (productType) {
              case 'giftcard':
              case 'wellnesscard':
                trackGiftcardPurchase({
                  giftcardAmount: amount,
                  quantity,
                  isDigital: type === 'digital',
                  isWellness: productType === 'wellnesscard',
                  orderId: responseData.id,
                  paymentsId: responseData.pspRef,
                });
                history.push({
                  pathname: `/giftcard/confirmation/${responseData.id}`,
                  state: {
                    ...(selectedPaymentMethod && { selectedPaymentMethod }),
                    ...(!user && { guestEmail: formData.email }),
                  },
                });
                break;
              case 'placecard':
                newTrackPlaceGiftCardPurchase({
                  giftcardAmount: amount,
                  quantity,
                  paymentsId: responseData.pspRef,
                  placeId: id,
                });
                history.push({
                  pathname: `/places/${slugId}/placecard/confirmation/${responseData.id}`,
                  state: {
                    ...(selectedPaymentMethod && { selectedPaymentMethod }),
                    ...(!user && { guestEmail: formData.email }),
                  },
                });
                break;
              case 'valuecard':
                newTrackPlaceGiftCardPurchase({
                  giftcardAmount: amount,
                  quantity,
                  paymentsId: responseData.pspRef,
                  placeId: id,
                  isValueCard: true,
                });
                history.push({
                  pathname: `/places/${slugId}/valuecard/confirmation/${responseData.id}`,
                  state: {
                    ...(selectedPaymentMethod && { selectedPaymentMethod }),
                    ...(!user && { guestEmail: formData.email }),
                  },
                });
                break;
            }

            break;

          default:
            break;
        }
      },
      formData,
      productType,
      employee,
      slugId: slugId,
    };

    const adyenContext = {
      guestId: state.guestId,
      selectedPaymentMethod: state.selectedPaymentMethod,
    };

    return {
      submitCoF: handleSubmitCoF({ ...context, ...adyenContext }, dispatch),
      submitGuestCoF: handleSubmitGuestCoF({ ...context, ...adyenContext }, dispatch),
      submitGooglePay: handleSubmitGooglePay({ ...context, ...adyenContext }, dispatch),
      submitApplePay: handleSubmitApplePay({ ...context, ...adyenContext }, dispatch),
      submitThreeDS: handleSubmitThreeDSPayment(
        { ...context, ...adyenContext, cofThreeDS: state.cofThreeDS, giftcardOrderId: state.giftcardOrderId },
        dispatch,
      ),
      initQliro: () => handleInitQliroPayment(context, dispatch),
      submitSwish: () => handleSubmitSwishPayment({ ...context, loggedIn: Boolean(user) }, dispatch),
    };
  };

  return {
    ...state,
    placeSellsGiftcard,
    placeName,
    employees,
    handleKlarnaPayment: () =>
      handleKlarnaPayment({ errorCallback: handleError, formData, productType, user, klarna: state.klarna }, dispatch),
    handlePlaceKlarnaPayment: () =>
      handlePlaceKlarnaPayment(
        {
          errorCallback: handleError,
          formData: getValues(),
          user,
          klarna: state.klarna,
          placeId: id,
          slugId: match?.params?.slugId,
          slug,
          employees,
        },
        dispatch,
      ),
    onUseGuestCheckout: handleUseGuestCheckout,
    onChangePaymentMethod: handleChangePaymentMethod,
    handleOnCheckoutMissingAction,
    giftcardCheckoutAPI,
  };
};

export default useGiftcardCheckoutManager;

const GiftcardCheckoutContext = createContext<UseGiftcardCheckoutManagerResult>({
  onChangePaymentMethod: () => {},
  handleOnCheckoutMissingAction: () => {},
  handleKlarnaPayment: async () => {
    return {};
  },
  handlePlaceKlarnaPayment: async () => {
    return {};
  },
  submitting: false,
  placeSellsGiftcard: false,
  placeName: '',
  loading: true,
  cofThreeDS: null,
  employees: [],
  onUseGuestCheckout: () => {},
  giftcardCheckoutAPI: () => ({
    submitSwish: async () => {},
    submitCoF: async () => {},
    submitGooglePay: async () => {},
    submitGuestCoF: async () => {},
    submitApplePay: async () => {},
    submitThreeDS: async () => {},
    initQliro: async () => {},
  }),
  selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.NONE },
});

export const useGiftcardCheckout = () => {
  const context = useContext(GiftcardCheckoutContext);

  if (!context) {
    throw new Error('useGiftcardCheckout must be used within GiftcardCheckoutContext');
  }

  return context;
};

export const GiftcardCheckoutProvider = ({ children }: { children: React.ReactNode }) => {
  const { formdata } = useGiftcardCheckoutFormData();
  return (
    <GiftcardCheckoutContext.Provider value={useGiftcardCheckoutManager(formdata)}>
      {children}
    </GiftcardCheckoutContext.Provider>
  );
};
