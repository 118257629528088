import Header from '@/components/elements/Header/Header';
import Label from '@/components/elements/Label/Label';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import Select from '@/components/elements/forms/Select/Select';
import Button, { ButtonProps } from '@/components/elements/forms/buttons/Button/Button';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import ListInputSelect from '@/components/elements/forms/listInput/ListInputSelect/ListInputSelect';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import SEO from '@/components/modules/SEO';
import UpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo';
import useUpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo.hooks';
import { ThreeDSComponent } from '@/components/modules/adyen';
import {
  CheckoutModalProvider,
  useCheckoutModal,
} from '@/components/modules/checkout/CheckoutModal/CheckoutModal.hooks';
import CheckoutTerms from '@/components/modules/checkout/CheckoutTerms/CheckoutTerms';
import PromotedPaymentMethods from '@/components/modules/checkout/PromotedPaymentMethods/PromotedPaymentMethods';
import DeliveryInfo from '@/components/modules/checkout/giftcard/DeliveryInfo/DeliveryInfo';
import DiscountCode from '@/components/modules/checkout/giftcard/DiscountCode';
import PersonalGreeting from '@/components/modules/checkout/giftcard/PersonalGreeting';
import SSNFormCard from '@/components/modules/checkout/giftcard/SSNForm';
import SelectAmount from '@/components/modules/checkout/giftcard/SelectAmount/SelectAmount';
import SelectType from '@/components/modules/checkout/giftcard/SelectType/SelectType';
import CreditCardModal from '@/components/modules/modals/CreditCardModal';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import ActionSteps from '@/components/templates/giftCards/ActionSteps';
import CheckoutDetails from '@/components/templates/giftCards/CheckoutDetails/CheckoutDetails';
import ProductInfo from '@/components/templates/giftCards/ProductInfo/ProductInfo';
import { EVENT_NAME, SCREEN_NAME } from '@/constants/analytics';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { OLD_KLARNA_GIFTCARD_CHECKOUT_FEATURE_FLAG } from '@/constants/experimentConstants';
import { GIFTCARD_DEFAULT_VALUE_SELECTED, WELLNESS_DEFAULT_VALUE_SELECTED } from '@/constants/giftcardConstants';
import { TermLinkIdentifier } from '@/constants/terms';
import {
  classnames,
  exportIdSlug,
  getGiftCardExpiryDateFromToday,
  getWellnessCardExpiryDateFromToday,
  trackMpEvent,
  trackPage,
  url,
} from '@/helpers';
import { getCheckoutTerms } from '@/helpers/checkout';
import { arrayOfStrings } from '@/helpers/utilsTS';
import { useAppSelector } from '@/hooks';
import { useCardsManager, UseCardsProvider } from '@/hooks/adyen/useCards';
import {
  formDataSchema,
  GiftcardCheckoutFormDataProvider,
  GiftcardFormData,
  useGiftcardCheckoutFormData,
} from '@/hooks/useGiftcardCheckoutFormData';
import useMobileView from '@/hooks/useMobileView';
import { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { giftCardServices } from '@/services';
import { authorizeKlarnaPurchase, getInitializeKlarnaSDKOnce, loadKlarnaWidget } from '@/services/klarnaServices';
import { createPlaceGiftCardOrder } from '@/services/pgcPaymentServices';
import { CheckoutMissingAction, SelectedPaymentMethod, selectedPaymentMethodSchema } from '@/types/checkout';
import { PaymentCard } from '@/types/paymentcards';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import NotFound from '../404';
import { saveCofCheckoutStateToLocalStorage } from '../validate-cof-payment-redirect/ValidateCofPaymentRedirect.hooks';
import { getGiftcardCheckoutProductType } from './GiftcardCheckout.helpers';
import { GiftcardCheckoutProvider, useGiftcardCheckout } from './GiftcardCheckout.hooks';
import { CampaignType, ProductType } from './GiftcardCheckout.types';
import useKlarnaGiftcardCheckoutManager from './klarna/KlarnaGiftcardCheckout.hooks';

const baseTranslationKey = 'pages.giftcard.checkout';

function handleUpdateUserInfoCallback({ success, retry }, closeUpdateUserInfo: () => void) {
  trackScreenView({ name: success ? 'screen_view_update_profile_success' : 'screen_view_update_profile_fail' });

  if (success) closeUpdateUserInfo();

  toast(
    ({ closeToast }) => (
      <Snackbar
        label={_s(`${baseTranslationKey}.editProfileModal.snackbar.label.${success ? 'success' : 'error'}`)}
        type={success ? 'success' : 'danger'}
        action={
          <button onClick={success ? closeToast : retry}>
            {_s(`${baseTranslationKey}.editProfileModal.snackbar.action.${success ? 'success' : 'error'}`)}
          </button>
        }
      />
    ),
    { autoClose: 2000 },
  );
}

const getCampaignType = ({
  campaignType,
  productType,
}: {
  campaignType?: string;
  productType: ProductType;
}): CampaignType | null => {
  if (productType === 'placecard' || productType === 'valuecard') {
    return null;
  }

  if (productType === 'wellnesscard') {
    switch (campaignType) {
      case 'massage':
        return 'massage';
      case 'fotvard':
        return 'footcare';
      case 'akupunktur':
        return 'acupuncture';
      case 'personlig-traning':
        return 'pt';
      case 'yoga':
        return 'yoga';
      default:
        return null;
    }
  }

  switch (campaignType) {
    case 'fars-dag':
      return 'fathersDay';
    case 'julklapp':
      return 'christmas';
    case 'fodelsedag':
      return 'birthday';
    case 'ga-bort-present':
      return 'giveAwayGift';
    default:
      return null;
  }
};

const getProductInfo = (type: ProductType | CampaignType, practitioner?: string) => {
  switch (type) {
    case 'giftcard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-${type}-desktop-2xl.png`,
          desktop: `/images/product-info-${type}-desktop-lg.png`,
          mobile: `/images/product-info-${type}-mobile.png`,
        },
      };
    case 'wellnesscard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.${type}.title`),
        subTitles: [
          _s(`${baseTranslationKey}.productInfo.${type}.subTitle`),
          _s(`${baseTranslationKey}.productInfo.${type}.subTitle2`),
        ],
        imgSrc: {
          desktop2xl: `/images/product-info-${type}-desktop-2xl.png`,
          desktop: `/images/product-info-${type}-desktop-lg.png`,
          mobile: `/images/product-info-${type}-mobile.png`,
        },
      };
    case 'fathersDay':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [
          _s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`),
          _s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle2`),
        ],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-${type}.png`,
          desktop: `/images/product-info-giftcard-${type}.png`,
          mobile: `/images/product-info-giftcard-${type}-mobile.png`,
        },
      };
    case 'christmas':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-${type}.png`,
          desktop: `/images/product-info-giftcard-${type}.png`,
          mobile: `/images/product-info-giftcard-${type}-mobile.png`,
        },
      };
    case 'birthday':
    case 'giveAwayGift':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-desktop-2xl.png`,
          desktop: `/images/product-info-giftcard-desktop-lg.png`,
          mobile: `/images/product-info-giftcard-mobile.png`,
        },
      };
    case 'massage':
    case 'footcare':
    case 'acupuncture':
    case 'pt':
    case 'yoga':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.${type}.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.campaigns.${type}.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-wellnesscard-${type}.png`,
          desktop: `/images/product-info-wellnesscard-${type}.png`,
          mobile: `/images/product-info-wellnesscard-${type}-mobile.png`,
        },
      };
    case 'placecard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.placecard.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.placecard.subTitle`, { practitioner })],
        imgSrc: {
          desktop2xl: `/images/product-info-placecard-desktop-2xl.png`,
          desktop: `/images/product-info-placecard-desktop-lg.png`,
          mobile: `/images/product-info-placecard-mobile.png`,
        },
      };
    case 'valuecard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.valuecard.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.valuecard.subTitle`, { practitioner })],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-desktop-2xl.png`,
          desktop: `/images/product-info-giftcard-desktop-lg.png`,
          mobile: `/images/product-info-giftcard-mobile.png`,
        },
      };

    default:
      return {
        title: _s(`${baseTranslationKey}.productInfo.giftcard.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.giftcard.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-desktop-2xl.png`,
          desktop: `/images/product-info-giftcard-desktop-lg.png`,
          mobile: `/images/product-info-giftcard-mobile.png`,
        },
      };
  }
};

const getCampaignAlerts = (type: CampaignType) => {
  if (type === 'birthday' || type === 'fathersDay' || type === 'giveAwayGift') {
    return;
  }

  if (type === 'christmas') {
    return (
      <Alert
        variant="information"
        leftSlot={<Icon variant="info-circle" />}
        body={_s(`${baseTranslationKey}.productInfo.campaigns.christmas.alert`)}
      />
    );
  }

  const typeTranslation = _s(`${baseTranslationKey}.productInfo.campaigns.${type}.type`);

  return (
    <Alert
      variant="information"
      leftSlot={<Icon variant="info-circle" />}
      body={
        <>
          <span>{_s(`${baseTranslationKey}.productInfo.campaigns.alert1`, { type: typeTranslation })}</span>{' '}
          <Link className="underline" to="/vad/var?wellness=true">
            {_s(`${baseTranslationKey}.productInfo.campaigns.link`)}
          </Link>{' '}
          <span>{_s(`${baseTranslationKey}.productInfo.campaigns.alert2`)}</span>
        </>
      }
    />
  );
};

const GiftcardCheckout = ({ productType }: { productType: ProductType }) => {
  const { isMobileView } = useMobileView();
  const user = useAppSelector((state) => state.users.user);
  const is404 = useAppSelector((state) => state.loading?.is404);
  const location = useLocation();
  const { campaign } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const { slugId } = useParams();
  const { id, slug } = exportIdSlug(slugId);
  const utm_source = queryParams.get('utm_source');
  const appUser = utm_source === 'mp_android_app' || utm_source === 'mp_ios_app';
  const { handleSubmit, formdata, reset, register } = useGiftcardCheckoutFormData();
  const selectPaymentMethodContainerRef = useRef<HTMLDivElement>(null);
  const updateUserInfo = useUpdateUserInfo({
    callback: (props) =>
      handleUpdateUserInfoCallback({ retry: props.retry, success: props.success }, handleCloseEditProfile),
    triggerSource: SCREEN_NAME.CHECKOUT_GIFTCARD_SUMMARY,
  });
  const [isDeliveryTooltipOpen, setIsDeliveryTooltipOpen] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const {
    summary,
    handleKlarnaPayment,
    klarna,
    employees,
    handlePlaceKlarnaPayment,
    loading,
    placeSellsGiftcard,
    placeName,
    missingActions,
    selectedPaymentMethod,
    onChangePaymentMethod,
    giftcardCheckoutAPI,
    guestId,
    submitting,
    cofThreeDS,
  } = useGiftcardCheckout();
  const { onModalClose: onCheckoutModalClose, ...checkoutModalContext } = useCheckoutModal();
  const deliveryType = formdata.type;
  const seoBaseString = productType === 'wellnesscard' ? 'buyGiftcardWellness' : 'buyGiftcardUniversal';
  const campaignType = getCampaignType({ campaignType: campaign, productType });
  const isKlarnaCheckout =
    useAppSelector((state) => state.flags)[OLD_KLARNA_GIFTCARD_CHECKOUT_FEATURE_FLAG]?.value === 'on';
  const checkoutTerms = getCheckoutTerms(
    'giftcard',
    TermLinkIdentifier.COUPON,
    isKlarnaCheckout ? _s('checkout.payWithKlarna') : _s('Buy'),
  );
  const isPlaceGiftcard = productType === 'placecard';
  const pickedValueCard = location?.state?.valueCard?.id;
  const typeTranslation =
    campaignType && productType === 'wellnesscard'
      ? _s(`${baseTranslationKey}.productInfo.campaigns.${campaignType}.type`)
      : '';
  const isGuestCheckout = Boolean(!user && guestId);

  const handleCloseEditProfile = () => {
    setShowEditProfile(false);
  };

  const handleChangePaymentMethod = (method: SelectedPaymentMethod) => {
    if (!isGuestCheckout && method.type === CHECKOUT_PAYMENT_METHOD.NEW_COF) {
      checkoutModalContext.onModalShow('cof');
      return;
    }
    onChangePaymentMethod(method);

    if (isGuestCheckout && method.type === CHECKOUT_PAYMENT_METHOD.NEW_COF) {
      return;
    }

    onCheckoutModalClose();
    // @TODO - Implement tracking
  };

  const handleBeforeThreeDSRedirect = () => {
    const validatedAdyenPaymentMethod = selectedPaymentMethodSchema.safeParse(selectedPaymentMethod);

    if (validatedAdyenPaymentMethod.success === false) {
      Sentry.captureException(validatedAdyenPaymentMethod.error);
      return;
    }

    saveCofCheckoutStateToLocalStorage({
      isAddNewCard: true,
      payLater: false,
      selectedPaymentMethod: validatedAdyenPaymentMethod.data,
    });
  };

  const handleOnRemoveCard = (card: PaymentCard) => {
    if (selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF && selectedPaymentMethod.id === card.id) {
      handleChangePaymentMethod({
        type: CHECKOUT_PAYMENT_METHOD.NONE,
      });
    }
  };

  const handleCloseCheckoutModal = () => {
    onCheckoutModalClose();
    // @TODO - Implement tracking
  };

  const handleOnCreditCardSelect = (card: PaymentCard) => {
    handleChangePaymentMethod({
      type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
      brand: card.brand,
      id: card.id,
      lastFour: card.lastFour,
    });
  };

  useEffect(() => {
    if (!isPlaceGiftcard && isKlarnaCheckout) {
      handleKlarnaPayment();
    }
    trackPage();
    trackMpEvent('screen_shown', {
      screen_name: `${
        isPlaceGiftcard
          ? 'place_gift_card_landing_page'
          : productType === 'wellnesscard'
          ? 'wellness_card'
          : 'gift_card'
      }_landing_page`,
      place_id: id,
      campaign: campaignType ? campaign : undefined,
    });
  }, []);

  useEffect(() => {
    if (!loading && formdata.employee && employees?.length && isPlaceGiftcard && isKlarnaCheckout) {
      handlePlaceKlarnaPayment();
    }
  }, [formdata.employee, employees, isPlaceGiftcard]);

  useEffect(() => {
    let amount = undefined;

    switch (productType) {
      case 'giftcard':
      case 'placecard':
        amount = GIFTCARD_DEFAULT_VALUE_SELECTED;
        break;
      case 'wellnesscard':
        amount = WELLNESS_DEFAULT_VALUE_SELECTED;
        break;
    }

    const newInitialData: GiftcardFormData = {
      amount,
      quantity: 1,
      type: 'digital',
    };
    reset(newInitialData);
  }, [productType, reset]);

  useEffect(() => {
    if (klarna?.clientToken && isKlarnaCheckout) {
      const initializeKlarnaSDK = getInitializeKlarnaSDKOnce();
      initializeKlarnaSDK();
      loadKlarnaWidget('#klarna-payment', klarna.clientToken);
    }
  }, [klarna?.clientToken]);

  const getReturnUrl = (productType: ProductType) => {
    switch (productType) {
      case 'giftcard':
        return '/presentkort';
      case 'wellnesscard':
        return '/friskvardskort';
      case 'placecard':
        return `/places/${slugId}/giftcard/checkout`;
      case 'valuecard':
        return `/places/${slugId}/valuecard/checkout`;
    }
  };

  const bulletPoints = isPlaceGiftcard
    ? undefined
    : arrayOfStrings(_s(`${baseTranslationKey}.productInfo.${productType}.bulletPoints`));

  const deliveryInfoContent = deliveryType === 'digital' ? user?.contact?.email : user?.contact?.streetAddress;
  const deliveryInfoSubTitle =
    deliveryType === 'digital'
      ? user?.contact?.mobile
      : user?.contact?.postalCode && user?.contact?.locality
      ? `${user.contact.postalCode}, ${user.contact.locality}`
      : undefined;

  if (productType === 'placecard' && (is404 || (!placeSellsGiftcard && !loading))) {
    return <NotFound />;
  }

  if (productType === 'valuecard') {
    if (loading) return <LoadingPlaceHolder />;
    if (is404) return <NotFound />;
    if (!placeSellsGiftcard) return <NotFound />;
    if (!pickedValueCard && !loading) return <NotFound />;
  }

  const selectedEmployee =
    productType === 'valuecard' && employees?.length
      ? employees.find((employee) => employee.id === formdata.employee)
      : undefined;
  const selectedValueCard =
    pickedValueCard && selectedEmployee
      ? selectedEmployee.valueCards.find((valuecard) => valuecard.id === pickedValueCard)
      : undefined;

  const validAt = productType === 'valuecard' ? selectedEmployee?.name : slug;

  return (
    <PageViewLayout
      type={appUser ? 'subView' : 'mainView'}
      title={_s(`${baseTranslationKey}.page-title.${productType}`)}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      <form onSubmit={handleSubmit(() => {})}>
        <div className=" bg-brown-50 flex justify-center lg:bg-transparent">
          <div className="lg:py-xxl lg:container">
            <div className={`flex items-start lg:p-[30px] ${isMobileView ? '' : 'gap-[30px]'}`}>
              <div className={`flex grow-[9999] flex-col lg:max-w-[600px] ${isMobileView ? 'gap-md' : 'gap-lg'}`}>
                <ProductInfo
                  {...getProductInfo(campaignType ?? productType, validAt)}
                  bulletPoints={bulletPoints}
                  {...(campaignType
                    ? {
                        bottomSlot: getCampaignAlerts(campaignType),
                      }
                    : {})}
                  middleSlot={
                    productType === 'wellnesscard' ? (
                      <Label
                        label={`${_s(`${baseTranslationKey}.productInfo.wellnesscard.label`, {
                          expire: getWellnessCardExpiryDateFromToday(),
                        })}`}
                        className="bg-success-900 text-white"></Label>
                    ) : undefined
                  }
                />

                {isPlaceGiftcard && employees?.length > 1 && !loading && (
                  <CardWrapper>
                    <div className="px-lg py-md gap-lg flex flex-col">
                      <Header label={'Välj utövare'} size="md" />
                      <ListInputSelect leftPadding={false} {...register('employee')}>
                        {employees.map((employee, index) => {
                          return (
                            <Select.Option key={index} value={employee.id}>
                              {employee.name}
                            </Select.Option>
                          );
                        })}
                      </ListInputSelect>
                    </div>
                  </CardWrapper>
                )}

                {productType !== 'valuecard' && (
                  <SelectAmount title={_s(`${baseTranslationKey}.selectAmount.title`)} type={productType} />
                )}

                {productType === 'giftcard' && <SelectType onClick={() => setIsDeliveryTooltipOpen(true)} />}

                <DeliveryInfo
                  productType={productType}
                  onClick={() => setShowEditProfile(true)}
                  content={deliveryInfoContent}
                  subTitle={deliveryInfoSubTitle}
                />

                {deliveryType === 'physical' && productType === 'giftcard' && <PersonalGreeting />}

                {productType === 'giftcard' && <DiscountCode />}

                {productType === 'wellnesscard' && <SSNFormCard />}

                {!isKlarnaCheckout && (
                  <CardWrapper>
                    <div ref={selectPaymentMethodContainerRef}>
                      <PromotedPaymentMethods
                        summary={summary}
                        selectedPaymentMethod={selectedPaymentMethod}
                        onChangePaymentMethod={handleChangePaymentMethod}
                        triggerSource="checkout_giftcard_summary"
                        error={missingActions.includes('payment-method')}
                        {...checkoutModalContext}
                      />
                    </div>
                  </CardWrapper>
                )}

                <div className="w-full">
                  <CardWrapper>
                    <div className="gap-xl p-lg flex flex-col">
                      {isKlarnaCheckout && (
                        <>
                          <div id="klarna-payment"></div>
                          <SubmitCTA isPlaceGiftcard={isPlaceGiftcard} campaign={campaignType ? campaign : undefined} />
                        </>
                      )}
                      {!isKlarnaCheckout && <CofSubmitCTA />}

                      <CheckoutTerms
                        description={checkoutTerms.description}
                        linkLabel={checkoutTerms.linkLabel}
                        termsPageId={checkoutTerms.termsPageId}
                        onClose={() => {}}
                        triggerSource={SCREEN_NAME.CHECKOUT_GIFTCARD_SUMMARY}
                      />
                    </div>
                  </CardWrapper>
                </div>

                {isMobileView && productType === 'wellnesscard' && (
                  <ActionSteps
                    title={_s(`${baseTranslationKey}.actionSteps.title`)}
                    steps={[
                      { text: _s(`${baseTranslationKey}.actionSteps.stepOne`) },
                      {
                        text: _s(`${baseTranslationKey}.actionSteps.stepTwo`),
                        link: _s(`${baseTranslationKey}.actionSteps.bookLink`),
                      },
                      { text: _s(`${baseTranslationKey}.actionSteps.stepThree`) },
                    ]}
                  />
                )}
              </div>
              {!isMobileView && (
                <div
                  className={`sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px] lg:flex-col lg:gap-[30px]`}>
                  <CardWrapper className="space-y-lg w-[390px]">
                    <CheckoutDetails
                      selectedAmount={formdata.amount ?? formdata.customAmount}
                      selectedQuantity={formdata.quantity}
                      productType={productType}
                      deliveryType={deliveryType}
                      discount={
                        formdata.discountCode
                          ? { code: formdata.discountCode, amount: summary.discountAmount }
                          : undefined
                      }
                    />

                    {productType === 'giftcard' && (
                      <Alert
                        variant="information"
                        leftSlot={<Icon variant="info-circle" />}
                        body={_s(`${baseTranslationKey}.alert.giftcard`)}
                      />
                    )}
                    {productType === 'wellnesscard' && (
                      <Alert
                        variant="information"
                        leftSlot={<Icon variant="info-circle" />}
                        body={_s(`${baseTranslationKey}.alert.wellnesscard`, {
                          expire: getWellnessCardExpiryDateFromToday(),
                        })}
                      />
                    )}
                    {productType === 'valuecard' && (
                      <Alert
                        variant="information"
                        leftSlot={<Icon variant="info-circle" />}
                        body={_s(`${baseTranslationKey}.alert.wellnesscard`, {
                          expire: getGiftCardExpiryDateFromToday(selectedValueCard?.daysOfValidity),
                        })}
                      />
                    )}
                  </CardWrapper>
                  {productType === 'wellnesscard' && (
                    <ActionSteps
                      insideCard={true}
                      title={_s(`${baseTranslationKey}.actionSteps.title`)}
                      steps={[
                        { text: _s(`${baseTranslationKey}.actionSteps.stepOne`) },
                        {
                          text: _s(`${baseTranslationKey}.actionSteps.stepTwo`),
                          link: _s(`${baseTranslationKey}.actionSteps.bookLink`),
                        },
                        { text: _s(`${baseTranslationKey}.actionSteps.stepThree`) },
                      ]}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>

      {checkoutModalContext.screen === 'cof' && (
        <CreditCardModal
          onBeforeThreeDSRedirect={handleBeforeThreeDSRedirect}
          isOpen={checkoutModalContext.isOpen}
          onBack={checkoutModalContext.onModalBack}
          onClose={handleCloseCheckoutModal}
          onCardSelect={handleOnCreditCardSelect}
          {...(isGuestCheckout && {
            guestId,
            onAuthorize: (data) => {
              giftcardCheckoutAPI().submitGuestCoF(data);
              handleCloseCheckoutModal();
            },
            amount: selectedPaymentMethod.final,
          })}
          onRemoveCard={handleOnRemoveCard}
          triggerSource={SCREEN_NAME.CHECKOUT_GIFTCARD_SUMMARY}
          returnPath={getReturnUrl(productType)}
          {...checkoutModalContext}
        />
      )}

      <Modal isOpen={isDeliveryTooltipOpen}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header
            title={_s(`pages.${productType}.checkout.deliveryTooltip.title`)}
            onClose={() => setIsDeliveryTooltipOpen(false)}
          />
          <div className="px-lg pb-lg">
            <p>{_s(`pages.${productType}.checkout.deliveryTooltip.body1`)}</p>
            <br />
            <p>{_s(`pages.${productType}.checkout.deliveryTooltip.body2`)}</p>
          </div>
        </Modal.Content>
      </Modal>

      {Boolean(cofThreeDS?.adyenActionJson) && (
        <Modal isOpen={!submitting}>
          <Modal.Content floating={!isMobileView}>
            <div className={classnames('gap-xl pb-lg px-lg flex flex-col', isMobileView && 'px-lg')}>
              <ThreeDSComponent
                action={JSON.parse(cofThreeDS?.adyenActionJson)}
                handleOnAdditionalDetails={(data) => giftcardCheckoutAPI().submitThreeDS(data, false)}
                isGiftcard={true}
              />
            </div>
          </Modal.Content>
        </Modal>
      )}

      <Modal isOpen={showEditProfile}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header title={_s(`${baseTranslationKey}.editProfileModal.title`)} onClose={handleCloseEditProfile} />
          <div className={classnames(isMobileView && 'px-lg')}>
            <div className="p-lg">
              <Header label={_s('Email')} size="lg" />
            </div>
            <ListInput id="email" disabled value={user?.contact?.email} />
            <UpdateUserInfo {...updateUserInfo} familyName givenName locality mobile postalCode streetAddress />
          </div>
        </Modal.Content>
      </Modal>

      <SEO
        {...(isPlaceGiftcard
          ? {
              title: _s(`seo.buyPlaceGiftCardTitle`, { name: placeName }),
              description: _s(`seo.buyPlaceGiftCardDescription`, { name: placeName }),
              url: `${url.getBaseUrl()}places/${slugId}/giftcard/checkout`,
            }
          : {
              description:
                campaignType && productType === 'wellnesscard'
                  ? _s(`${baseTranslationKey}.productInfo.campaigns.seo.wellness.description`, {
                      type: typeTranslation,
                    })
                  : _s(`seo.${seoBaseString}Description`),
              title:
                campaignType && productType === 'wellnesscard'
                  ? _s(`${baseTranslationKey}.productInfo.campaigns.seo.wellness.title`, { type: typeTranslation })
                  : _s(`seo.${seoBaseString}Title`),
              url: `${url.getBaseUrl()}${productType === 'giftcard' ? 'presentkort' : 'friskvardskort'}${
                campaignType ? '/' + campaign : ''
              }`,
            })}
      />
    </PageViewLayout>
  );
};

const CofSubmitCTA = () => {
  const {
    handleOnCheckoutMissingAction,
    submitting,
    selectedPaymentMethod,
    missingActions,
    giftcardCheckoutAPI,
    summary,
    guestId,
  } = useGiftcardCheckout();
  const { user } = useAppSelector((state) => state.users);
  const { handleSubmit } = useGiftcardCheckoutFormData();
  const checkoutModal = useCheckoutModal();
  const paymentMethod = selectedPaymentMethod.type;
  const invalidPaymentMethod = paymentMethod === CHECKOUT_PAYMENT_METHOD.NONE;
  const isGuestCheckout = Boolean(!user && guestId);

  const handleOnClick = () => {
    const missingActions: CheckoutMissingAction[] = [];
    if (invalidPaymentMethod) missingActions.push('payment-method');

    missingActions.forEach((action) => handleOnCheckoutMissingAction(action));

    if (missingActions.length) {
      trackMpEvent(EVENT_NAME.VALIDATION_FAIL, {
        screen_name: SCREEN_NAME.CHECKOUT_GIFTCARD_SUMMARY,
        field_name: missingActions,
      });
      return;
    }
    switch (paymentMethod) {
      case CHECKOUT_PAYMENT_METHOD.STORED_COF:
        return giftcardCheckoutAPI().submitCoF(selectedPaymentMethod.id);
      case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
        return giftcardCheckoutAPI().submitGooglePay(summary.price, false);
      case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
        return giftcardCheckoutAPI().submitApplePay(summary.price, false);
      case CHECKOUT_PAYMENT_METHOD.NEW_COF:
        if (isGuestCheckout) {
          return checkoutModal.onModalShow('cof');
        }
        break;
    }
  };

  const buttonProps: ButtonProps = {
    size: 'lg',
    block: true,
    disabled: submitting || Boolean(missingActions.length),
    label: selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.QLIRO ? _s('bookCTA.qliro') : _s('Buy'),
    onClick: handleSubmit(handleOnClick),
    className: 'whitespace-nowrap',
  };

  return (
    <>
      {submitting && <LoadingPlaceHolder className="fixed h-screen" />}
      <Button {...buttonProps} />
    </>
  );
};

const SubmitCTA = ({ isPlaceGiftcard, campaign }: { isPlaceGiftcard: boolean; campaign: string }) => {
  const { handleKlarnaPayment, handlePlaceKlarnaPayment, submitting, klarna } = useGiftcardCheckout();
  const { klarnaGiftcardCheckoutAPI, status } = useKlarnaGiftcardCheckoutManager();
  const { handleSubmit } = useGiftcardCheckoutFormData();
  const payWithKlarna = async () => {
    const klarnaResponse = isPlaceGiftcard ? await handlePlaceKlarnaPayment() : await handleKlarnaPayment();
    if (klarnaResponse.sessionID) {
      sessionStorage.setItem(`${klarnaResponse.sessionID}_campaign`, campaign);
    }
    if (klarnaResponse) {
      authorizeKlarnaPurchase(klarnaResponse.orderLines, 'pay_later', (res) => {
        if (res.authorization_token) {
          klarnaGiftcardCheckoutAPI().onSubmitKlarna(
            res,
            klarnaResponse,
            isPlaceGiftcard ? createPlaceGiftCardOrder : giftCardServices.createGiftCardOrder,
          );
        }
      });
    }
  };

  const buttonProps: ButtonProps = {
    size: 'lg',
    block: true,
    label: _s(`checkout.payWithKlarna`),
    onClick: handleSubmit(payWithKlarna),
    className: 'whitespace-nowrap',
    leftIcon: <Icon variant={'payment-method-klarna-lg'} noFilter />,
    iconNoFilter: true,
    disabled: !klarna || status === 'SUBMITTING' || submitting,
  };

  return (
    <>
      {(status === 'SUBMITTING' || submitting) && <LoadingPlaceHolder className="fixed h-screen" />}
      <Button {...buttonProps} />
    </>
  );
};

export default function () {
  const location = useLocation();
  const match = useRouteMatch();
  const user = useAppSelector((state) => state.users.user);
  const { cards, loading: cardsLoading } = useCardsManager([], !!user);

  if (cardsLoading) return <LoadingPlaceHolder />;

  const productType = getGiftcardCheckoutProductType(match.params, location.pathname);

  const savedCheckoutState = formDataSchema.safeParse(location.state?.savedCheckoutState);

  if (productType === undefined) {
    return <NotFound />;
  }

  if (productType === 'valuecard' && (!location.state?.employeeId || !location.state?.valueCard)) {
    return <NotFound />;
  }

  const initialAmount = (() => {
    if (productType === 'giftcard' || productType === 'placecard') {
      return GIFTCARD_DEFAULT_VALUE_SELECTED;
    }
    if (productType === 'wellnesscard') {
      return WELLNESS_DEFAULT_VALUE_SELECTED;
    }
    if (productType === 'valuecard') {
      return location.state?.valueCard?.price / 100;
    }
  })();

  return (
    <UseCardsProvider initialCards={cards}>
      <GiftcardCheckoutFormDataProvider
        initialData={
          savedCheckoutState.success
            ? savedCheckoutState.data
            : {
                amount: initialAmount,
                customAmount: productType === 'valuecard' ? initialAmount : undefined,
                quantity: 1,
                type: 'digital',
              }
        }>
        <GiftcardCheckoutProvider>
          <CheckoutModalProvider>
            <GiftcardCheckout productType={productType}></GiftcardCheckout>
          </CheckoutModalProvider>
        </GiftcardCheckoutProvider>
      </GiftcardCheckoutFormDataProvider>
    </UseCardsProvider>
  );
}
