import { User } from '@/types/user';
import * as amplitude from '@amplitude/analytics-browser';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { getMainFbPixel, initTrackerFacebook, trackMpUserEvent, removeCookie } from '.';
import { isServer } from './general';

export function loggedUser() {
  // return authorization header with jwt token
  let token = null;
  if (!isServer) {
    token =
      typeof localStorage !== 'undefined' && localStorage.getItem('uSessionID')
        ? localStorage.getItem('uSessionID')
        : null;
  }

  return token;
}

export function storeUser(userData) {
  // Save to storage
  localStorage.setItem('uSessionID', userData.token);
  trackMpUserEvent(userData);

  // don't save "user" anymore in localstorage - cleanup: remove after 1.Aug.2022
  localStorage.removeItem('user');

  return userData;
}

export function removeUser() {
  if (isServer) {
    return;
  }

  localStorage.removeItem('user');
  localStorage.removeItem('uSessionID');
  localStorage.removeItem('uFilterActive');
  localStorage.removeItem('uFilterCanceled');
  localStorage.removeItem('mpLastVisited');
  localStorage.removeItem('mpPlaceVisits');
  localStorage.removeItem('mpClientPlaceBookings');
  removeCookie('clientBooker');
  //should also delete cookie BokadirektCookiePreferencesMP ??
  trackMpUserEvent();

  amplitude.reset();

  if (window._cio) {
    window._cio.reset();
  } else {
    setTimeout(() => {
      if (window._cio) {
        window._cio.reset();
      }
    }, 200);
  }

  if (getMainFbPixel()) {
    initTrackerFacebook(getMainFbPixel(), { em: null, ph: null });
  }
}

export const getUserInitials = (user: User) => {
  const { about } = user;
  const { givenName, familyName } = about;
  return givenName && familyName ? `${givenName.charAt(0).toUpperCase()}${familyName.charAt(0).toUpperCase()}` : '';
};

export const validatePhoneNumber = (phoneNumber: string) => {
  let swedishNumberStartingWith00 = /^0046\s*(7[02369])\s*(\d{4})\s*(\d{3})$/;
  let internationalNumers = /^\+(?!46)[0-9\s]+$/;
  return (
    isValidPhoneNumber(phoneNumber, 'SE') ||
    swedishNumberStartingWith00.test(phoneNumber) ||
    internationalNumers.test(phoneNumber)
  );
};

export const isPlaceFavorite = (user: any, placeId: number) => {
  return user?.favorites?.indexOf?.(placeId) !== -1;
};
