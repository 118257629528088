import { favoriteActions } from '@/actions';
import { Button, Fab } from '@/components/elements/forms/buttons';
import { HeartAltIcon, HeartIcon } from '@/components/icons';
import EmptyState from '@/components/templates/EmptyState';
import { isMobile, isServer, restoreBodyScroll, saveBodyScroll, trackMpEvent } from '@/helpers';
import { withAlert } from '@/hoc/withAlert';
import { __ } from '@/locale';
import { getScreenName, handleLoginClick } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from '../modals/redesign/Modal/Modal';

class FavoritesButton extends React.Component {
  handleCloseModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.showFavoriteModal(false, () => {
      restoreBodyScroll();
    });
  };

  handleShowModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    saveBodyScroll();
    this.props.showFavoriteModal(true);
  };

  async favoritesAction(allowRemove) {
    const { dispatch, place, user } = this.props;
    const isFavorite = user && user.favorites && user.favorites.indexOf(place.id) !== -1;
    const showLink = !isServer && window.location.pathname.indexOf('favoriter') === -1;
    if (!allowRemove && isFavorite) return;

    trackMpEvent(isFavorite ? 'favorite_removed' : 'favorite_added', {
      screen_name: 'company_details',
      company_id: place.id,
    });
    dispatch(isFavorite ? favoriteActions.remove(place.id, user) : favoriteActions.add(place.id, user)).then(() => {
      const { addAlert } = this.props;
      addAlert({
        message: !isFavorite ? __('addedTo') : __('removedFrom'),
        type: 'success',
        autoClose: true,
        ...(showLink && { link: { to: '/favoriter', label: __('favorites') } }),
      });
    });
  }

  handleClick = (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }

    if (this.props.user) {
      this.favoritesAction(true);
    } else {
      this.handleShowModal(e);
    }
  };

  componentDidMount() {
    this.checkFavoriteHash();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) {
      this.checkFavoriteHash();
    }
  }

  checkFavoriteHash() {
    if (!isServer) {
      const { user } = this.props;
      if (window.location.hash === '#fav' && user) {
        window.location.hash = '';
        this.favoritesAction(false);
      }
    }
  }

  getModal() {
    if (!isServer) {
      return (
        <Modal isOpen={this.props.showModal} key="fav2">
          <Modal.Content floating={!isMobile()}>
            <Modal.Header onClose={this.handleCloseModal} />
            <EmptyState
              body={__('loginForFavs')}
              title={__('loginForFavsTitle')}
              icon={<img src="/images/illustrations/favorites2.png" alt="" />}
              cta={
                <Button
                  label={__('favorites.emptyState.button')}
                  size="sm"
                  onClick={(e) => {
                    handleLoginClick(this.props.dispatch, NavItemIdentifier.LogIn, getScreenName());
                    this.handleCloseModal(e);
                  }}
                />
              }
            />
          </Modal.Content>
        </Modal>
      );
    } else {
      return null;
    }
  }

  render() {
    const { user, place, withText, overlay, withIcon = true } = this.props;
    const isFavorite = user ? user.favorites.indexOf(place.id) !== -1 : false;
    const label = withText ? (isFavorite ? __('removeFromFavContact') : __('addToFavContact')) : '';
    const content = [];
    const buttonProps = {
      ...(withIcon
        ? {
            icon: isFavorite ? (
              <HeartAltIcon className="h-6 w-6 text-[#f9a7a7]" />
            ) : (
              <HeartIcon className={`h-6 w-6 ${label || !overlay ? 'text-black-700' : 'text-white'}`} />
            ),
          }
        : {}),
    };
    content.push(
      <Fab
        key="fav1"
        onClick={this.handleClick}
        size="sm"
        variant="primary"
        className={`!focus:ring-0 !focus:outline-none !border-none ${
          overlay
            ? '!hover:bg-[rgba(0,0,0,0.5)] !bg-[rgba(0,0,0,0.5)]'
            : 'hover:text-black-900 bg-white hover:bg-transparent'
        } ${label ? 'p-0' : 'p-2'}`}
        {...buttonProps}>
        {label && <span className="text-primary ml-1">{label}</span>}
      </Fab>,
    );
    if (!user && this.props.showModal) {
      content.push(this.getModal());
    }
    return content;
  }
}

function mapStateToProps(state) {
  const { user } = state.users;

  return {
    user,
  };
}

const connectedFavoritesButton = withAlert(withRouter(connect(mapStateToProps)(FavoritesButton)));
export default connectedFavoritesButton;
