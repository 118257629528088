import { favoriteActions, loadingActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button } from '@/components/elements/forms/buttons';
import { LinkButton } from '@/components/elements/forms/buttons/Button/Button';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { PlaceCardContainer } from '@/components/modules/place';
import EmptyState from '@/components/templates/EmptyState';
import { config } from '@/config';
import { getCompanyType, setBookingStartingPoint, trackMpEvent, trackPage, url } from '@/helpers';
import { withAlert } from '@/hoc/withAlert';
import { __ } from '@/locale';
import { favoriteServices } from '@/services';
import { handleLoginClick } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import deepEqual from 'deep-equal';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

let myRef = null;
class FavoritesPage extends React.Component {
  constructor(props) {
    super(props);
    this.removeFavorite = this.removeFavorite.bind(this);
    let { salon } = url.getParamsFromGET();
    this.state = { places: [], scrollTo: salon };
  }

  seo() {
    const title = __('seo.favoritesTitle');
    const description = __('seo.favoritesDescription');
    const url = config.baseUrl + 'favoriter';
    const imageUrl = config.baseUrl + 'images/home-background.jpeg';
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={url} />

        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { dispatch } = this.props;
    dispatch(loadingActions.show());
    this.getFavorites(true);
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(this.props.user, prevProps.user, { strict: true })) {
      this.getFavorites();
    }
  }

  trackPage(count) {
    trackPage();
    // trackMpEvent('screen_shown', {
    //   screen_name: 'favorites',
    //   favorite_companies: count,
    // });
  }

  getFavorites(withTracking) {
    const { dispatch } = this.props;

    favoriteServices
      .favorites()
      .then((data) => {
        this.setState({ places: data.places || [] }, () => {
          if (this.state.scrollTo && myRef) {
            setTimeout(() => {
              window.scrollTo(0, myRef.offsetTop - 80);
            }, 200);
          }
        });
        withTracking && this.trackPage((data.places && data.places.length) || 0);
        dispatch(loadingActions.hide());
      })
      .catch((error) => {
        this.setState({ places: [] });
        withTracking && this.trackPage(0);
        dispatch(loadingActions.hide());
      });
  }

  trackPlace = (place) => (e) => {
    setBookingStartingPoint('favorites');
    trackMpEvent('favorite_clicked', {
      screen_name: 'favorites',
      company_id: place.id,
      company_type: getCompanyType(place),
    });
  };

  removeFavorite = (id) => async (e) => {
    const { dispatch, user } = this.props;
    this.setState({ places: this.state.places.filter((place) => place.id !== id) });
    dispatch(favoriteActions.remove(id, user)).then(() => {
      const { addAlert } = this.props;
      addAlert({ message: `${__('removedFrom')} ${__('favorites')}`, type: 'success', autoClose: true });
    });
    trackMpEvent('favorite_removed', {
      screen_name: 'favorites',
      company_id: id,
    });
  };

  getEmptyList() {
    return (
      <EmptyState
        body={__('noFavoritesContent')}
        title={__('noFavoritesTitle')}
        icon={<img src="/images/illustrations/favorites2.png" alt="" />}
        cta={
          <LinkButton variant="primary" size="sm" className="mt-4" to="/vad/var">
            {__('viewAll')}
          </LinkButton>
        }
      />
    );
  }
  setMyRef(val) {
    if (val) {
      myRef = val;
    }
  }

  render() {
    const loader = this.props.show ? <LoadingPlaceHolder /> : null;
    const { places } = this.state;

    const { user } = this.props;
    const isLogged = Boolean(user && user.token);

    if (!isLogged) {
      return (
        <PageViewLayout
          type="mainView"
          wrapperClass="bg-gradient"
          title={__('favorites')}
          source={'favorites'}
          hamburger
          footerDisplayOption={{ from: 'md', to: 'all' }}>
          <EmptyState
            title={__('favorites.emptyState.title')}
            body={__('favorites.emptyState.body')}
            icon={<img src="/images/illustrations/favorites2.png" alt="" />}
            cta={
              <Button
                label={__('favorites.emptyState.button')}
                size="sm"
                onClick={() => handleLoginClick(this.props.dispatch, NavItemIdentifier.LogIn, 'favorites')}
              />
            }
          />
          {this.seo()}
        </PageViewLayout>
      );
    }

    const items =
      places && places.length > 0 ? (
        <>
          <h1 className="hidden py-6 text-2xl font-semibold sm:block">{__('favorites')}</h1>
          <div className="flex w-full flex-col gap-4">
            {places.map((place, key) => {
              const same = parseInt(this.state.scrollTo) === place.id ? true : false;
              return (
                <PlaceCardContainer
                  key={key}
                  myRef={same ? this.setMyRef : null}
                  source="favorites"
                  place={place}
                  removeAction={this.removeFavorite(place.id)}
                  onClickPlace={this.trackPlace(place)}
                  onBookClick={() => {
                    sessionStorage.setItem('bookSPurl', '/favoriter');
                  }}
                />
              );
            })}
          </div>
        </>
      ) : (
        this.getEmptyList()
      );

    return (
      <PageViewLayout
        type="mainView"
        title={__('favorites')}
        source={'favorites'}
        hamburger
        footerDisplayOption={{ from: 'md', to: 'all' }}
        wrapperClass="bg-gradient">
        <div className="container mx-auto mb-4 px-0 sm:px-4">
          <div className="relative">
            {loader}
            {items}
          </div>
        </div>
        {this.seo()}
      </PageViewLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.users;
  const { show } = state.loading;
  return {
    user,
    show,
  };
}

const Favorites = withAlert(connect(mapStateToProps)(FavoritesPage));
export default Favorites;
