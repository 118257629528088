import { _s } from '@/locale';
import { PHYSICAL_FEE } from '@/pages/presentkort/GiftcardCheckout.hooks';

import { Order, PlaceGiftcardOrder } from '@/types/api/services/giftcard';
import { trackEventTikTok, trackMpAnalyticsEvent, trackMpEvent, trackMpRevenueGiftcards, trackPage } from './analytics';

export const mapKlarnaOrderToGiftcardOrder = (order: any): Order => {
  const discount = order.orderLines.find((line: any) => line.type === 'discount');
  return {
    deliveryType: order.isDigital ? 'digital' : 'physical',
    email: order?.giftCard?.[0]?.receiverEmail
      ? order.giftCard[0].receiverEmail
      : order?.confirmationEmail
      ? order.confirmationEmail
      : '',
    paymentMethod: 1,
    productType: order.isWellness ? 2 : 1,
    price: (order.orderLines[0].unit_price / 100).toString(),
    quantity: order.orderLines[0].quantity,
    discount: discount ? { amount: -discount.total_amount / 100 } : undefined,
  };
};

export const mapKlarnaOrderToPlaceGiftcardOrder = (order: any): PlaceGiftcardOrder => {
  return {
    email: order?.confirmationEmail ? order.confirmationEmail : '',
    price: order.orderLines[0].unit_price / 100,
    quantity: order.orderLines[0].quantity,
  };
};

export const trackKlarnaGiftcardPurchase = (response, klarnaID, sessionID) => {
  if (response.giftCard[0]?.message === 'order_created') return;

  const { isWellness, amount, isDigital } = response;

  const campaign = sessionStorage.getItem(`${sessionID}_campaign`);

  const wellness = isWellness ? 'wellness_' : '';
  const w = isWellness ? 'W' : '';

  trackMpAnalyticsEvent('gift_card_purchase_success', {
    card_type: isWellness ? 'wellness' : 'universal',
    amount,
    value: amount,
    currency: 'SEK',
    transaction_id: klarnaID,
  });

  trackMpAnalyticsEvent('gc_purchase_success', {
    gc_type: isWellness ? 'wellness' : 'universal',
  });

  trackMpEvent(`gift_card_${wellness}purchase`, {
    klarna_order_id: klarnaID,
    order_value: amount,
    is_digital: isDigital,
    campaign: campaign,
  });

  trackPage();

  trackMpEvent('screen_shown', {
    screen_name: `${isWellness ? 'wellness_card' : 'gift_card'}_confirmation`,
  });

  trackMpRevenueGiftcards({
    value: amount || 0,
    currency: 'SEK',
    contentName: `UGC${w}`,
    contentType: 'product',
    description: isWellness ? 'wellness_card' : 'gift_card',
    orderId: klarnaID,
  });

  trackEventTikTok(`${isWellness ? 'CompletePayment' : 'CompleteRegistration'}`, {
    value: amount, // number. Value of the order or items sold. Example: 100.
    currency: 'SEK', // string. The 4217 currency code. Example: 'USD'.
    contents: [
      {
        content_id: `UGC${w}`, // string. ID of the product. Example: '1077218'.
        content_type: 'product', // string. Either product or product_group.
        content_name: `${isWellness ? 'Wellness card' : 'Universal gift card'}`, // string. The name of the page or product. Example: 'shirt'.
      },
    ],
    content_type: 'product',
  });

  trackEventTikTok(`${isWellness ? 'WellnessCardPurchase' : 'UniversalGiftCardPurchase'}`, {
    value: amount, // number. Value of the order or items sold. Example: 100.
    currency: 'SEK', // string. The 4217 currency code. Example: 'USD'.
    contents: [
      {
        content_id: `UGC${w}`, // string. ID of the product. Example: '1077218'.
        content_type: 'product', // string. Either product or product_group.
        content_name: `${isWellness ? 'Wellness card' : 'Universal gift card'}`, // string. The name of the page or product. Example: 'shirt'.
      },
    ],
    content_type: 'product',
  });
};

export const getGiftcardTotalAmount = ({
  amount,
  quantity,
  isPhysical,
}: {
  amount: number;
  quantity: number;
  isPhysical: boolean;
}) => amount * quantity + (isPhysical ? PHYSICAL_FEE : 0);

export const trackGiftcardPurchase = ({
  isDigital,
  orderId,
  isWellness,
  giftcardAmount,
  quantity,
  paymentsId,
}: {
  isDigital: boolean;
  orderId: number;
  isWellness: boolean;
  giftcardAmount: number;
  quantity: number;
  paymentsId: string;
}) => {
  const campaign = sessionStorage.getItem(`${orderId}_campaign`);
  const amount = getGiftcardTotalAmount({ amount: giftcardAmount, quantity, isPhysical: !isDigital });

  const wellness = isWellness ? 'wellness_' : '';
  const w = isWellness ? 'W' : '';

  trackMpAnalyticsEvent('gift_card_purchase_success', {
    card_type: isWellness ? 'wellness' : 'universal',
    amount,
    value: amount,
    currency: 'SEK',
    transaction_id: paymentsId,
  });

  trackMpAnalyticsEvent('gc_purchase_success', {
    gc_type: isWellness ? 'wellness' : 'universal',
  });

  trackMpEvent(`gift_card_${wellness}purchase`, {
    // klarna_order_id: klarnaID,
    order_value: amount,
    is_digital: isDigital,
    campaign: campaign,
  });

  trackMpRevenueGiftcards({
    value: amount || 0,
    currency: 'SEK',
    contentName: `UGC${w}`,
    contentType: 'product',
    description: isWellness ? 'wellness_card' : 'gift_card',
    // orderId: klarnaID,
  });

  trackEventTikTok(`${isWellness ? 'CompletePayment' : 'CompleteRegistration'}`, {
    value: amount, // number. Value of the order or items sold. Example: 100.
    currency: 'SEK', // string. The 4217 currency code. Example: 'USD'.
    contents: [
      {
        content_id: `UGC${w}`, // string. ID of the product. Example: '1077218'.
        content_type: 'product', // string. Either product or product_group.
        content_name: `${isWellness ? 'Wellness card' : 'Universal gift card'}`, // string. The name of the page or product. Example: 'shirt'.
      },
    ],
    content_type: 'product',
  });

  trackEventTikTok(`${isWellness ? 'WellnessCardPurchase' : 'UniversalGiftCardPurchase'}`, {
    value: amount, // number. Value of the order or items sold. Example: 100.
    currency: 'SEK', // string. The 4217 currency code. Example: 'USD'.
    contents: [
      {
        content_id: `UGC${w}`, // string. ID of the product. Example: '1077218'.
        content_type: 'product', // string. Either product or product_group.
        content_name: `${isWellness ? 'Wellness card' : 'Universal gift card'}`, // string. The name of the page or product. Example: 'shirt'.
      },
    ],
    content_type: 'product',
  });
};

export const newTrackPlaceGiftCardPurchase = ({
  giftcardAmount,
  quantity,
  paymentsId,
  placeId,
  isValueCard,
}: {
  giftcardAmount: number;
  quantity: number;
  paymentsId: string;
  placeId: string;
  isValueCard?: boolean;
}) => {
  const amount = getGiftcardTotalAmount({ amount: giftcardAmount, quantity, isPhysical: false });
  trackMpAnalyticsEvent('gift_card_purchase_success', {
    card_type: 'individual',
    amount: amount,
    value: amount,
    currency: 'SEK',
    transaction_id: paymentsId,
  });
  trackMpAnalyticsEvent('gc_purchase_success', {
    gc_type: 'individual',
  });
  trackMpEvent(isValueCard ? 'value_card_purchase' : 'place_gift_card_purchase', {
    // klarna_order_id: klarnaId,
    place_id: placeId,
    order_value: amount,
  });
  trackMpRevenueGiftcards({
    value: amount || 0,
    currency: 'SEK',
    contentName: isValueCard ? 'VC' : 'IGC',
    contentType: 'product',
    description: isValueCard ? 'value_card' : 'place_gift_card',
    // orderId: klarnaId,
  });
};

export const getGiftcardErrorMessage = (message?: string) => {
  switch (message) {
    case 'not_found':
      return _s('saveGiftcard.error.notFound');
    case 'expired':
      return _s('saveGiftcard.error.expired');
    case 'empty':
      return _s('saveGiftcard.error.empty');
    case 'already_saved':
      return _s('saveGiftcard.error.alreadySaved');
    case 'already_saved_another_account':
      return _s('saveGiftcard.error.alreadySavedAnotherAccount');
    case 'not_supported':
      return _s('saveGiftcard.error.notSupported');
    case 'invalid_type':
      return _s('saveGiftcard.error.invalidType');
    case 'invalid':
      return _s('saveGiftcard.error.invalid');
    case 'link_use_expired':
      return _s('saveGiftcard.error.linkUseExpired');
    case 'link_already_used':
      return _s('saveGiftcard.error.linkAlreadyUsed');
    default:
      return _s('unknownError');
  }
};

export const trackPlaceGiftCardPurchase = (response, klarnaId, placeId) => {
  if (response.giftCard[0]?.greeting === 'order_created') return;

  trackMpAnalyticsEvent('gift_card_purchase_success', {
    card_type: 'individual',
    amount: response.amount,
    value: response.amount,
    currency: 'SEK',
    transaction_id: klarnaId,
  });
  trackMpAnalyticsEvent('gc_purchase_success', {
    gc_type: 'individual',
  });
  trackMpEvent('place_gift_card_purchase', {
    klarna_order_id: klarnaId,
    place_id: placeId,
    order_value: response.amount,
  });

  trackPage();
  trackMpEvent('screen_shown', {
    screen_name: 'place_gift_card_confirmation',
  });
  trackMpRevenueGiftcards({
    value: response.amount || 0,
    currency: 'SEK',
    contentName: 'IGC',
    contentType: 'product',
    description: 'place_gift_card',
    orderId: klarnaId,
  });
};
