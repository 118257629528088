import breakpoints from '@/breakpoints';
import { Lightbox } from '@/components/modules/lightbox';
import { getImagePreview, isMobile, trackMpEvent } from '@/helpers';
import withMobileView from '@/hoc/withMobileView';
import { __ } from '@/locale';
import React from 'react';

class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.isdisabled = false;
    this.state = { currentImage: 0, lightboxIsOpen: props.showGallery || false };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
  }

  openLightbox(index) {
    trackMpEvent('gallery_clicked', {
      screen_name: 'company_details',
      company_id: this.props.place.id,
    });

    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: (this.state.currentImage + 1) % this.props.photos.length,
    });
  }

  gotoImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  getImages() {
    const { photos, place } = this.props;
    const imageContainerBlur = `flex items-center overflow-hidden bg-cover bg-center bg-no-repeat blur-sm`;
    const imageContainer = `overflow-hidden bg-transparent flex items-center basis-[40%]`;
    const firstImage = 'min-w-[60%] basis-[60%]';

    if (photos.length > 1) {
      const first = getImagePreview(photos[0], '200x200', place.about.slug + '-1');
      const second = getImagePreview(photos[1], '200x200', place.about.slug + '-2');
      return (
        <div className="fake-gallery flex h-[160px] w-full">
          <div className="background-blur absolute left-0 top-0 z-[2] flex h-[160px] w-full">
            <div
              className={`${imageContainerBlur} ${firstImage}`}
              style={{ backgroundImage: 'url(' + first + ')' }}
              onClick={() => this.openLightbox(0)}>
              &nbsp;
            </div>
            <div
              className={imageContainerBlur}
              style={{ backgroundImage: 'url(' + second + ')' }}
              onClick={() => this.openLightbox(1)}>
              &nbsp;
            </div>
          </div>
          <div className="clear-view absolute left-0 top-0 z-[3] flex h-[160px] w-full">
            <div className={`${imageContainer} ${firstImage}`} onClick={() => this.openLightbox(0)}>
              <img
                loading="lazy"
                className="mx-auto h-auto max-h-full w-auto max-w-full"
                width="160"
                height="160"
                src={first}
                alt=""
                onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img200x200.jpg')}
              />
            </div>
            <div className={imageContainer} onClick={() => this.openLightbox(1)}>
              <img
                loading="lazy"
                className="mx-auto h-auto min-h-full min-w-full max-w-full basis-[40%] object-cover sm:m-0 sm:max-h-full sm:min-w-min"
                src={second}
                alt=""
                width="160"
                height="160"
                onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img200x200.jpg')}
              />
            </div>
          </div>
        </div>
      );
    } else if (photos.length === 1) {
      return (
        <div className="gallery flex h-[160px] justify-start" onClick={() => this.openLightbox(0)}>
          <div
            className="absolute z-[1] h-[160px] w-full bg-cover bg-center bg-no-repeat blur-sm"
            style={{ backgroundImage: 'url(' + photos[0] + ')' }}
            onClick={() => this.openLightbox(0)}>
            &nbsp;
          </div>
          <div className="absolute z-[2] flex h-[160px] w-full">
            <img
              loading="lazy"
              className="single-image m-auto block h-auto max-h-full max-w-full"
              src={photos[0]}
              alt=""
              width="160"
              height="160"
              onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img200x200.jpg')}
            />
          </div>
        </div>
      );
    }
  }

  disable_scroll() {
    if (this.isdisabled === true) {
      return;
    }
    document.ontouchmove = function (e) {
      e.preventDefault();
      this.isdisabled = true;
      setTimeout(() => {
        document.ontouchmove = function (e) {
          this.isdisabled = false;
          return true;
        };
      }, 500);
    };
  }

  getLightbox() {
    const lightboxPhotos = this.props.photos.map((url) => ({ src: url }));

    return (
      !!this.state.lightboxIsOpen && <Lightbox
        images={lightboxPhotos}
        onClose={this.closeLightbox}
        backdropClosesModal={true}
        onClickPrev={this.gotoPrevious}
        onClickNext={this.gotoNext}
        currentImage={this.state.currentImage}
        isOpen={this.state.lightboxIsOpen}
        showThumbnails={true}
        onClickThumbnail={this.gotoImage}
        imageCountSeparator={__('of')}
      />
    );
  }

  renderEmployeeGallery(photos) {
    const images = photos.slice(0, 6);
    return (
      <div className="flex h-auto pb-2">
        {images.map((img, k) => (
          <div
            key={k}
            className="mr-1 cursor-pointer overflow-hidden rounded-md"
            onClick={() => this.openLightbox(k)}
            style={k === 5 && photos.length > 6 ? { backgroundImage: 'url(' + getImagePreview(img, '40x40') + ')' } : {}}>
            {k === 5 && photos.length > 6 && (
              <div className="relative z-10 flex h-10 w-10 items-center justify-center text-sm font-semibold text-white ">
                {'+' + (photos.length - 6)}
                <div className="absolute inset-0 -z-[1] bg-black opacity-40"></div>
              </div>
            )}
            {(k !== 5 || k === photos.length - 1) && (
              <img
                loading="lazy"
                src={getImagePreview(img, '40x40')}
                height="40px"
                width="40px"
                alt=""
                onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img60x60.jpg')}
              />
            )}
          </div>
        ))}
        {this.state.lightboxIsOpen && this.getLightbox()}
      </div>
    );
  }

  renderMobile(photos) {
    return (
      <div id="photos" className="gallery-container relative h-[160px] overflow-hidden">
        {this.getImages()}
        {this.state.lightboxIsOpen && this.getLightbox()}
      </div>
    );
  }

  render() {
    const { photos, place, isEmployee } = this.props;
    const isSmallDesktop = this.props.windowSize.width < breakpoints.lg;

    if (isEmployee) {
      return this.renderEmployeeGallery(photos);
    }
    if (!photos || photos.length === 0) {
      return isMobile() ? (
        <div className="gallery-container relative h-[160px] overflow-hidden">
          <div
            className="w-full bg-center bg-no-repeat"
            style={{
              backgroundImage: 'url("/images/no-salon-image.png")',
              height: '160px',
              backgroundColor: '#f4f4f4',
            }}></div>
        </div>
      ) : (
        <div id="photos" className="details-section scroll-mt-[160px] md:scroll-mt-[120px]">
          <h2 className="mb-2 text-2xl font-semibold">{__('photos')}</h2>
          <span className="text-black-600">{__('noPhotosYet')}</span>
        </div>
      );
    }

    if (isMobile()) {
      return this.renderMobile(photos);
    }
    const images = isSmallDesktop ? photos.slice(0, 3) : photos.slice(0, 4);
    const unshiftFirst = images.length >= (isSmallDesktop ? 3 : 4);
    if (unshiftFirst) {
      // remove main image since it has a different format that doesn't look ok
      images.shift();
    }

    return (
      <div id="photos" className="details-section scroll-mt-[160px] md:scroll-mt-[120px]">
        <h2 className="mb-2 text-2xl font-semibold">{__('photos')}</h2>
        <div className="flex justify-start">
          {images.map((img, k) => (
            <div
              key={k}
              className="smart-gallery mr-2 flex min-w-[200px] cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-[#999] bg-cover bg-center bg-no-repeat"
              onClick={() => this.openLightbox(unshiftFirst ? k + 1 : k)}
              style={
                (k === 2 && photos.length > 3 && !isSmallDesktop) || (k === 1 && photos.length > 2 && isSmallDesktop)
                  ? { backgroundImage: 'url(' + getImagePreview(img, '200x200', place.about.slug + '-' + (k + 1)) + ')' }
                  : {}
              }>
              {((k === 2 && photos.length > 3 && !isSmallDesktop) ||
                (k === 1 && photos.length > 2 && isSmallDesktop)) && (
                <div
                  className="more-images z-10 h-[200px] w-[200px] text-center text-4xl !leading-[200px] text-white"
                  style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}>
                  {'+' + (isSmallDesktop ? photos.length - 2 : photos.length - 3)}
                </div>
              )}

              {(((k !== 2 || k === photos.length - 1) && !isSmallDesktop) ||
                ((k !== 1 || k === photos.length - 1) && isSmallDesktop)) && (
                <img
                  loading="lazy"
                  src={getImagePreview(img, '200x200', place.about.slug + '-' + (k + 1))}
                  height="200px"
                  width="200"
                  alt=""
                  onError={(e) => (e.currentTarget.src = '/images/bd-fallback-img200x200.jpg')}
                />
              )}
            </div>
          ))}
          {this.state.lightboxIsOpen && this.getLightbox()}
        </div>
      </div>
    );
  }
}

export default withMobileView(Photos);
