import { LinkButton } from '@/components/elements/forms/buttons';
import { OLD_KLARNA_GIFTCARD_CHECKOUT_FEATURE_FLAG } from '@/constants/experimentConstants';
import { isServer, trackMpEvent } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';

class GiftCards extends React.Component {
  handleClick = (e) => {
    trackMpEvent('buy_gift_card_clicked', {
      screen_name: 'company_details',
      company_id: this.props.place ? this.props.place.id : undefined,
      trigger_source: 'company_details',
    });
  };

  render() {
    const place = this.props.place;
    if (
      isServer ||
      !(
        place &&
        place.about &&
        place.about.settings &&
        (place.about.settings.sellsGiftCard || place.about.settings.sellsValueCard)
      )
    ) {
      return null;
    }

    let url = `/places/${place.about.slug}-${place.id}`;
    let label = __('buy') + ' ';

    let slash = '';
    if (place.about.settings.sellsGiftCard) {
      label += __('giftcardLabel');
      slash = ' / ';
    }

    const useOldCheckout = this.props?.flags?.[OLD_KLARNA_GIFTCARD_CHECKOUT_FEATURE_FLAG]?.value === 'on';

    if (place.about.settings.sellsValueCard) {
      url += '/valuecards/buy';
      label += slash + __('valuecardLabel');
    } else {
      url += useOldCheckout ? '/giftcards/buy' : '/giftcard/checkout';
    }

    return (
      <div id="giftCards" className="py-6">
        <h2 className="mb-4 text-2xl font-semibold">{__('giftCardHeader')}</h2>
        <div className="mr-[10px] flex items-start">
          <div className="flex flex-1 flex-col">
            <p className="text-black-900 mb-2">{label}</p>
            <p className="text-black-600 text-sm">{__('salonGiftCardDescription')}</p>
          </div>
          <LinkButton
            to={url}
            onClick={this.handleClick}
            variant="primary"
            size="sm"
            className="flex max-w-[75px] flex-1 items-center">
            {__('buy')}
          </LinkButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { flags } = state;
  return { flags };
};

export default connect(mapStateToProps)(GiftCards);
