import { paymentCardBrandSchema } from '@/types/paymentcards';
import { formFieldSchema } from '@/types/state/shared';
import { z } from 'zod';

const employeeSettingsSchema = z.object({
  lastDay: z.number().optional(),
  useQliro: z.boolean().optional(),
  bookAhead: z.number().optional(),
  hasKlarna: z.boolean().optional(),
  showPhoto: z.boolean().optional(),
  bookCancel: z.number().optional(),
  klarnaText: z.string().optional(),
  startWorking: z.number().optional(),
  allowCustomer: z.boolean().optional(),
  bookInAdvance: z.number().optional(),
  sellsGiftCard: z.boolean().optional(),
  useCardOnFile: z.boolean().optional(),
  useCardOnline: z.boolean().optional(),
  displayReviews: z.boolean().optional(),
  klarnaClientId: z.string().optional(),
  sellsValueCard: z.boolean().optional(),
  acceptsValueCard: z.boolean().optional(),
  hasOnlinePayment: z.boolean().optional(),
  giftCardTemplates: z.array(z.unknown()).optional(),
  offlinePaymentFee: z.number().optional(),
  blockCancelBooking: z.boolean().optional(),
  allowCustomerMessage: z.boolean().optional(),
  alwaysShowPayAtPlace: z.boolean().optional(),
  onlinePaymentRequired: z.boolean().optional(),
  useCardOnlinePayLater: z.boolean().optional(),
  allowBookingsToBeCancelled: z.boolean().optional(),
  klarnaPaymentDisplayOption: z.number().optional(),
  cardOnFileReservationEnabled: z.boolean().optional(),
  forceNonAggressivePaymentFlow: z.boolean().optional(),
  onlinePaymentRequiredOnlyForNewCustomers: z.boolean().optional(),
  useSwishOnline: z.boolean().optional().nullable(),
});

export type EmployeeSettings = z.infer<typeof employeeSettingsSchema>;

export const employeeAboutSchema = z.object({
  avatar: z.string().optional().nullable(),
  inactive: z.boolean().optional(),
  name: z.string(),
  isBookable: z.boolean().optional(),
  terms: z.string().optional().nullable(),
  info: z.string().optional(),
  priceListId: z.string().optional(),
  settings: employeeSettingsSchema,
  formFields: z.array(formFieldSchema).nullable().optional(),
});

export type EmployeeAbout = z.infer<typeof employeeAboutSchema>;

export const employeeSchema = z.object({
  id: z.number(),
  place: z.number(),
  about: employeeAboutSchema,
  services: z.array(z.number()).optional().nullable(),
});

export type Employee = z.infer<typeof employeeSchema>;

const servicePriceType = z.object({
  gaps: z.record(z.unknown()).optional(),
  range: z.string().optional(),
  rooms: z.record(z.unknown()).optional(),
  prices: z.record(z.number()).optional(),
  durations: z.record(z.number()).optional(),
  equipments: z.record(z.unknown()).optional(),
  withoutVat: z.record(z.number()).optional(),
  durationRange: z.string().optional(),
  rangeWithoutVat: z.string().optional(),
  displayDurations: z.record(z.number()).optional(),
});

const serviceSettingsSchema = z.object({
  allowMarketing: z.boolean().optional(),
  healthCareCard: z.boolean().optional(),
  hideDuration: z.boolean().optional(),
  hidePrice: z.boolean().optional(),
  isAddOn: z.boolean().optional(),
  isChargeable: z.boolean().optional(),
  isFree: z.boolean().optional(),
  onlinePaymentRequired: z.boolean().optional(),
  onlinePaymentRequiredOnlyForNewCustomers: z.boolean().optional(),
  showFrom: z.boolean().optional(),
  useDynamicPricing: z.boolean().optional(),
});

const serviceAboutSchema = z.object({
  settings: serviceSettingsSchema,
  slug: z.string().optional(),
  displayDuration: z.number().optional(),
  product: z.number().optional().nullable(),
  capacity: z.number().optional().nullable(),
});

const serviceExtraSchema = z.object({
  inactive: z.boolean().optional(),
  external: z
    .object({
      erpId: z.string().optional().nullable(),
      oldId: z.string().optional().nullable(),
      maxPriceWithoutVat: z.number().optional().nullable(),
    })
    .optional()
    .nullable(),
});

export const serviceSchema = z.object({
  id: z.number(),
  name: z.string(),
  place: z.number(),
  employee: employeeSchema.optional(),
  price: z.number().or(z.string()).optional(),
  about: serviceAboutSchema,
  priceType: servicePriceType,
  extra: serviceExtraSchema.optional(),
});

const placeServicesSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  name: z.string().optional(),
  services: z.array(serviceSchema).optional(),
});

export type Service = z.infer<typeof serviceSchema>;

const placeSettingsSchema = z.object({
  terms: z.string().optional().nullable(),
  hasQliro: z.boolean().optional(),
  listView: z.boolean().optional(),
  hasKlarna: z.boolean().optional(),
  sendEmail: z.boolean().optional(),
  showPrices: z.boolean().optional(),
  skipEmails: z.boolean().optional(),
  topOfSearch: z.boolean().optional(),
  hasCampaigns: z.boolean().optional(),
  requireLogin: z.boolean().optional(),
  sortServices: z.number().optional(),
  allowCustomer: z.boolean().optional(),
  hideEmployees: z.boolean().optional(),
  sellsGiftCard: z.boolean().optional(),
  servicesOrder: z
    .array(
      z.object({
        id: z.number(),
        own: z.boolean(),
        sort: z.number(),
      }),
    )
    .optional(),
  displayReviews: z.boolean().optional(),
  hasCertificate: z.boolean().optional(),
  sellsValueCard: z.boolean().optional(),
  acceptsGiftCard: z.boolean().optional(),
  fluentThresolds: z.boolean().optional(),
  acceptsValueCard: z.boolean().optional(),
  magneticBookings: z.boolean().optional(),
  subscriptionType: z.string().optional(),
  supportsWaitlist: z.boolean().optional(),
  customerWebWaitList: z.boolean().optional(),
  displayCertificates: z.boolean().optional(),
  onlinePaymentRequired: z.boolean().optional(),
  openingHoursExtraInformation: z.string().optional(),
});

export type PlaceSettings = z.infer<typeof placeSettingsSchema>;

const placeAssociationSchema = z.object({
  id: z.number().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  imgSrc: z.string().optional(),
});

const placeAboutSchema = z.object({
  active: z.boolean(),
  associations: z.array(placeAssociationSchema).optional(),
  name: z.string(),
  book: z.object({
    blockCancel: z.boolean().optional(),
    bookInAdvance: z.nullable(z.number()).optional(),
    cancel: z.nullable(z.number()).optional(),
    bookingText: z.string().optional(),
    instantBook: z.boolean().optional(),
    intervals: z.number().optional(),
    showSelectedEmployee: z.boolean(),
    dynamicPricing: z.nullable(
      z.object({
        endHours: z.number(),
        startHours: z.number(),
        applyOnSunday: z.boolean(),
        applyOnSaturday: z.boolean(),
        applyOnBankHoliday: z.boolean(),
      }),
    ),
  }),
  profileImage: z.string().optional(),
  images: z.array(z.string()).optional(),
  settings: placeSettingsSchema,
  slug: z.string(),
});

const placeContactSchema = z.object({
  address: z.object({
    city: z.string().optional(),
    name: z.string().optional(),
    street: z.string().optional(),
    zipcode: z.string().optional(),
  }),
  email: z.string().optional(),
  phone: z.string().optional(),
  position: z.object({
    lat: z.number().optional(),
    lon: z.number().optional(),
  }),
  timezone: z.string().optional(),
  website: z.string().optional(),
});

const campaignSchema = z.object({
  id: z.number().optional(),
  name: z.string().optional(),
  type: z.number().optional(),
  place: z.number().optional(),
  status: z.number().optional(),
  endDate: z.number().optional(),
  services: z.array(
    z.object({
      id: z.number(),
      discountPrice: z.number().nullable().optional(),
      discountPercent: z.number().nullable().optional(),
    }),
  ),
  createdAt: z.string(),
  startDate: z.number(),
  updatedAt: z.string().optional(),
});

const sistaminutenCampaignSchema = z.object({
  id: z.literal(-1),
  startDate: z.number().optional().nullable(),
  endDate: z.number().optional().nullable(),
  services: z.array(
    z.object({
      id: z.number(),
      discountPrice: z.number().nullable().optional(),
      discountPercent: z.number().nullable().optional(),
    }),
  ),
});

export const placeSchema = z.object({
  id: z.number(),
  source: z.union([z.literal(1), z.literal(2)]),
  about: placeAboutSchema,
  contact: placeContactSchema,
  campaigns: z.array(campaignSchema.or(sistaminutenCampaignSchema)).optional(),
  employees: z.array(employeeSchema).optional().nullable(),
  services: z.array(placeServicesSchema).optional().nullable(),
});

export type Place = z.infer<typeof placeSchema>;

const formFieldsSchema = z.object({
  email: z.string().optional(),
  phone: z.string().optional(),
  mobile: z.string().optional(),
  locality: z.string().optional(),
  givenName: z.string().optional(),
  familyName: z.string().optional(),
  nationalId: z.string().optional(),
  postalCode: z.string().optional(),
  bookingNotes: z.string().optional(),
  streetAddress: z.string().optional(),
});

const bookingConfirmed = z.literal(1);
const bookingCanceled = z.literal(2);
const bookingOnHold = z.literal(3);

export const paymentMethodPayAtPlaceSchema = z.literal(0);
export type PaymentMethodPayAtPlace = z.infer<typeof paymentMethodPayAtPlaceSchema>;

export const paymentMethodKlarnaSchema = z.literal(1);
export type PaymentMethodKlarna = z.infer<typeof paymentMethodKlarnaSchema>;

export const paymentMethodQliroSchema = z.literal(3);
export type PaymentMethodQliro = z.infer<typeof paymentMethodQliroSchema>;

export const paymentMethodStoredCofSchema = z.literal(4);
export type PaymentMethodStoredCof = z.infer<typeof paymentMethodStoredCofSchema>;

export const paymentMethodGooglePaySchema = z.literal(6);
export const paymentMethodApplePaySchema = z.literal(7);

export const paymentMethodSwishSchema = z.literal(8);
export type PaymentMethodSwish = z.infer<typeof paymentMethodSwishSchema>;

export const paymentMethodSchema = z.union([
  paymentMethodPayAtPlaceSchema,
  paymentMethodKlarnaSchema,
  paymentMethodQliroSchema,
  paymentMethodStoredCofSchema,
  paymentMethodGooglePaySchema,
  paymentMethodApplePaySchema,
  paymentMethodSwishSchema,
]);

export type PaymentMethod = z.infer<typeof paymentMethodSchema>;

const couponMethodGiftcard = z.literal('Giftcard');
const couponMethodValuecard = z.literal('Valuecard');
const couponMethodWellnesscard = z.literal('Wellnesscard');

export const couponMethodSchema = z.union([couponMethodGiftcard, couponMethodValuecard, couponMethodWellnesscard]);

export const usedPaymentMethodSchema = z.object({
  paymentMethod: paymentMethodSchema,
  count: z.number().optional(),
});

export type UsedPaymentMethod = z.infer<typeof usedPaymentMethodSchema>;

export const bookingExtraSchema = z.object({
  anyEmployee: z.boolean().optional(),
  anyEmployeeSelected: z.boolean().optional(),
  fee: z.number().optional(),
  orderId: z.string().or(z.number()).optional(),
  klarnaOrderId: z.string().optional(),
  feeApplied: z.boolean().optional(),
  discount: z.boolean().optional(),
  formfields: z.record(formFieldsSchema).optional(),
  paymentMethod: paymentMethodSchema,
  adyenMerchantReference: z.string().optional(),
  observation: z.string().optional(),
  giftcards: z
    .array(
      z.object({
        amount: z.number(),
        code: z.string(),
        giftCardType: z
          .union([
            z.literal('UGC'),
            z.literal('EGC'),
            z.literal('IGC'),
            z.literal('VCIGC'),
            z.literal('VCEGC'),
            z.literal('UGCW'),
          ])
          .optional(),
        giftCardExpiryDate: z.string().optional(),
      }),
    )
    .optional(),
  campaigns: z.array(campaignSchema.or(sistaminutenCampaignSchema)).optional(),
  dynamicPriceListIdKey: z.string().optional(),
  onlinePayment: z
    .object({
      amount: z.number().optional(),
      method: paymentCardBrandSchema.optional(),
      status: z.string().optional(),
      success: z.string().optional(),
      lastFour: z.string().optional(),
    })
    .optional(),
  appliedBundle: z
    .object({ id: z.number(), service: z.object({ serviceId: z.number(), quantity: z.number(), usages: z.number() }) })
    .optional(),
});

export const bookingStatusSchema = z.union([bookingConfirmed, bookingCanceled, bookingOnHold]);

const appliedBundleSchema = z.object({
  id: z.number(),
  bundleTemplateId: z.number(),
  name: z.string(),
  price: z.number(),
  priceLabel: z.string().optional(),
  finalPrice: z.number().optional(),
  finalPriceLabel: z.string().optional(),
  validMonths: z.number(),
  validity: z.string().optional(),
  description: z.string().optional(),
  place: placeSchema.pick({ id: true, about: true }),
  service: z.object({
    serviceId: z.number(),
    name: z.string(),
    slug: z.string(),
    usages: z.number(),
    quantity: z.number(),
  }),
});

export const bookingSchema = z.object({
  id: z.number(),
  cancelMargin: z.number().optional(),
  client: z.number(),
  duration: z.number(),
  end: z.number(),
  extra: bookingExtraSchema,
  services: z.array(
    z.object({
      cancellationCode: z.string().optional().nullable(),
      duration: z.number(),
      start: z.number(),
      employee: employeeSchema,
      service: serviceSchema,
      extra: z
        .object({
          formfields: z
            .object({
              email: z.string().optional(),
              familyName: z.string().optional(),
              givenName: z.string().optional(),
              locality: z.string().optional(),
              mobile: z.string().optional(),
              phone: z.string().optional(),
              postalCode: z.string().optional(),
              streetAddress: z.string().optional(),
              cancellationCode: z.string().optional(),
              message: z.string().optional(),
            })
            .nullable()
            .optional(),
          anyEmployee: z.boolean().optional().nullable(),
          anyEmployeeSelected: z.boolean().optional().nullable(),
          dynamicPriceListIdKey: z.string().optional().nullable(),
        })
        .optional(),
    }),
  ),
  place: placeSchema,
  appliedBundle: appliedBundleSchema.optional(),
  start: z.number(),
  price: z.string(),
  status: bookingStatusSchema,
  bookingCode: z.string().optional(),
});

export type Booking = z.infer<typeof bookingSchema>;

export const bookingByCodeSchema = bookingSchema.extend({
  bookingCode: z.string(),
});
