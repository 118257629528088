import { server } from '@/helpers';
import {
  AdyenAdditionalDetails,
  CreatePaymentResponse as AdyenCreatePaymentResponse,
} from '@/types/api/services/adyen';
import {
  AdyenGiftcardOrderRequest,
  CheckDiscountCodeRequest,
  CheckDiscountCodeResponse,
  GiftcardOrderRequest,
  Order,
} from '@/types/api/services/giftcard';
import { CreatePaymentResponse as QliroCreatePaymentResponse } from '@/types/api/services/qliro';

function createGiftcardAdyenOrder(
  body: AdyenGiftcardOrderRequest,
): Promise<AdyenCreatePaymentResponse & { pspRef: string }> {
  return server.request.post('/adyen/giftcard/orders', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function submitGiftcardAdyenPaymentDetails(
  body: AdyenAdditionalDetails,
): Promise<AdyenCreatePaymentResponse & { pspRef: string }> {
  return server.request
    .post('/adyen/giftcard/details', body)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

function createGiftcardQliroOrder(body: GiftcardOrderRequest): Promise<QliroCreatePaymentResponse> {
  return server.request.post('/qliro/giftcard/orders', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function getGiftcardOrder(id: string): Promise<Order> {
  return server.request.get(`/giftcard/orders/${id}`).then(server.handleSuccess).catch(server.handleErrorObject);
}

function checkDiscountCode({ code, orderTotal }: CheckDiscountCodeRequest): Promise<CheckDiscountCodeResponse> {
  return server.request
    .post('/giftcard/discount', { code, orderTotal })
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

export const GiftcardService = {
  createGiftcardAdyenOrder,
  submitGiftcardAdyenPaymentDetails,
  getGiftcardOrder,
  checkDiscountCode,
  createGiftcardQliroOrder,
};
